import { css, cx } from "emotion";
import React, { FC, useState } from "react";
import { Card } from "../../../components/card/Card";
import { Arrow } from "../../../components/icons/Arrow";
import chevronIcon from "../../../components/icons/chevron.svg";
import gridIcon from "../../../components/icons/grid.svg";
import listIcon from "../../../components/icons/list.svg";
import { grade, TagButton, topic } from "../../../components/Tags";
import theme from "../../../utils/theme";
import { State } from "../../../utils/useDataApi";
import { PostData } from "../Home";

export const Content: FC<{
  postsResult: State<{ posts: PostData[] }, unknown>;
  latestPostsResult: State<{ posts: PostData[] }, unknown>;
  refetchPosts: () => void;
}> = ({ postsResult, latestPostsResult, refetchPosts }) => {
  const [gradeFilter, setGradeFilter] = useState<string[]>([]);
  const [topicFilter, setTopicFilter] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<"LIST" | "GRID">("GRID");
  const [sortAfter, setSortAfter] = useState<string>("LIKES");

  const handleUpdateGradeFilter = (tag: string) => {
    const tagExistsIndex = gradeFilter.findIndex((t) => t === tag);
    if (tagExistsIndex === -1) {
      setGradeFilter((tags) => [...tags, tag]);
    } else {
      let gradeFilterCopy = [...gradeFilter];
      gradeFilterCopy.splice(tagExistsIndex, 1);
      setGradeFilter(gradeFilterCopy);
    }
  };

  const handleUpdateTopicFilter = (tag: string) => {
    const tagExistsIndex = topicFilter.findIndex((t) => t === tag);
    if (tagExistsIndex === -1) {
      setTopicFilter((tags) => [...tags, tag]);
    } else {
      let topicFilterCopy = [...topicFilter];
      topicFilterCopy.splice(tagExistsIndex, 1);
      setTopicFilter(topicFilterCopy);
    }
  };

  return (
    <div
      className={css`
        padding-top: 90px;
      `}
    >
      <TagFilter
        gradeFilter={gradeFilter}
        setGradeFilter={handleUpdateGradeFilter}
        topicFilter={topicFilter}
        setTopicFilter={handleUpdateTopicFilter}
      />
      <div
        className={css`
          margin-top: 40px;
          display: flex;
          align-items: center;
        `}
      >
        Vis heller som{" "}
        <button
          onClick={() => setViewMode(viewMode === "GRID" ? "LIST" : "GRID")}
          title={viewMode === "GRID" ? "LIST" : "GRID"}
          className={css`
            background-color: ${theme.colors.base.background.primary};
            height: 40px;
            width: 40px;
            border-radius: 20px;
            margin-left: 10px;
          `}
        >
          {viewMode === "GRID" ? (
            <img src={listIcon} alt="list" />
          ) : (
            <img src={gridIcon} alt="grid" />
          )}
        </button>
      </div>
      {gradeFilter.length === 0 && topicFilter.length === 0 && (
        <div
          className={css`
            margin-top: 40px;
          `}
        >
          <h2>Nyeste poster</h2>
          {latestPostsResult.isLoading && <div>Loading...</div>}
          {latestPostsResult.data && (
            <div
              className={cx(
                css`
                  margin-top: 24px;
                `,
                viewMode === "GRID"
                  ? css`
                      display: grid;
                      grid-gap: 20px;
                      grid-template-columns: repeat(5, 250px);
                      align-items: center;
                      justify-items: center;

                      @media screen and (max-width: 1330px) {
                        overflow-x: auto;
                      }
                    `
                  : css`
                      display: flex;
                      flex-direction: column;
                    `
              )}
            >
              {latestPostsResult.data.posts.map((post, i) => (
                <Card
                  mode={viewMode === "GRID" ? "VERTICAL" : "HORIZONTAL"}
                  key={`${post.id}-${post.title}`}
                  post={post}
                  refetchPosts={refetchPosts}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <div
        className={css`
          margin-top: 40px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            label {
              margin-right: 8px;
              font-size: inherit;
            }
          `}
        >
          <h2>Alle poster</h2>
          <div>
            <label htmlFor="sort-after">Sorter etter</label>
            <select
              id="sort-after"
              onChange={(event) => setSortAfter(event.target.value)}
              value={sortAfter}
              className={css`
                padding: 8px;
                border-radius: 2px;
                width: 150px;
                border: none;
                appearance: none;
                font-size: inherit;
                background-color: ${theme.colors.base.background.primary};
                background-image: url(${chevronIcon});
                background-repeat: no-repeat, repeat;
                background-position: right 0.7em top 50%, 0 0;
                background-size: 0.65em auto, 100%;
              `}
            >
              <option value="OLDEST">Eldste</option>
              <option value="NEWEST">Nyeste</option>
              <option value="LIKES">Flest epler</option>
            </select>
          </div>
        </div>
        {postsResult.isLoading && <div>Loading...</div>}
        {postsResult.data && (
          <div
            className={cx(
              css`
                margin-top: 24px;
              `,
              viewMode === "GRID"
                ? css`
                    display: grid;
                    grid-gap: 20px;
                    grid-template-columns: repeat(auto-fit, 250px);
                    grid-template-rows: repeat(auto-fit, 400px);
                    align-items: center;
                    justify-items: center;
                  `
                : css`
                    display: flex;
                    flex-direction: column;
                  `
            )}
          >
            {postsResult.data.posts
              .filter((post) =>
                gradeFilter.length > 0
                  ? post.tags.some((tag) => gradeFilter.includes(tag))
                  : true
              )
              .filter((post) =>
                topicFilter.length > 0
                  ? post.tags.some((tag) => topicFilter.includes(tag))
                  : true
              )
              .sort((a, b) => {
                if (sortAfter === "OLDEST") {
                  return a.id - b.id;
                }
                if (sortAfter === "NEWEST") {
                  return b.id - a.id;
                }
                if (sortAfter === "LIKES") {
                  return b.likes - a.likes;
                }

                return a.id - b.id;
              })
              .map((post) => (
                <Card
                  mode={viewMode === "GRID" ? "VERTICAL" : "HORIZONTAL"}
                  key={`${post.id}-${post.title}`}
                  post={post}
                  refetchPosts={refetchPosts}
                />
              ))}
          </div>
        )}
      </div>
      <div
        className={css`
          display: flex;
          justify-content: flex-end;
          margin-top: 16px;
        `}
      >
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Til toppen{" "}
          <span
            className={css`
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background-color: ${theme.colors2.cta.primary};
              display: flex;
              justify-content: center;
              margin-left: 16px;
              align-items: center;
            `}
          >
            <Arrow
              color={theme.colors2.cta.secondary}
              direction="UP"
              size={16}
            />
          </span>
        </button>
      </div>
    </div>
  );
};

const TagFilter: FC<{
  gradeFilter: string[];
  setGradeFilter: (tag: string) => void;
  topicFilter: string[];
  setTopicFilter: (tag: string) => void;
}> = ({ gradeFilter, setGradeFilter, topicFilter, setTopicFilter }) => {
  return (
    <div>
      <h1
        className={css`
          margin-bottom: 24px;
        `}
      >
        Filtrér innholdet
      </h1>
      <TagFilterRow
        title="Klassetrinn"
        tags={grade}
        selectedTags={gradeFilter}
        onTagClick={setGradeFilter}
      />
      <TagFilterRow
        title="Fag"
        tags={topic}
        selectedTags={topicFilter}
        onTagClick={setTopicFilter}
      />
    </div>
  );
};

export const TagFilterRow: FC<{
  tags: string[];
  title: string;
  selectedTags: string[];
  onTagClick: (tag: string) => void;
  className?: string;
}> = ({ tags, title, selectedTags, onTagClick, className }) => (
  <div
    className={cx(
      css`
        display: flex;
        align-items: flex-start;

        * {
          hyphens: none;
          white-space: nowrap;
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;
        }
      `,
      className
    )}
  >
    <h2
      className={css`
        font-size: 24px;
        min-width: 120px;
        margin-right: 20px;
        text-align: right;
        padding-bottom: 20px;
        font-weight: 400;

        @media screen and (max-width: 600px) {
          text-align: left;
          font-weight: 300;
          font-size: 14px;
        }
      `}
    >
      {title}
    </h2>
    <div
      className={css`
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;

        @media screen and (max-width: 600px) {
          max-width: 100%;
          flex-wrap: nowrap;
          overflow-x: auto;
        }
      `}
    >
      {tags.map((tag: string) => (
        <TagButton
          key={tag}
          isSelected={selectedTags.includes(tag)}
          onClick={() => onTagClick(tag)}
          tag={tag}
        />
      ))}
    </div>
  </div>
);
