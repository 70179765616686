import { Link } from "@reach/router";
import { css } from "emotion";
import React, { FC } from "react";
import { CopyDataButton } from "../../components/CopyDataButton";
import { Arrow, ArrowCorner } from "../../components/icons/Arrow";
import linkImg from "../../components/icons/link.svg";
import theme from "../../utils/theme";
import { useDataApi } from "../../utils/useDataApi";
import { PostData } from "../home/Home";

export const Share: FC<{
  postId: number;
  resetForm: () => void;
}> = ({ postId, resetForm }) => {
  const [postResponse] = useDataApi<PostData>(`/api/post/${postId}`);

  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        padding: 20px;
        flex-direction: column;
        align-items: center;
      `}
    >
      <h1>Opplegget ditt er lastet opp, tusen takk for at du bidrar!</h1>
      <p
        className={css`
          margin-top: 10px;
          margin-bottom: 80px;
          a {
            color: #4d76d2;
          }
        `}
      >
        Alle dine bidrag finnes på <Link to="/my-page">Min side</Link>, hvor du
        også kan redigere dem
      </p>

      <div
        className={css`
          margin-bottom: 16px;
          button,
          a {
            width: 250px;
            height: 50px;
            border-radius: 4px;
            background-color: ${theme.colors.base.background.secondary};
            color: ${theme.colors.base.text.secondary};
            font-size: 18px;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
      >
        <button
          className={css`
            margin-bottom: 60px;
            border: 1px solid ${theme.colors.base.background.secondary};
          `}
          style={{
            backgroundColor: theme.colors.base.background.primary,
            color: theme.colors.base.text.primary,
          }}
          onClick={() => {
            window.scrollTo(0, 0);
            resetForm();
          }}
        >
          Last opp flere bidrag
          <ArrowCorner
            direction="UP"
            size={16}
            className={css`
              margin-left: 8px;
            `}
          />
        </button>
        <Link
          className={css`
            margin-bottom: 8px;
          `}
          to="/"
        >
          <Arrow
            size={16}
            direction="LEFT"
            color={theme.colors.base.text.secondary}
            className={css`
              margin-right: 8px;
            `}
          />
          Gå til forsiden
        </Link>
        <Link
          className={css`
            margin-bottom: 50px;
          `}
          to="/my-page"
        >
          Gå til Min side
          <Arrow
            size={16}
            direction="RIGHT"
            color={theme.colors.base.text.secondary}
            className={css`
              margin-left: 8px;
            `}
          />
        </Link>

        <CopyDataButton
          data={`${window.location.origin}/p/${postResponse.data?.id}`}
        >
          Kopier lenke
          <img src={linkImg} alt="" style={{ height: 16, marginLeft: 8 }} />
        </CopyDataButton>
      </div>
    </div>
  );
};
