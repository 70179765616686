import React, {FC, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import {css} from "emotion";
import {createComment} from "./comment_util";
import theme from "../../../utils/theme";

export const NewComment: FC<
  RouteComponentProps & {
  postId: number,
  callback: () => void;
  parentId?: number;
}> = ({ postId, callback, parentId }) => {
  const [myComment, setMyComment] = useState('');
  const placeholderText = parentId
    ? 'Skriv svaret ditt her'
    : 'Har du noe du lurer på, eller vil du bare gi noen hyggelige ord til bidragsyteren?';
  
  const _callback = () => {
    setMyComment('');
    callback();
  };
  
  return (
    <div className={css`
      display: flex;
      flex-direction: row;
      > * {
        margin: 5px;
      }
      @media screen and (max-width: 460px) {
        flex-direction: column;
      }
    `}>
      <textarea
        value={myComment}
        onChange={(e) => setMyComment(e.target.value)}
        placeholder={placeholderText}
        className={css`
          resize: none;
          flex: 1 1 auto;
          background: #F5F5F5;
          border-radius: 2px;
          border: none;
          padding: 20px 16px;
        `}
      />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        `}
      >
        <button
          onClick={() => createComment(postId, myComment, _callback, parentId)}
          className={css`
          padding: 10px;
          border: 1px solid ${theme.colors2.cta.primary};
          border-radius: 4px;
        `}
        >
          Send
        </button>
      </div>
    </div>
  )
}