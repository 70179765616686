import { css, cx, keyframes } from "emotion";
import React, { FC } from "react";
import { useInterval } from "../utils/hooks";
import theme from "../utils/theme";

// https://material-ui.com/components/alert/#alert
export const Alert: FC<{
  level: "SUCCESS" | "WARNING" | "ERROR" | "INFO";
  custom?: {
    background: string;
    text: string;
  };
  marginTop?: number;
  className?: string;
}> = ({ level, marginTop, children, custom, className }) => (
  <div
    className={cx(
      css`
        background-color: ${custom?.background || colors[level].background};
        color: ${custom?.text || colors[level].text};
        border-radius: 4px;
        padding: 10px 20px;
        margin-top: ${marginTop || 0}px;
      `,
      className
    )}
  >
    {children}
  </div>
);
const colors = {
  SUCCESS: theme.colors.base.alert.success,
  WARNING: theme.colors.base.alert.warning,
  ERROR: theme.colors.base.alert.error,
  INFO: theme.colors.base.alert.info,
};

export const AutoDismissAlert: FC<{
  autoDismiss: number;
  className?: string;
  closeAlert: () => void;
}> = ({ autoDismiss, children, className, closeAlert }) => {
  useInterval(() => {
    closeAlert();
  }, autoDismiss || null);

  return (
    <div
      onClick={closeAlert}
      className={cx(
        css`
          background-color: ${theme.colors2.accent.secondary};
          color: ${theme.colors2.text.primary};
          box-shadow: ${theme.shadows.large};
          text-align: center;
          opacity: 0;
          border-radius: 4px;
          padding: 20px 60px;
          position: relative;
          animation: ${fadeInOut} forwards ease-in-out;
          animation-duration: ${autoDismiss}ms;
        `,
        className
      )}
    >
      {children}
    </div>
  );
};

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
  `;
