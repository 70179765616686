import React, {FC, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import type {Comment} from "../../home/Home";
import {css} from "emotion";
import {NewComment} from "./NewComment";
import trashImg from "../../../components/icons/trash.svg";
import axios from "axios";
import {useUserContext} from "../../../lib/UserContext";
import {LikeButton} from "../../../components/Buttons";
import {colors2} from "../../../utils/theme";
import {AlertModal} from "../../../components/modals/AlertModal";
import {FeedbackModal} from "../../../components/modals/FeedbackModal";

export const PostComment: FC<RouteComponentProps & {
	comment: Comment,
	postId: number,
	refetchPost: () => void;
	key?: number
}> = ({comment, postId, refetchPost}) => {
	const [showNewComment, setShowNewComment] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const {user, isLoggedIn, showLogInOrSignUp} = useUserContext();
	const [liked, setLiked] = useState<boolean>(comment.isLiked);
	const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
	
	const toggleDeleted = async () => {
		const data = user?.id === comment.author.id ?
			{
				deleted: !comment.deleted
			}
			: user?.admin ? {
				deletedByAdmin: !comment.deletedByAdmin
			} : null
		await axios.put(`/api/post/${postId}/comment/${comment.id}`, data);
		setShowDeleteModal(false);
		refetchPost();
	}
	
	const toggleLike = async () => {
		if (!isLoggedIn) {
			showLogInOrSignUp("LOG_IN", "Hei! For å gi epler må du være logget inn.");
			return;
		}
		const data = {
			liked: !liked
		};
		setLiked(data.liked)
		await axios.put(`/api/post/${postId}/comment/${comment.id}`, data);
		refetchPost();
	}
	
	const norwegianDateString = new Date(comment.createdTime)
		.toLocaleDateString('no-NO', {month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'});
	
	const handleRespondClick = () => {
		if (!isLoggedIn) {
			showLogInOrSignUp("LOG_IN", "Hei! For å svare må du være logget inn.");
			return;
		}
		setShowNewComment(!showNewComment)
	}
	
	const startReportCommentFlow = () => {
		if (!isLoggedIn) {
			showLogInOrSignUp("LOG_IN", "Hei! For å rapportere må du være logget inn.");
			return;
		}
		setShowFeedbackModal(true)
	}
	
	const postCommentFlag = async (feedbackText: string) => {
		await axios.post(`/api/post/${postId}/comment/${comment.id}/flag`, {reason: feedbackText});
	};
	
	const newCommentCallback = () => {
		setShowNewComment(false);
		refetchPost();
	}
	
	return (
		<div className={css`
			margin-bottom: 20px;
		`}>
			<div className={css`
				padding-left: 18px;
				border-left: 2px solid orange;
				margin: 10px;
				display: flex;
				flex-direction: column;
			`}>
			<div className={css`
				margin-bottom: 5px;
				color: #33140C;
				font-size: 12px;
				opacity: 0.5;
				line-height: 15px;
			`}>
				{comment.author.name} - {norwegianDateString}
			</div>
				<div className={css`
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 25px;
					display: flex;
					flex-direction: row;
				`}>
					<div className={css`
						flex: 1 0 auto;
						max-width: 100%;
						${(comment.deleted || comment.deletedByAdmin) ? 'font-style: italic;' : ''}
					`}>
						{comment.text}
					</div>
					{
						((user?.id === comment.author.id) && !comment.deletedByAdmin) || (user?.admin && !comment.deleted) ? (
							<button onClick={() => setShowDeleteModal(true)}>
								{comment.deleted || comment.deletedByAdmin ? 'Angre sletting' : <img src={trashImg} alt=""/>}
							</button>
						) : null
					}
					{showDeleteModal && (
						<AlertModal
							onConfirm={toggleDeleted}
							onCancel={() => setShowDeleteModal(false)}
							message={comment.deleted || comment.deletedByAdmin ? 'Angre sletting?' : 'Er du sikker på at du vil slette kommentaren?'}
						/>
					)}
					{showFeedbackModal &&
						<FeedbackModal
							message={'Hvorfor ønsker du å rapportere denne kommentaren?'}
							onConfirm={postCommentFlag}
							onClose={() => setShowFeedbackModal(false)}
						/>
					}
				</div>
			</div>
			<div className={css`
				display: flex;
				padding-left: 28px;
			`}>
				<LikeButton
					isLoggedIn={!!user}
					onClick={toggleLike}
					isLiked={liked}
					likes={comment.likes}
					strokeColor={colors2.cta.primary}
					size={12}
				/>
				<button
					onClick={handleRespondClick}
					className={css`
					margin-left: 20px;
					font-size: 12px;
					line-height: 25px;
			`}
				>
					Svar
				</button>
				<button
					onClick={startReportCommentFlow}
					className={css`
						margin-left: 20px;
						font-size: 12px;
						line-height: 25px;
					`}
				>
					Rapporter
				</button>
			</div>
			{
				showNewComment && <NewComment postId={postId} parentId={comment.id} callback={newCommentCallback}/>
			}
			{
				comment.children.length ? comment.children
					.sort((a, b) => (new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()))
					.map(child => (
						<div className={css`
							margin-left: 20px;
						`}
						 key={child.id}
						>
							<PostComment comment={child} postId={postId} refetchPost={refetchPost}/>
						</div>
					)) : null
			}
		</div>
	);
}