import { css } from "emotion";
import React, { FC } from "react";
import { UploadedFile } from "../Upload";
import { FileItem, LinkItem } from "./FileItem";

export const StepDescription: FC<{
  title?: string;
  files?: UploadedFile[];
  cannotDeleteFiles?: boolean;
  links?: string[];
  removeLink?: (index: number) => void;
  deleteFile?: (index: number, type: "UPLOADED" | "CHOSEN") => void;
}> = ({ title, files, cannotDeleteFiles, links, removeLink, deleteFile }) => (
  <div
    className={css`
      margin-top: 20px;
      max-height: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;

      @media screen and (max-width: 800px) {
        height: auto;
      }
    `}
  >
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      {title && (
        <h2
          className={css`
            margin-top: 30px;
            margin-bottom: 20px;
            height: 30px;

            @media screen and (max-width: 800px) {
              height: auto;
            }
          `}
        >
          {title}
        </h2>
      )}
      <ul
        className={css`
          margin-top: 10px;
          max-height: 120px;
          overflow-y: auto;

          li {
            background-color: rgba(255, 255, 255, 0.7);
            margin-bottom: 2px;

            &:hover {
              background-color: rgba(255, 255, 255, 0.5);
            }
          }
        `}
      >
        {files?.reverse().map((file, index) => (
          <FileItem
            file={file}
            index={index}
            key={`${file}-${index}`}
            deleteFile={deleteFile}
            cannotDeleteFiles={cannotDeleteFiles}
          />
        ))}
        {links?.reverse().map((link, index) => (
          <LinkItem
            link={link}
            index={index}
            key={link}
            deleteIcon="CROSS"
            removeLink={removeLink}
          />
        ))}
      </ul>
    </div>
  </div>
);
