import { css } from "emotion";
import React, { FC, useRef, useState } from "react";
import { LinkIcon } from "./icons/LinkIcon";
import { Modal } from "./modal/Modal";

export const CopyDataButton: FC<{ data: string; className?: string }> = ({
  data,
  className,
  children,
}) => {
  const [showLinkCopiedModal, setShowLinkCopiedModal] = useState<boolean>(
    false
  );
  const copyDataRef = useRef<HTMLInputElement>(null);

  const handleCopyClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (data) {
      if (copyDataRef.current) {
        copyDataRef.current.select();
        document.execCommand("copy");
      }
    }
    setShowLinkCopiedModal(true);
  };

  return (
    <>
      {showLinkCopiedModal && (
        <Modal
          closeModal={() => setShowLinkCopiedModal(false)}
          hideDefaultButtons
          autoDismiss={2000}
          modalSize={{
            width: "490px",
            height: "400px",
          }}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;

              h1 {
                margin-top: 50px;
                margin-bottom: 70px;
              }
              svg {
                margin-bottom: 90px;
              }
            `}
          >
            <h1>Lenken er kopiert!</h1>
            <LinkIcon size={120} color="#ff653a" />
          </div>
        </Modal>
      )}
      <input
        ref={copyDataRef}
        type="text"
        value={data}
        readOnly
        className={css`
          position: absolute;
          left: -10000px;
        `}
      />
      <button className={css(className)} onClick={handleCopyClick}>
        {children}
      </button>
    </>
  );
};
