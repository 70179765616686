import React, {FC, useState} from "react";
import {Comment, CommentFlag} from "../home/Home";
import {css} from "emotion";
import theme from "../../utils/theme";
import {AlertModal} from "../../components/modals/AlertModal";
import axios from "axios";
import {LinkButton, TextLink} from "../../components/Buttons";

export const CommentFlagCard: FC<{
  commentFlag: CommentFlag;
  refetchCommentFlags: () => void;
  filter: string;
}> = ({commentFlag, refetchCommentFlags}) => {
  const [showArchiveFlagModal, setShowArchiveFlagModal] = useState<boolean>(false);
  
  const toggleAdminDeleteComment = async (comment: Comment) => {
    const data = {
      deletedByAdmin: !comment.deletedByAdmin
    }
    await axios.put(`/api/post/${comment.postId}/comment/${comment.id}`, data);
    refetchCommentFlags();
  };
  
  const archiveFlag = async (id: number) => {
    const data = {
      archived: true
    }
    await axios.put(`/api/post/comment-flags/${id}`, data);
    setShowArchiveFlagModal(false)
    refetchCommentFlags();
  };
  
  return (
    <div
      key={commentFlag.id}
      className={css`
        width: 100%;
        min-height: 100px;
        background-color: ${theme.colors.base.background.primary};
        padding: 20px;
        margin-bottom: 8px;
        display: grid;
        justify-content: space-between;
        align-items: top;
        grid-template-areas: "grade title tagsAndName likesDownloadsAndMenu share menu";
        grid-template-columns: minmax(auto, 100px) 3fr 3fr 1fr 1fr 1fr;
        cursor: default;
        position: relative;

        &:hover {
          box-shadow: ${theme.shadows.small};
        }

        @media screen and (max-width: 1100px) {
          grid-template-areas:
            "grade likesDownloadsAndMenu"
            "title title"
            "tagsAndName tagsAndName"
            ". share";
          grid-template-rows: repeat(4, auto);
          grid-template-columns: 1fr 1fr;
          grid-gap: 8px;
          border-radius: 2px;
        }
      `}
    >
      <div>
        <div>Kommentar av {commentFlag.comment.author.name}:</div>
        <div className={css`
          font-size: 12px;
        `}
        >
          {commentFlag.comment.text}</div>
      </div>
      <div>
        <div>Tilbakemelding fra {commentFlag.reporter.name}:</div>
        <div className={css`
          font-size: 12px;
        `}
        >
          {commentFlag.reason}
        </div>
      </div>
      <div>
        <TextLink to={`/p/${commentFlag.comment.postId}`}>Gå til bidrag</TextLink>
        {
          commentFlag.comment.deleted && <div>Kommentaren er slettet av brukeren</div>
        }
        {
          commentFlag.comment.deletedByAdmin && <div>Kommentaren er slettet av administrator</div>
        }
        <button onClick={() => toggleAdminDeleteComment(commentFlag.comment)}>
          {commentFlag.comment.deletedByAdmin ? 'Angre sletting' : 'Slett kommentar'}
        </button>
        <button onClick={() => setShowArchiveFlagModal(true)}>Arkiver tilbakemelding</button>
        {
          showArchiveFlagModal && (
            <AlertModal
              message={'Vil du arkivere tilbakemeldingen?'}
              onConfirm={() => archiveFlag(commentFlag.id)}
              onCancel={() => setShowArchiveFlagModal(false)}
            />
          )
        }
      </div>
    </div>
  )
}