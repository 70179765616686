import {
  RouteComponentProps,
  Link,
  navigate,
  useLocation,
} from "@reach/router";
import { css, cx } from "emotion";
import React, { FC, useState, useCallback } from "react";
import { Arrow, ArrowCorner } from "../../components/icons/Arrow";
import pencilImg from "../../components/icons/pencil.svg";
import eyeImg from "../../components/icons/eye.svg";
import { Tag, tagWrapperStyle } from "../../components/Tags";
import theme from "../../utils/theme";
import { TagSearch } from "../upload/TagSearch";
import { PostData, PostFile } from "../home/Home";
import { useDataApi } from "../../utils/useDataApi";
import { Alert, AutoDismissAlert } from "../../components/Alert";
import { FileItem, LinkItem } from "../upload/components/FileItem";
import { UploadedFile } from "../upload/Upload";
import { InnerDropZone } from "../upload/FileAndLinkUpload";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import { DropToAdd } from "../upload/components/DropToAdd";
import { Modal } from "../../components/modal/Modal";

export const EditPost: FC<RouteComponentProps & { id?: string | number }> = ({
  id,
}) => {
  const [postResponse, refetchPost] = useDataApi<PostData>(`/api/post/${id}`);

  return (
    <div
      className={css`
        margin-top: 180px;
      `}
    >
      <div
        className={css`
          width: 100vw;
          max-width: 100%;
          min-height: 100vh;
          background-color: ${theme.colors2.background.myPage};
        `}
      >
        <div
          className={css`
            width: 90vw;
            max-width: 1330px;
            margin: 0 auto;
            padding: 40px 0;
          `}
        >
          {postResponse.isLoading ||
            (postResponse.error && (
              <>
                <Link
                  className={css`
                    display: flex;
                    align-items: center;
                  `}
                  to="/my-page"
                >
                  <Arrow
                    size={16}
                    direction="LEFT"
                    className={css`
                      margin-right: 16px;
                    `}
                  />
                  Tilbake til Min side
                </Link>
                <div
                  className={css`
                    margin-top: 40px;
                    margin-bottom: 30px;
                  `}
                >
                  <h2>Rediger innhold</h2>
                </div>
              </>
            ))}
          {postResponse.isLoading && <div>Laster...</div>}
          {postResponse.error && (
            <Alert
              level="ERROR"
              className={css`
                margin-top: 20px;
              `}
            >
              {postResponse.error.toString()}
            </Alert>
          )}
          {postResponse.data && (
            <Content post={postResponse.data} refetchPost={refetchPost} />
          )}
        </div>
      </div>
    </div>
  );
};
export const Content: FC<{
  post: PostData;
  refetchPost: () => void;
}> = ({ post, refetchPost }) => {
  const [tags, setTags] = useState<string[]>(post.tags);
  const [title, setTitle] = useState<string>(post.title);
  const [description, setDescription] = useState<string>(post.description);
  const [currentFiles, setCurrentFiles] = useState<PostFile[]>(
    post.files || []
  );
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [chosenFiles, setChosenFiles] = useState<File[]>([]);
  const [links, setLinks] = useState<string[]>(post.links || []);
  const [link, setLink] = useState<string>("");
  const [isUploadingFiles, setIsUploadingFiles] = useState<number>(0);
  const [cancelEditWarning, setCancelEditWarning] = useState<boolean>(false);
  const [showSavedAlert, setShowSavedAlert] = useState<boolean>(false);
  const [postStatus, setPostStatus] = useState<{
    loading: boolean;
    error?: string;
  }>({
    loading: false,
    error: undefined,
  });
  const location = useLocation();
  const asAdmin = location.pathname.includes("admin");

  React.useEffect(() => {
    console.log("links", links);
  }, [links]);

  const postIsEdited =
    JSON.stringify(tags) !== JSON.stringify(post.tags) ||
    title !== post.title ||
    description !== post.description ||
    currentFiles.length !== post.files.length ||
    files.length > 0 ||
    chosenFiles.length > 0;

  const handleAddTag = (tag: string) => {
    if (!tags.find((t) => t.toLocaleLowerCase() === tag.toLocaleLowerCase())) {
      setTags((tags) => [...tags, tag]);
    }
  };

  const handleRemoveTag = (tag: string) => {
    const tagIndex = tags.findIndex((t) => t === tag);
    if (tagIndex !== -1) {
      let tagsCopy = [...tags];
      tagsCopy.splice(tagIndex, 1);
      setTags(tagsCopy);
    }
  };

  const handleUpdateFiles = (f: UploadedFile, serverId?: string): string => {
    const id = f.id || `_tmp-${Math.floor(Math.random() * 10000)}`;

    setFiles((files) => {
      const existingFileIndex = files.findIndex((file) => file.id === f.id);
      if (existingFileIndex === -1) {
        return [
          ...files,
          {
            ...f,
            id: serverId || id,
          },
        ];
      } else {
        let filesCopy = [...files];
        filesCopy[existingFileIndex] = {
          ...f,
          id: serverId || id,
        };
        return filesCopy;
      }
    });

    return id;
  };
  const handleFileUpload = async () => {
    if (chosenFiles.length > 0) {
      setIsUploadingFiles(chosenFiles.length);
      chosenFiles.forEach(async (file) => {
        let data = new FormData();

        const tmpId = handleUpdateFiles({
          name: file.name,
          loading: true,
        });
        data.append("file", file);
        try {
          let res = await Axios.post("/api/upload", data);

          if (res?.data?.file_id) {
            handleUpdateFiles(
              {
                id: tmpId,
                name: file.name,
                loading: false,
              },
              res.data.file_id
            );
          }
        } catch (e) {
          handleUpdateFiles({
            id: tmpId,
            name: file.name,
            loading: false,
            error: e.toString(),
          });
        }

        setIsUploadingFiles((count) => count - 1);
      });

      setChosenFiles([]);
    }
  };

  const handleDeleteFile = (
    index: number,
    type: "UPLOADED" | "CHOSEN" | "CURRENT"
  ) => {
    if (type === "CHOSEN") {
      let chosenFilesCopy = [...chosenFiles];
      chosenFilesCopy.splice(index, 1);
      setChosenFiles(chosenFilesCopy);
    } else if (type === "UPLOADED") {
      let uploadedFilesCopy = [...files];
      uploadedFilesCopy.splice(index, 1);
      setFiles(uploadedFilesCopy);
    } else if (type === "CURRENT") {
      let currentFilesCopy = [...currentFiles];
      currentFilesCopy.splice(index, 1);
      setCurrentFiles(currentFilesCopy);
    }
  };

  const handleRemoveLink = (index: number) => {
    let linksCopy = [...links];
    linksCopy.splice(index, 1);
    setLinks(linksCopy);
  };

  const handleAddLink = () => {
    const linkExists = Boolean(links.find((l) => l === link));
    if (linkExists) {
      alert("Du har allerede lagt til denne linken");
    } else {
      setLinks((currentLinks) => [...currentLinks, link]);
      setLink("");
    }
  };

  const handleCancelEditClick = () => {
    if (postIsEdited) {
      setCancelEditWarning(true);
    } else {
      navigate(asAdmin ? "/admin" : "/my-page");
    }
  };

  const handleSaveClick = useCallback(async () => {
    setPostStatus({
      loading: true,
    });
    try {
      const postResponse = await Axios.put<PostData>(`/api/post/${post.id}`, {
        title,
        tags,
        description,
        links,
        files: [...currentFiles, ...files].map((file) => file.id),
      });

      setPostStatus({
        loading: false,
      });

      refetchPost();
      setShowSavedAlert(true);
      setTitle(postResponse.data.title);
      setTags(postResponse.data.tags);
      setDescription(postResponse.data.description);
      setCurrentFiles(postResponse.data.files);
      setFiles([]);
      setChosenFiles([]);
    } catch (error) {
      setPostStatus({
        loading: false,
        error: error.toString(),
      });
    }
  }, [
    title,
    tags,
    description,
    files,
    links,
    currentFiles,
    post.id,
    setShowSavedAlert,
    setTitle,
    setTags,
    setFiles,
    setDescription,
    setCurrentFiles,
    setChosenFiles,
    refetchPost,
  ]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setChosenFiles((currentFiles) => [...currentFiles, ...acceptedFiles]);
    },
    [setChosenFiles]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <>
      {cancelEditWarning && (
        <CancelEditWarningModal
          closeModal={() => setCancelEditWarning(false)}
        />
      )}
      <button
        className={css`
          display: flex;
          align-items: center;
        `}
        onClick={handleCancelEditClick}
        disabled={postStatus.loading}
      >
        <Arrow
          size={16}
          direction="LEFT"
          className={css`
            margin-right: 16px;
          `}
        />
        Tilbake til {asAdmin ? "Admin" : "Min side"}
      </button>
      <div
        className={css`
          margin-top: 40px;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <h2>Rediger innhold</h2>
        <button
          className={css`
            font-size: 24px;
            font-weight: 300;
          `}
        >
          Forhåndsvis
          <span
            className={css`
              margin-left: 16px;
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background-color: ${theme.colors2.cta.secondary};
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <img
              src={eyeImg}
              alt=""
              className={css`
                widows: 22px;
              `}
            />
          </span>
        </button>
      </div>
      <div
        className={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 50px;

          @media screen and (max-width: 900px) {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
          }
        `}
      >
        <div>
          <div>
            <div
              className={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <label
                htmlFor="title"
                className={css`
                  font-weight: 300;
                  margin-left: 24px;
                `}
              >
                TITTEL
                <img
                  src={pencilImg}
                  alt=""
                  style={{ height: 16, marginLeft: 8 }}
                />
              </label>
              <input
                id="title"
                type="text"
                placeholder="Tittel til posten"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                className={css`
                  margin-top: 8px;
                  height: 60px;
                  padding: 16px 24px;
                  border-radius: 2px;
                `}
              />
            </div>
            <div
              className={css`
                display: flex;
                flex-direction: column;
                margin-top: 60px;
              `}
            >
              <label
                htmlFor="title"
                className={css`
                  font-weight: 300;
                  margin-left: 24px;
                `}
              >
                BESKRIVELSE
                <img
                  src={pencilImg}
                  alt=""
                  style={{ height: 16, marginLeft: 8 }}
                />
              </label>
              <textarea
                id="title"
                placeholder="Beskrivelse av posten"
                rows={8}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                className={css`
                  margin-top: 8px;
                  padding: 16px 24px;
                  border-radius: 2px;
                  border: none;
                `}
              />
            </div>
          </div>
          <div
            className={css`
              margin-top: 50px;
            `}
          >
            <p
              className={css`
                font-weight: 300;
                margin-left: 24px;
                margin-bottom: 24px;
              `}
            >
              Emneknagger
            </p>
            <TagSearch
              selectedTags={tags}
              addTag={handleAddTag}
              removeTag={handleRemoveTag}
              inputClassName={css`
                padding: 16px 24px;
              `}
            />
            {tags.length > 0 && (
              <div
                className={cx(
                  tagWrapperStyle,
                  css`
                    position: relative;
                  `
                )}
              >
                {tags.map((tag) => (
                  <Tag
                    key={tag}
                    tag={tag}
                    tags={tags}
                    isSelected
                    coreTagButton
                    addTag={handleAddTag}
                    removeTag={handleRemoveTag}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <label
              htmlFor="files"
              className={css`
                font-weight: 300;
                margin-left: 24px;
              `}
            >
              FILVEDLEGG
            </label>
            <div
              {...getRootProps({ className: "dropzone" })}
              className={css`
                background-color: rgba(255, 255, 255, 0.6);
                position: relative;
              `}
            >
              {isDragActive && (
                <DropToAdd
                  className={css`
                    top: 0;
                    bottom: 0;
                  `}
                />
              )}
              <ul
                className={css`
                  margin-top: 10px;
                  max-height: 500px;
                  overflow: auto;
                `}
              >
                {currentFiles.map((file, index) => (
                  <FileItem
                    file={file}
                    index={index}
                    key={`${file}-${index}`}
                    deleteFile={() => handleDeleteFile(index, "CURRENT")}
                    cannotDeleteFiles={false}
                    deleteIcon="TRASH"
                  />
                ))}
                {files.map((file, index) => (
                  <FileItem
                    file={file}
                    index={index}
                    key={`${file}-${index}`}
                    deleteFile={() => handleDeleteFile(index, "UPLOADED")}
                    cannotDeleteFiles={false}
                    deleteIcon="TRASH"
                  />
                ))}
              </ul>
              <div
                className={css`
                  display: flex;
                  align-items: flex-end;
                  padding: 20px;

                  @media screen and (max-width: 500px) {
                    flex-direction: column;
                    align-items: flex-start;

                    button: {
                      margin-top: 8px;
                    }
                  }
                `}
              >
                <InnerDropZone
                  isDragActive={isDragActive}
                  onDrop={onDrop}
                  id="upload"
                  className={css`
                    margin-right: 8px;
                    p {
                      font-size: 20px;
                    }
                  `}
                />
                <button
                  onClick={handleFileUpload}
                  disabled={chosenFiles.length < 1}
                  className={css`
                    align-items: center;
                    background-color: ${theme.colors2.cta.secondary};
                    color: ${theme.colors2.cta.primary};
                    border: 1px solid ${theme.colors2.cta.primary};
                    border-radius: 4px;
                    height: 60px;
                    padding: 20px 16px;
                    white-space: nowrap;

                    &:disabled {
                      opacity: 0.4;
                      cursor: not-allowed;
                    }

                    svg {
                      margin-left: 8px;
                    }
                  `}
                >
                  {chosenFiles.length > 1
                    ? "Last opp nye filer"
                    : "Last opp ny fil"}
                  <ArrowCorner size={16} color={theme.colors.upload.primary} />
                </button>
              </div>
              {chosenFiles.length > 0 && (
                <p
                  className={css`
                    padding: 0 20px;
                  `}
                >
                  Filer klare til opplasting
                </p>
              )}
              <ul
                className={css`
                  margin-top: 10px;
                  max-height: 500px;
                  overflow: auto;
                `}
              >
                {chosenFiles.map((file, index) => (
                  <FileItem
                    file={file}
                    index={index}
                    key={`${file}-${index}`}
                    deleteFile={() => handleDeleteFile(index, "CHOSEN")}
                    cannotDeleteFiles={false}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div
            className={css`
              margin-top: 60px;
            `}
          >
            <label
              htmlFor="links"
              className={css`
                font-weight: 300;
                margin-left: 24px;
              `}
            >
              LENKER
            </label>
            <div
              className={css`
                margin-top: 10px;
                background-color: rgba(255, 255, 255, 0.6);
              `}
            >
              {links?.reverse().map((link, index) => (
                <LinkItem
                  link={link}
                  index={index}
                  key={link}
                  deleteIcon="CROSS"
                  removeLink={handleRemoveLink}
                />
              ))}
              <div
                className={css`
                  display: flex;
                  align-items: flex-end;
                  padding: 20px;

                  @media screen and (max-width: 500px) {
                    flex-direction: column;
                    align-items: flex-start;

                    button: {
                      margin-top: 8px;
                    }
                  }
                `}
              >
                <input
                  type="text"
                  value={link}
                  onChange={(event) => setLink(event.target.value)}
                  className={css`
                    height: 70px;
                    font-size: 24px;
                    padding: 0 24px;
                    margin-right: 8px;
                    color: ${theme.colors2.cta.tertiary};
                  `}
                />
                <button
                  onClick={handleAddLink}
                  disabled={!link}
                  className={css`
                    align-items: center;
                    background-color: ${theme.colors2.cta.secondary};
                    color: ${theme.colors2.cta.primary};
                    border: 1px solid ${theme.colors2.cta.primary};
                    border-radius: 4px;
                    height: 60px;
                    padding: 20px 16px;
                    white-space: nowrap;

                    &:disabled {
                      opacity: 0.4;
                      cursor: not-allowed;
                    }

                    svg {
                      margin-left: 8px;
                    }
                  `}
                >
                  Legg til ny link
                  <ArrowCorner size={16} color={theme.colors.upload.primary} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 60px;

          button {
            width: 145px;
            height: 60px;
            border-radius: 4px;
            margin: 20px;
          }
        `}
      >
        {postStatus.error && (
          <Alert level="ERROR">{postStatus.error.toString()}</Alert>
        )}

        <div
          className={css`
            display: flex;
          `}
        >
          <button onClick={handleCancelEditClick} disabled={postStatus.loading}>
            {postIsEdited ? "Avbryt" : "Avslutt"}
          </button>
          <div
            className={css`
              position: relative;
            `}
          >
            {showSavedAlert && (
              <AutoDismissAlert
                autoDismiss={3000}
                closeAlert={() => setShowSavedAlert(false)}
                className={css`
                  position: absolute;
                  width: 300px;
                  right: -60px;
                  top: -50px;

                  @media screen and (max-width: 500px) {
                    width: 220px;
                    padding-right: 20px;
                    padding-left: 20px;
                    right: 10px;
                  }
                `}
              >
                Endringene er lagret!
              </AutoDismissAlert>
            )}
            <button
              className={css`
                background-color: ${theme.colors2.cta.primary};
                color: ${theme.colors2.text.secondary};

                &:disabled {
                  opacity: 0.4;
                }
              `}
              onClick={handleSaveClick}
              disabled={postStatus.loading || isUploadingFiles > 0}
            >
              Lagre
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const CancelEditWarningModal: FC<{
  closeModal: () => void;
}> = ({ closeModal }) => (
  <Modal
    closeModal={closeModal}
    modalSize={{ width: "300px", height: "350px" }}
  >
    <div
      className={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      `}
    >
      <div
        className={css`
          text-align: center;
          margin-top: 50px;
        `}
      >
        <h2>Er du sikker på at du vil avbryte redigeringen?</h2>
      </div>
      <div
        className={css`
          display: flex;
          justify-content: space-between;

          button,
          a {
            height: 60px;
            width: 140px;
            border-radius: 4px;
          }
        `}
      >
        <Link
          className={css`
            padding: 8px 16px;
            background-color: ${theme.colors2.cta.primary};
            color: ${theme.colors2.cta.secondary};
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          to="/my-page"
        >
          Ja, avbryt
        </Link>
        <button onClick={closeModal}>Avbryt</button>
      </div>
    </div>
  </Modal>
);
