import { Link } from "@reach/router";
import Axios from "axios";
import { format } from "date-fns";
import { css, cx } from "emotion";
import React, { FC, useState } from "react";
import useMeasure from "react-use-measure";
import { GradeTag } from "../../components/card/components/GradeTag";
import { CopyDataButton } from "../../components/CopyDataButton";
import { Apple } from "../../components/icons/Apple";
import { ArrowCorner } from "../../components/icons/Arrow";
import { Cross } from "../../components/icons/Cross";
import { LinkIcon } from "../../components/icons/LinkIcon";
import messageImg from "../../components/icons/message.svg";
import pencilImg from "../../components/icons/pencil.svg";
import thumbsUpImg from "../../components/icons/thumbsUp.svg";
import trashImg from "../../components/icons/trash.svg";
import { PostMenu } from "../../components/PostMenu";
import { TagsAsBobbles } from "../../components/Tags";
import theme from "../../utils/theme";
import { PostData } from "../home/Home";
import { ReasonType } from "./Admin";

export const AdminCard: FC<{
  post: PostData;
  flaggedData?: ReasonType[];
  refetchFlaggedPosts?: () => void;
  refetchPosts?: () => void;
}> = ({ post, flaggedData, refetchFlaggedPosts, refetchPosts }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [ref, measure] = useMeasure();

  const handleDeletePost = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Er du sikker på at du vil slette posten ${post.title}?`)) {
      try {
        await Axios.delete(`/api/post/${post.id}`);
      } catch (error) {
        alert(error);
      }
      refetchFlaggedPosts && refetchFlaggedPosts();
      refetchPosts && refetchPosts();
    }
  };

  const handleDeleteAllFlags = async () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Er du sikker på at du vil markere all flaggene som OK for ${post.title}?`
      )
    ) {
      if (flaggedData) {
        flaggedData.forEach(async (flag) => {
          try {
            await Axios.delete(`/api/flag/${flag.id}`);
          } catch (error) {
            alert(error);
          }
        });

        refetchFlaggedPosts && refetchFlaggedPosts();
      }
    }
  };

  const handleDeleteFlags = async (flagId: number) => {
    if (flaggedData) {
      try {
        await Axios.delete(`/api/flag/${flagId}`);
      } catch (error) {
        alert(error);
      }

      refetchFlaggedPosts && refetchFlaggedPosts();
    }
  };

  return (
    <div>
      <div
        ref={ref}
        onClick={() => {
          flaggedData && setExpanded(!expanded);
        }}
        className={css`
          width: 100%;
          min-height: 100px;
          background-color: ${theme.colors.base.background.primary};
          padding: 20px;
          margin-bottom: ${expanded ? 0 : 8}px;
          display: grid;
          justify-content: space-between;
          align-items: center;
          grid-template-areas: "grade title tagsAndName likesDownloadsAndMenu share menu";
          grid-template-columns: minmax(auto, 100px) 3fr 3fr 1fr 1fr 1fr;
          cursor: ${flaggedData ? "pointer" : "default"};
          position: relative;

          &:hover {
            box-shadow: ${theme.shadows.small};
          }

          @media screen and (max-width: 1100px) {
            grid-template-areas:
              "grade likesDownloadsAndMenu"
              "title title"
              "tagsAndName tagsAndName"
              ". share";
            grid-template-rows: repeat(4, auto);
            grid-template-columns: 1fr 1fr;
            grid-gap: 8px;
            border-radius: 2px;
          }
        `}
      >
        <GradeTag
          tags={post.tags}
          className={css`
            grid-area: grade;
            margin-right: 10px;
            flex-wrap: wrap;

            span {
              margin-bottom: 3px;
            }
          `}
        />
        <div
          className={css`
            grid-area: title;
          `}
        >
          <h2
            className={css`
              font-size: 18px;
              font-weight: 400;

              @media screen and (max-width: 1100px) {
                grid-column: 1 / -1;
              }
            `}
          >
            {post.title}
          </h2>
          <div
            className={css`
              color: ${theme.colors.base.text.tertiary};
              font-size: 12px;
              margin-top: 4px;
            `}
          >
            Lastet opp av {post.user.name}
          </div>
        </div>
        <div
          className={css`
            grid-area: tagsAndName;
            @media screen and (max-width: 1100px) {
              grid-column: 1 / -1;
            }
          `}
        >
          <TagsAsBobbles tags={post.tags} maxTags={6} />
        </div>
        <div
          className={css`
            font-weight: 100;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 24px;
            grid-area: likesDownloadsAndMenu;

            @media screen and (max-width: 1100px) {
              grid-template-columns: repeat(3, auto);
              justify-self: end;
              grid-gap: 0;
            }
          `}
        >
          <div
            className={css`
              display: grid;
              grid-template-columns: 1fr 1fr;
            `}
          >
            <Apple filled size={20} />
            <span
              className={css`
                margin-left: 8px;
                margin-right: 8px;
                padding-top: 2px;
              `}
            >
              {post.likes || 0}
            </span>
          </div>
          <div
            className={css`
              display: grid;
              grid-template-columns: 1fr 1fr;
            `}
          >
            <ArrowCorner
              direction="DOWN"
              color={theme.colors.base.colors.pink.primary}
              size={20}
            />
            <span
              className={css`
                margin-left: 8px;
                padding-top: 2px;
              `}
            >
              {0}
            </span>
          </div>
          <div
            className={css`
              @media screen and (min-width: 1100px) {
                display: none;
              }
            `}
          >
            <AdminPostMenu
              post={post}
              flaggedData={flaggedData}
              handleDeleteAllFlags={handleDeleteAllFlags}
              handleDeletePost={handleDeletePost}
            />
          </div>
        </div>
        <div
          className={css`
            display: flex;
            justify-content: center;
            grid-area: share;

            @media screen and (max-width: 1100px) {
              justify-content: flex-end;
              column-span: 2;
            }
          `}
        >
          <CopyDataButton data={`${window.location.origin}/p/${post.id}`}>
            <LinkIcon color={theme.colors2.cta.tertiary} />
          </CopyDataButton>
        </div>
        <div
          className={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media screen and (max-width: 1100px) {
              display: none;
              grid-area: menu;
            }
          `}
        >
          <AdminPostMenu
            post={post}
            flaggedData={flaggedData}
            handleDeleteAllFlags={handleDeleteAllFlags}
            handleDeletePost={handleDeletePost}
          />
        </div>
        {flaggedData && (
          <button
            className={cx(
              css`
                position: absolute;
                left: -40px;
                bottom: 0px;
                height: 40px;
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${theme.colors2.base.secondary};
                box-shadow: ${theme.shadows.medium};
                border-radius: 20px;
                transition: all 200ms;

                @media screen and (max-width: 700px) {
                  bottom: -4px;
                  left: ${measure.width / 2 - 40}px;
                }
              `,
              expanded &&
                css`
                  @media screen and (min-width: 701px) {
                    width: 40px;
                    left: -20px;
                    bottom: -60px;
                  }
                  @media screen and (max-width: 700px) {
                    width: 40px;
                    left: ${measure.width / 2 - 20}px;
                    bottom: -20px;
                  }
                `
            )}
          >
            {expanded ? (
              <Cross />
            ) : (
              <>
                <img
                  src={messageImg}
                  alt=""
                  className={css`
                    margin-right: 10px;
                  `}
                />
                {flaggedData.length}
              </>
            )}
          </button>
        )}
      </div>
      {expanded && flaggedData && (
        <div
          className={css`
            width: calc(100% - 8px);
            margin-left: 8px;
            background-color: #f0efee;
            min-height: 80px;
            margin-bottom: 8px;

            div {
              img {
                opacity: 0.1;
              }
              display: flex;
              justify-content: space-between;
              padding: 15px 20px;
              &:hover {
                background-color: #d3d3d3;

                img {
                  opacity: 1;
                }
              }
            }
          `}
        >
          {flaggedData.map((reason) => (
            <div>
              <span>
                <p
                  className={css`
                    opacity: 0.8;
                    font-size: 12px;
                    margin-bottom: 8px;
                  `}
                >
                  {format(new Date(reason.date), "dd.MM.yyyy HH:MM")}
                </p>
                <p>{reason.message}</p>
              </span>
              <button onClick={() => handleDeleteFlags(reason.id)}>
                Godkjenn
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AdminPostMenu: FC<{
  post: PostData;
  flaggedData?: ReasonType[];
  handleDeleteAllFlags: () => void;
  handleDeletePost: () => void;
}> = ({ post, flaggedData, handleDeleteAllFlags, handleDeletePost }) => (
  <PostMenu
    className={css`
      margin-left: 14px;
      white-space: nowrap;
    `}
    flagButton={!flaggedData}
    postId={post.id}
  >
    <Link to={`/admin/${post.id}/edit`}>
      Rediger <img src={pencilImg} alt="" />
    </Link>
    {flaggedData && (
      <button onClick={handleDeleteAllFlags}>
        Merk alle som ok <img src={thumbsUpImg} alt="" />
      </button>
    )}
    <button onClick={handleDeletePost}>
      Slett bidrag <img src={trashImg} alt="" />
    </button>
  </PostMenu>
);
