import { css, cx } from "emotion";
import React, { FC } from "react";
import { PostData } from "../../pages/home/Home";
import theme from "../../utils/theme";
import { TextLink } from "../Buttons";
import { CopyDataButton } from "../CopyDataButton";
import { Apple } from "../icons/Apple";
import { ArrowCorner } from "../icons/Arrow";
import bookImg from "../icons/book.svg";
import { Bookmark } from "../icons/Bookmark";
import { LinkIcon } from "../icons/LinkIcon";
import { MultiDownloadButton } from "../MultiDownloadButton";
import { PostMenu } from "../PostMenu";
import { TagsAsBobbles } from "../Tags";
import { GradeTag } from "./components/GradeTag";

export const VerticalCard: FC<{
  post: PostData;
  isLoggedIn: boolean;
  disableOnClick?: boolean;
  handleOpenModalClick: () => void;
  handleLikeClick: () => void;
  handleBookmarkedClick: () => void;
}> = ({
  post,
  isLoggedIn,
  disableOnClick,
  handleOpenModalClick,
  handleLikeClick,
  handleBookmarkedClick,
}) => (
  <div
    className={cx(
      css`
        width: 250px;
        height: 400px;
        background-color: ${theme.colors.base.background.primary};
        padding: 20px 24px;
        display: flex;
        border-radius: 2px;
        flex-direction: column;
        justify-content: space-between;
        cursor: initial;
      `,
      !disableOnClick &&
        css`
          cursor: pointer;
          &:hover {
            box-shadow: ${theme.shadows.small};
          }
        `,
      post.user.special &&
        css`
          border: 1.5px solid #14376e;
        `
    )}
    onClick={handleOpenModalClick}
  >
    <div
      className={css`
        display: flex;
        justify-content: space-between;
      `}
      onClick={(e) => e.stopPropagation()}
    >
      <GradeTag tags={post.tags} />
      <PostMenu flagButton postId={post.id}>
        <MultiDownloadButton
          className={css`
            display: flex;
            align-items: center;
            svg {
              margin-left: 14px;
            }
          `}
          files={post.files}
        >
          Last ned{" "}
          <ArrowCorner
            direction="DOWN"
            size={20}
            color={theme.colors2.cta.tertiary}
          />
        </MultiDownloadButton>
      </PostMenu>
    </div>
    <h2
      className={css`
        hyphens: none;
        margin-bottom: 13px;
        font-weight: 400;
        flex-shrink: 0;
        text-overflow: ellipsis;
        overflow-y: hidden;
        font-size: 20px;
        margin-top: 15px;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      `}
    >
      {post.title}
    </h2>
    <div
      className={css`
        flex: 1;
      `}
    >
      <TagsAsBobbles tags={post.tags} maxTags={3} />
    </div>
    <div
      className={css`
        font-weight: 100;
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-shrink: 0;
        padding: 8px 0;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          svg {
            margin-right: 10px;
          }
        `}
      >
        <ArrowCorner
          direction="DOWN"
          color={theme.colors.base.colors.pink.primary}
          size={16}
        />{" "}
        {post.downloads}
      </div>
    </div>
    {post.user.special ? (
      <TextLink
        to="/"
        className={css`
          display: flex;
          align-items: center;
          color: ${theme.colors2.cta.tertiary};
          padding: 16px 0 30px 0;

          img {
            margin-right: 8px;
            width: 18px;
          }
        `}
      >
        <img src={bookImg} alt="" />
        {post.user.special}
      </TextLink>
    ) : (
      <div
        className={css`
          color: ${theme.colors.base.text.tertiary};
          padding: 16px 0 30px 0;
          font-size: 14px;
        `}
      >
        Lastet opp av {post.user.name}
      </div>
    )}
    <div
      className={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleLikeClick();
        }}
        className={cx(
          css`
            font-size: 14px;
            font-weight: 300;

            p {
              padding-top: 3px;
            }
            &:hover {
              path {
                opacity: 0.5;
              }
              path:first-of-type {
                fill: ${theme.colors.base.colors.pink.primary};
                stroke: none;
              }
            }
          `,
          !isLoggedIn &&
            css`
              path {
                opacity: 0.5;
              }
            `
        )}
      >
        <Apple
          filled={post.liked}
          strokeColor={theme.colors2.cta.tertiary}
          className={css`
            margin-right: 8px;
          `}
        />
        <p>{post.likes || 0}</p>
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleBookmarkedClick();
        }}
        className={cx(
          css`
            &:hover {
              svg {
                fill: ${theme.colors.base.background.secondary};
                opacity: 0.5;
              }
            }
          `,
          !isLoggedIn &&
            css`
              svg {
                opacity: 0.5;
              }
            `
        )}
      >
        <Bookmark solid={post.bookmarked} color={theme.colors2.cta.tertiary} />
      </button>
      <CopyDataButton data={`${window.location.origin}/p/${post.id}`}>
        <LinkIcon alt="del" color={theme.colors2.cta.tertiary} />
      </CopyDataButton>
    </div>
  </div>
);
