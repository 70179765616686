import { useLocation } from "@reach/router";
import { css, cx, keyframes } from "emotion";
import React, { FC, useEffect, useState } from "react";
import { Arrow } from "../../../components/icons/Arrow";
import { MagnifyingGlass } from "../../../components/icons/MagnifyingGlass";
import theme from "../../../utils/theme";
import { State } from "../../../utils/useDataApi";
import { PostData } from "../Home";
import { SearchInput } from "./SearchInput";
import { SearchResults } from "./SearchResults";
import {debounce} from "debounce";

export const Search: FC<{
  postsResult: State<{ posts: PostData[] }, unknown>;
  scrollToContentClick: () => void;
  setSearchIsActive: (isActive: boolean) => void;
}> = ({ postsResult, scrollToContentClick, setSearchIsActive }) => {
  const [value, setValue] = useState<string>("");
  const debouncedSetValue = debounce(setValue, 600);

  const location = useLocation();
  const state = location.state as { searchContext?: string };

  useEffect(() => {
    if (!value && state?.searchContext) {
      setValue(state?.searchContext)
      window.history.pushState({searchContext: null}, "")
    }
  }, [value, state]);

  useEffect(() => {
    setSearchIsActive(Boolean(value));
  }, [value, setSearchIsActive]);

  return (
    <div
      className={css`
        min-height: calc(100vh - 180px);
        background-color: ${theme.colors.search.primary};
        display: flex;
        align-items: center;
        flex-direction: column;

        @media screen and (max-height: 600px) {
          min-height: 600px;
        }
      `}
    >
      <div
        className={cx(
          css`
            height: 25vh;
            width: 100px;
            max-height: 250px;
            transition: height 50ms ease-in-out;

            @media screen and (max-width: 800px) {
              margin-top: 80px;
              height: 0;
            }
          `,
          value &&
            css`
              margin-top: 50px;
              height: 0;
            `
        )}
      />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 1330px;
          animation: ${keyframes`from { opacity: 0; } to { opacity: 1; }`} 300ms
            ease-in-out;

          @media screen and (max-width: 1200px) {
            width: 90vw;
          }
        `}
      >
        <div
          className={css`
            margin: auto;
            width: 1000px;

            @media screen and (max-width: 1200px) {
              width: 90vw;
            }
          `}
        >
          <MagnifyingGlass
            size={50}
            color={theme.colors.search.secondary}
            style={{ marginBottom: 20 }}
          />
          <div
            className={css`
              display: flex;
              align-items: center;

              input {
                padding: 16px;
              }
            `}
          >
            <SearchInput onChange={debouncedSetValue} id="search" />
          </div>
          <label
            htmlFor="search"
            className={css`
              padding-top: 10px;
              display: block;
              opacity: 0.8;
            `}
          >
            Søk etter undervisningsmateriell
          </label>
        </div>
      </div>

      {value && postsResult.isLoading && <div>Laster...</div>}
      {value && postsResult.data && (
        <SearchResults value={value} posts={postsResult.data.posts} />
      )}
      <div
        className={css`
          flex: 1;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          width: 1000px;
          margin: auto;

          @media screen and (max-width: 1200px) {
            width: 90vw;
          }
        `}
      >
        <button
          onClick={() => scrollToContentClick()}
          className={cx(
            css`
              margin-top: 15vh;
              margin-bottom: 30px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              @media screen and (max-height: 1000px) {
                margin-top: 15px;
              }
            `,
            value &&
              css`
                display: none;
              `
          )}
        >
          <h2>Bla igjennom alt</h2>
          <h2>av innhold</h2>
          <span style={{ height: 35 }} />
          <Arrow direction="DOWN" />
        </button>
      </div>
    </div>
  );
};
