import { css, cx } from "emotion";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tag, tagWrapperStyle } from "../../components/Tags";
import { useOutsideClick } from "../../utils/hooks";
import theme from "../../utils/theme";
import { useDataApi } from "../../utils/useDataApi";
import { useSearch } from "../../utils/useSearch";

export const TagSearch: FC<{
  selectedTags: string[];
  inputClassName?: string;
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
}> = ({ selectedTags, inputClassName, addTag, removeTag }) => {
  const [tagsResult] = useDataApi<{ tags: string[] }>("/api/tag");
  const [value, setValue] = useState<string>("");
  const [showTagSearchBox, setShowTagSearchBox] = useState(false);
  const tagSearchBoxRef = useRef<HTMLDivElement>(null);

  const tags = useMemo(() => tagsResult.data?.tags ?? [], [tagsResult]);

  const result = useSearch<string>(value, tags);

  useOutsideClick(tagSearchBoxRef, () => setShowTagSearchBox(false));

  useEffect(() => {
    setShowTagSearchBox(Boolean(value));
  }, [value]);

  const handleAddTag = useCallback(
    (tag?: string) => {
      addTag(tag || value);
      setValue("");
    },
    [value, addTag]
  );

  return (
    <div
      className={css`
        margin-bottom: 24px;
        position: relative;
      `}
    >
      <input
        value={value}
        onChange={(event) => setValue(event.target.value)}
        placeholder="Legg til emneknagger"
        className={cx(
          css`
            padding: 15px;
          `,
          inputClassName
        )}
        id="tagSearch"
        onClick={() => {
          value && !showTagSearchBox && setShowTagSearchBox(true);
        }}
      />
      <label htmlFor="tagSearch" style={{ display: "none" }}>
        Legg til emneknagger
      </label>
      {showTagSearchBox && (
        <div
          ref={tagSearchBoxRef}
          className={cx(
            css`
              position: absolute;
              top: 50px;
              left: 0;
              background-color: white;
              width: 100%;
              box-shadow: ${theme.shadows.medium};
              transition: transform 0.3s ease-out;
              height: auto;
              transform-origin: top;
              z-index: 1;
            `,
            showTagSearchBox
              ? css`
                  transform: translateY(0);
                `
              : css`
                  transform: translateY(-100%);
                `
          )}
        >
          {tagsResult.isLoading && <span>Loading...</span>}
          <div
            className={css`
              border-bottom: 0.5px solid ${theme.colors.upload.secondary};
              margin-bottom: 15px;
              margin-left: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            {value}
            <button
              className={css`
                color: ${theme.colors.base.text.secondary};
                background-color: ${theme.colors.base.background.secondary};
                height: 50px;
                padding: 0 16px;
              `}
              onClick={() => handleAddTag()}
            >
              Legg til som ny
            </button>
          </div>
          <div
            className={cx(
              css(tagWrapperStyle),
              css`
                padding: 16px;
              `
            )}
          >
            {result.map((tag) => (
              <Tag
                tag={tag}
                isSelected={selectedTags.includes(tag)}
                addTag={(tag) => handleAddTag(tag)}
                removeTag={removeTag}
                key={tag}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
