import { RouteComponentProps } from "@reach/router";
import { css, cx } from "emotion";
import React, { FC, useEffect, useState } from "react";

export const AboutUs: FC<RouteComponentProps> = () => {
  const [fade, setFace] = useState(false);

  const handleScroll = () => {
    const pos = window.pageYOffset;

    if (pos <= 100) {
      setFace(false);
    }
    if (pos > 100) {
      setFace(true);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={css`
        max-width: 1330px;
        margin: 0 auto;
        margin-top: 180px;
        display: flex;

        @media screen and (max-width: 1500px) {
          width: 90vw;
        }

        @media screen and (max-width: 1100px) {
          flex-direction: column;
        }

        p {
          margin-bottom: 30px;
        }
      `}
    >
      <div
        className={css`
          margin-right: 140px;
          min-width: 400px;
          max-width: 500px;

          * {
            hyphens: none;
          }

          @media screen and (max-width: 400px) {
            min-width: 90vw;
            max-width: 90vw;
          }
        `}
      >
        <div
          className={cx(
            css`
              position: sticky;
              top: 190px;
              opacity: 1;
              transition: opacity 200ms ease-in-out;
            `,
            fade &&
              css`
                opacity: 0.5;
              `
          )}
        >
          <h1
            className={css`
              margin-bottom: 40px;
              font-size: 40px;
            `}
          >
            Om Addito
          </h1>
          <p>
            Addito administreres av en gruppe lærere og pedagoger, som brenner
            for å skape gode læringsopplevelser for elever. Sammen har vi flere
            tiår med erfaring innen lærerfaget, både innen grunnskolen og
            videregående skole
          </p>
        </div>
      </div>
      <div
        className={css`
          margin-top: 175px;

          * {
            hyphens: none;
          }

          h1 {
            margin-bottom: 30px;
          }

          @media screen and (max-width: 1100px) {
            margin-top: 40px;
            margin-left: 20vw;
          }

          @media screen and (max-width: 768px) {
            margin-top: 40px;
            margin-left: 0;
          }

          @media screen and (max-width: 490px) {
            h1 {
              font-size: 24px;
            }
          }
        `}
      >
        <div
          className={css`
            background-color: #feefa4;
            padding: 60px 20px;
            margin-bottom: 30px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;

              svg {
                margin-right: 110px;

                @media screen and (max-width: 650px) {
                  margin-right: 0px;
                }
              }
              @media screen and (max-width: 490px) {
                flex-direction: column;
              }
            `}
          >
            <h1
              className={css`
                width: 330px;
              `}
            >
              Vi er lærere
            </h1>
            <svg
              width="103"
              height="53"
              viewBox="0 0 103 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M93 30.3578L90.3258 30.6594C84.6705 31.2973 77.1631 31.7506 68.6138 31.9242C60.0493 32.0981 51.7312 31.9668 44.6939 31.5912L42 31.4474L42.353 27.0574L45.0469 27.2012C51.9039 27.5672 60.0583 27.6973 68.4792 27.5262C76.8976 27.3553 84.1913 26.9101 89.5853 26.3017L92.2596 26L93 30.3578Z"
                fill="#FE9051"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M51 38.8227L72.4857 38.8227C72.4858 38.8227 72.486 38.8227 72.4861 38.8227C85.4129 38.8204 92.2063 35.9142 97.5152 31.654L99.5762 30L103 33.9827L100.939 35.6367C94.4998 40.804 86.4259 43.9976 72.4866 44L51 44L51 38.8227Z"
                fill="#FE9051"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M52 8.82267L30.5143 8.82267C30.5142 8.82267 30.514 8.82267 30.5139 8.82267C17.5871 8.82042 10.7937 5.91424 5.48483 1.65398L3.42376 1.28702e-06L-3.44687e-06 3.98273L2.06107 5.63671C8.5002 10.804 16.5741 13.9976 30.5134 14L52 14L52 8.82267Z"
                fill="#FE9051"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M47 48H99V53H47V48Z"
                fill="#FE9051"
              />
            </svg>
          </div>
          <p>Vi er lærere.</p>
          <p>Vi ble ikke lærere for å oppnå rikdom og berømmelse.</p>
          <p>
            Vi ble lærere for å løfte barn og ungdom inn i kompetanse og
            kreativitet.
          </p>
          <p>
            Vi ble lærere for å vise disse barna og ungdommene hvordan samfunnet
            og verden fungerer, hjelpe de å finne sin plass i dette felleskapet,
            og bli gode medlemmer av dette samfunnet og denne verden.
          </p>
          <p>
            Men noe gikk galt på veien. Rammene har endret seg underveis.
            Målstyring og effektivitetskrav har tatt over for mye av skolen.
          </p>
          <p>
            Produksjonskrav innen undervisning overskygger relevans og
            individuell tilpasning.
          </p>
          <p>
            I et samfunn i stadig raskere utvikling makter ikke skolen å holde
            følge. Utdaterte læremidler og mangelfulle verktøy, kjøpt inn
            gjennom kjempeanbud, gjør ikke den jobben de skal.
          </p>
          <p>
            Under korona-krisen ble dette tydeligere enn noen sinne. Læreren
            stod på bar bakke med digitale løsninger og læremidler som ikke
            strakk til. Noe måtte gjøres.
          </p>
          <p>
            I løpet av få dager hadde tusenvis av lærere samlet seg i grupper på
            sosiale medier for å hjelpe hverandre. De delte erfaringer og gode
            undervisningsopplegg. Hjalp hverandre i gang med videoundervisning
            og digitale verktøy.
          </p>
          <p>I løpet av en uke var vi 65 000 lærere.</p>
          <p>Dette er grunnen til at vi har startet Addito.</p>
          <p>
            For å ta vare på magien fra disse dagene da tusenvis av lærere fant
            nye måter og jobbe på, gjennom samarbeid og deling. For å lage en
            felles plattform hvor lærere kan lage og dele oppdaterte og
            relevante læremidler og undervisningsopplegg de selv har laget.
          </p>
          <p>
            Vi bygger en plattform for fremtidens skole. For den skolen vi vet
            elevene, Norge og verden trenger.
          </p>
          <h1
            className={css`
              margin-bottom: 20px;
              font-size: 30px;
            `}
          >
            Folka
          </h1>
          <p>
            <b className={css`
                font-weight: 500;
              `}>Kjetil:</b> Lærer på videregående, mange ideer, liker å dele både de 
            gode og de dårlige med kolleger som aldri har bedt om dem.
            <br/>
            <a className={css`
                font-weight: 500;
              `} 
              href="mailto:kjetil@addito.no">kjetil@addito.no
            </a>
          </p>
          <p>
            <b className={css`
              font-weight: 500;
            `}>Linda:</b> Lærer på videregående, raus, engasjert, tålmodig. Kreativ, 
                tusenkunstner, og det hun ikke har prøvd før - det får hun til og hvis 
                hun ikke får det til, så jobber hun til hun gjør det.
            <br/>
            <a className={css`
                  font-weight: 500;
                `} 
                href="linda@addito.no">
                  linda@addito.no
            </a>
          </p>
          <p>
          <b className={css`
              font-weight: 500;
            `}>Stine:</b> Lærer i grunnskolen, spesialpedagog og idrettsstudent, 
                engasjert og grenseløs i å finne muligheter. Jobber med hjertet på 
                utsiden av kroppen, hisser seg raskt opp, men det er også kort vei 
                til indre fryd og glede.
          <br/>
            <a className={css`
                  font-weight: 500;
                `} 
                href="stine@addito.no">
                  stine@addito.no
            </a>
          </p>
          <p>
            <b className={css`
                font-weight: 500;
              `}>Toril:</b> Organisatoren, roen alle trenger, med øyne for det gode 
              system. Som ser mulighet i kaos og finner kunnskap i hver krik og krok av livet.
            <br/>
            <a className={css`
                  font-weight: 500;
                `} 
                href="toril@addito.no">
                  toril@addito.no
            </a>
          </p>
          <p>
            <b className={css`
                font-weight: 500;
              `}>Ida:</b> Er vateret, som sorterer påfunn, ideer og ville drømmer, finner 
              løsningene slik at de kan bli virkelighet. Hun holder oss samlet og tar i mot 
              det som kommer. Gjør gråstein til gull, og snur ved enkle grep, andres frustrasjon 
              til mesterverk.
            <br/>
            <a className={css`
                  font-weight: 500;
                `} 
                href="ida@addito.no">
                  ida@addito.no
            </a>
          </p>
          <p>
            <b className={css`
                font-weight: 500;
              `}>Fredrik:</b> Bakgrunn fra Fin-tech og lidenskapelig opptatt av å gi norsk skole 
              teknologien den fortjener. Han viker ikke fra prinsipper som brukervennlighet og enkle 
              løsninger som FAKTISK løser problemer.
            <br/>
            <a className={css`
                  font-weight: 500;
                `} 
                href="fredrik@addito.no">
                  fredrik@addito.no
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
