import { css, cx } from "emotion";
import React, { FC } from "react";
import { Alert } from "../../../components/Alert";
import { Cross } from "../../../components/icons/Cross";
import { LinkIcon } from "../../../components/icons/LinkIcon";
import trashImg from "../../../components/icons/trash.svg";
import warningImg from "../../../components/icons/warning.svg";
import { humanFileSize } from "../../../utils";
import theme from "../../../utils/theme";
import { PostFile } from "../../home/Home";
import { UploadedFile } from "../Upload";
import fileImg from "./file.svg";

export const FileItem: FC<{
  index: number;
  file: File | UploadedFile | PostFile;
  cannotDeleteFiles?: boolean;
  deleteIcon?: "CROSS" | "TRASH";
  deleteFile?: (index: number, type: "UPLOADED" | "CHOSEN") => void;
}> = ({ index, file, deleteIcon, cannotDeleteFiles, deleteFile }) => {
  const isLoading = "loading" in file && file.loading;
  const error = "error" in file && file.error;
  const name = "filename" in file ? file.filename : file.name;

  return (
    <li
      className={css`
        display: flex;
        flex-direction: column;
        padding: 8px 24px;

        &:first-child {
          padding-top: 16px;
        }
        &:last-child {
          padding-bottom: 16px;
        }

        @media screen and (max-width: 990px) {
          max-width: calc(90vw);
        }
      `}
    >
      <span
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <span
          className={css`
            display: flex;
            align-items: center;
            flex: 1;
          `}
        >
          <span
            className={css`
              width: 30px;
            `}
          >
            <img
              src={fileImg}
              alt=""
              className={css`
                height: 20px;
              `}
            />
          </span>
          <span
            className={css`
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 20px;
              flex: 1;

              @media screen and (max-width: 1000px) {
                max-width: calc(90vw - 100px);
              }
            `}
            title={name}
          >
            {name}
          </span>
        </span>
        <span
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          {file instanceof File && (
            <span style={{ marginRight: 10 }}>{humanFileSize(file.size)}</span>
          )}
          {file && (
            <button
              onClick={() => {
                deleteFile &&
                  deleteFile(
                    index,
                    file instanceof File ? "CHOSEN" : "UPLOADED"
                  );
              }}
              disabled={isLoading || cannotDeleteFiles}
              className={cx(
                css`
                  border: none;
                  background-color: transparent;
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                  position: relative;
                `,
                "loading" in file &&
                  file.loading &&
                  css`
                    cursor: not-allowed;
                  `
              )}
              title={isLoading ? "Uploading file" : "Delete file"}
            >
              {error && (
                <img src={warningImg} alt="error" style={{ width: 16 }} />
              )}
              {deleteIcon === "TRASH" && !isLoading && (
                <img src={trashImg} alt="slett" />
              )}
              {deleteIcon === "TRASH" && isLoading && <Cross spin />}
              {(!deleteIcon || deleteIcon === "CROSS") && (
                <Cross spin={isLoading} />
              )}
            </button>
          )}
        </span>
      </span>
      {error && (
        <Alert level="ERROR" marginTop={8}>
          {error}
        </Alert>
      )}
    </li>
  );
};

export const LinkItem: FC<{
  index: number;
  link: string;
  cannotRemoveLink?: boolean;
  deleteIcon?: "CROSS" | "TRASH";
  removeLink?: (index: number) => void;
}> = ({ index, link, deleteIcon, cannotRemoveLink, removeLink }) => (
  <li
    className={css`
      display: flex;
      flex-direction: column;
      padding: 8px 24px;

      &:first-child {
        padding-top: 16px;
      }
      &:last-child {
        padding-bottom: 16px;
      }

      @media screen and (max-width: 990px) {
        max-width: calc(90vw);
      }
    `}
  >
    <span
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <span
        className={css`
          display: flex;
          align-items: center;
          flex: 1;
        `}
      >
        <span
          className={css`
            width: 30px;
          `}
        >
          <LinkIcon color={theme.colors2.cta.primary} />
        </span>
        <span
          className={css`
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 20px;
            flex: 1;

            @media screen and (max-width: 1000px) {
              max-width: calc(90vw - 100px);
            }
          `}
          title={link}
        >
          {link}
        </span>
      </span>
      <span
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        {link && (
          <button
            onClick={() => removeLink && removeLink(index)}
            disabled={cannotRemoveLink}
            className={css`
              border: none;
              background-color: transparent;
              width: 16px;
              height: 16px;
              cursor: pointer;
              position: relative;
            `}
          >
            {deleteIcon === "TRASH" ? (
              <img src={trashImg} alt="slett" />
            ) : (
              <Cross />
            )}
          </button>
        )}
      </span>
    </span>
  </li>
);
