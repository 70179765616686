import { css } from "emotion";
import React, { FC } from "react";
import * as googleImg from "../../icons/google.png";
import theme from "../../../utils/theme"
import { LogInModal } from "./LogInModal";
import { SignUpModal } from "./SignUpModal";

export const LogInOrSignUp: FC<{
  showModal: "LOG_IN" | "SIGN_UP" | undefined;
  message?: string;
  setShowModal: (modal: "LOG_IN" | "SIGN_UP" | undefined) => void;
}> = ({ showModal, message, setShowModal }) => {
  if (showModal === "LOG_IN") {
    return (
      <LogInModal
        closeModal={() => setShowModal(undefined)}
        changeToSignUpModal={() => setShowModal("SIGN_UP")}
        message={message}
      />
    );
  } else if (showModal === "SIGN_UP") {
    return (
      <SignUpModal
        closeModal={() => setShowModal(undefined)}
        changeToLogInModal={() => setShowModal("LOG_IN")}
        message={message}
      />
    );
  } else {
    return null;
  }
};

export const GoogleLink: FC<{ link: string; title: string }> = ({
  link,
  title,
}) => (
  <a
    className={css`
      background-color: ${theme.colors.base.background.primary};
      border: 1px solid ${theme.colors.base.background.secondary};
      padding: 20px 24px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-left: 16px;
      }
    `}
    href={link}
  >
    {title}
    <img src={googleImg} alt="G" />
  </a>
);
