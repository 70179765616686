import { Link, navigate } from "@reach/router";
import { css, cx } from "emotion";
import React, { FC, useEffect, useRef, useState } from "react";
import { useUserContext } from "../lib/UserContext";
import { useOutsideClick } from "../utils/hooks";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../utils/localeStorage";
import theme from "../utils/theme";
import { Arrow } from "./icons/Arrow";
import logo from "./icons/logo.svg";
import logoMobil from "./icons/logoMobil.svg";
import logOut from "./icons/logOut.svg";
import { Button, TextLink } from "./Buttons";

const SHOW_COOKIE_ACCEPT = "SHOW_COOKIE_ACCEPT";

export const Header: FC = () => {
  const [size, setSize] = useState<"BIG" | "SMALL">("BIG");
  const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
  const [showAcceptCookie, setShowAcceptCookie] = useState(
    getFromLocalStorage(SHOW_COOKIE_ACCEPT, true)
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const { isLoggedIn, showLogInOrSignUp } = useUserContext();

  useOutsideClick(menuRef, () => setMenuExpanded(false));

  const handleAcceptCookieClick = () => {
    setShowAcceptCookie(false);
    saveToLocalStorage(SHOW_COOKIE_ACCEPT, false);
  };

  const handleScroll = () => {
    const pos = window.pageYOffset;

    if (pos === 0) {
      setSize("BIG");
    }
    if (pos > 0) {
      setSize("SMALL");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleEscPress = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setMenuExpanded(false);
    }
  };

  useEffect(() => {
    if (menuExpanded) {
      window.addEventListener("keyup", handleEscPress);
    }

    return () => {
      window.removeEventListener("keyup", handleEscPress);
    };
  }, [menuExpanded]);

  return (
    <>
      <header
        className={css`
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          max-width: 100%;
          z-index: 10;
          display: flex;
          justify-content: center;
          pointer-events: none;
        `}
        id="header"
        ref={menuRef}
      >
        <div
          className={cx(
            css`
              position: absolute;
              background-color: ${theme.colors.base.background.primary};
              top: 0;
              width: 100vw;
              max-width: 100%;
              overflow: hidden;
              transition: transform 0.3s ease-out;
              height: auto;
              transform-origin: top;
            `,
            menuExpanded
              ? css`
                  transform: translateY(0);
                  box-shadow: 0px -10px 200px rgba(0, 0, 0, 0.5);
                `
              : css`
                  transform: translateY(-100%);
                `
          )}
        >
          <div
            className={css`
              padding-top: 140px;
              width: 90vw;
              max-width: 1330px;
              margin: 0px auto;
              pointer-events: initial;
            `}
          >
            <ul
              className={css`
                display: flex;
                flex-direction: column;
                padding-bottom: 30px;

                svg {
                  margin-right: 14px;
                }

                li {
                  display: flex;
                  align-items: center;
                  height: 30px;
                  font-size: 18px;
                }
              `}
            >
              {isLoggedIn ? (
                <li>
                  <Link
                    to="/my-page"
                    onClick={() => {
                      setMenuExpanded(false);
                    }}
                  >
                    <Arrow size={14} />
                    Min Side
                  </Link>
                </li>
              ) : (
                <>
                  <li>
                    <button
                      onClick={() => {
                        setMenuExpanded(false);
                        showLogInOrSignUp("LOG_IN");
                      }}
                    >
                      <Arrow size={14} />
                      Logg inn
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setMenuExpanded(false);
                        showLogInOrSignUp("SIGN_UP");
                      }}
                    >
                      <Arrow size={14} />
                      Registrer deg
                    </button>
                  </li>
                </>
              )}
              <span style={{ height: 60 }} />
              <li>
                <button
                  onClick={() => {
                    setMenuExpanded(false);
                    navigate("/about-us");
                  }}
                >
                  <Arrow size={14} />
                  Om tjenesten
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setMenuExpanded(false);
                    navigate("/terms-of-use");
                  }}
                >
                  <Arrow size={14} />
                  Vilkår og rettningslinjer
                </button>
              </li>
              {isLoggedIn && (
                <>
                  <span style={{ height: 30 }} />
                  <li>
                    <a
                      href="/api/logout"
                      onClick={() => {
                        setMenuExpanded(false);
                      }}
                    >
                      <img
                        src={logOut}
                        style={{ height: 18, marginRight: 14 }}
                        alt=""
                      />
                      Logg ut
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div
          className={css`
            height: 100px;
            display: flex;
            align-items: flex-end;
            width: 95vw;
            max-width: 1600px;
            margin: 0 20px;
            transition: height 200ms ease-in-out;
          `}
        >
          <div
            className={cx(
              css`
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 0;
                transition: padding-bottom 200ms ease-in-out;
              `,
              size === "SMALL" &&
                css`
                  padding-bottom: 25px;
                `
            )}
          >
            <a
              href="/"
              onClick={() => {
                window.history.pushState({ searchContext: null }, "")
              }}
              className={css`
                pointer-events: initial;
              `}
            >
              <img
                src={logo}
                alt="Addito"
                className={css`
                  height: 32px;
                  transform: scale(${size === "BIG" ? 1 : 0.7});
                  transition: all 200ms ease-in-out;

                  @media screen and (max-width: 414px) {
                    display: none;
                  }
                `}
              />
              <img
                src={logoMobil}
                alt="Addito"
                className={css`
                  height: 32px;
                  transform: scale(${size === "BIG" ? 1 : 0.7});
                  transition: all 200ms ease-in-out;
                  @media screen and (min-width: 415px) {
                    display: none;
                  }
                `}
              />
            </a>

            <HamburgerButton
              menuExpanded={menuExpanded}
              onClick={() => setMenuExpanded((currentState) => !currentState)}
            />
          </div>
        </div>
      </header>
      {showAcceptCookie && (
        <div
          className={css`
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #ffefa4;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            pointer-events: initial;
            z-index: 10000;

            h1 {
              font-size: 40px;
              line-height: 60px;
              margin-bottom: 40px;
            }

            p {
              font-size: 32px;
              line-height: 48px;
              margin-bottom: 40px;
            }
          `}
        >
          <h1>Vi har cookies!</h1>
          <p>
            Vi bruker cookies for å forbedre brukeropplevelsen for deg. Det er
            alltid godt.
          </p>
          <div
            className={css`
              display: flex;
              align-items: center;

              button {
                margin-left: 40px;
              }
            `}
          >
            <TextLink to="/terms-of-use">Lær mer om cookies</TextLink>
            <Button onClick={handleAcceptCookieClick}>
              Aksepter alle cookies
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const HamburgerButton: FC<{ menuExpanded: boolean; onClick: () => void }> = ({
  menuExpanded,
  onClick,
}) => (
  <button
    className={css`
      border: none;
      cursor: pointer;
      width: 50px;
      height: 50px;
      z-index: 2;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      pointer-events: initial;
    `}
    title={menuExpanded ? "Close menu" : "open menu"}
    onClick={onClick}
  >
    <span
      className={css`
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
      `}
    >
      <span
        className={cx(
          css`
            display: block;
            position: absolute;
            top: 9px;
            right: 0;
            height: 3px;
            width: 20px;
            background-color: ${theme.colors.base.background.secondary};
            transition: all 200ms ease-out;
          `,
          menuExpanded
            ? css`
                transform: rotate(45deg);
                top: 14px;
                right: 5px;
              `
            : css`
                transform: rotate(0deg);
              `
        )}
      />
      <span
        className={cx(
          css`
            display: block;
            position: absolute;
            top: 18px;
            right: 0;
            height: 3px;
            width: 30px;
            background-color: ${theme.colors.base.background.secondary};
            margin-top: 0;
            transition: all 200ms ease-out;
          `,
          menuExpanded
            ? css`
                transform: rotate(-45deg);
                width: 20px;
                top: 14px;
                right: 5px;
              `
            : css`
                transform: rotate(0deg);
              `
        )}
      />
    </span>
    <span
      className={css`
        font-size: 12px;
      `}
    >
      MENY
    </span>
  </button>
);
