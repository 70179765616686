import { Link } from "@reach/router";
import { css, cx } from "emotion";
import React, { FC } from "react";
import { Arrow } from "./icons/Arrow";

export const Footer: FC = () => (
  <footer
    className={cx(
      css`
        width: 100vw;
        max-width: 100%;
        padding: 40px;
      `,
      "footer"
    )}
  >
    <div
      className={css`
        width: 90vw;
        max-width: 1330px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 750px) {
          justify-content: flex-start;
          flex-direction: column;
          max-width: 150px;
          margin: 0;
        }
      `}
    >
      <div
        className={css`
          @media screen and (max-width: 750px) {
            max-width: 150px;
            margin-bottom: 20px;
          }
        `}
      >
        © {new Date().getFullYear()} Addito
      </div>
      <div
        className={css`
          a:last-child {
            margin-left: 60px;
          }

          @media screen and (max-width: 750px) {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            max-width: 100px;

            a:last-child {
              margin-top: 10px;
              margin-left: 0;
            }
          }
        `}
      >
        <Link to="/terms-of-use">Personvern</Link>
        <a href="mailto:kontakt@addito.no">
          Kontakt <Arrow direction="RIGHT" size={16} />
        </a>
      </div>
    </div>
  </footer>
);
