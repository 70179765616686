import React, { FC } from "react";
import { BundledFlagPostType, FlaggedPostType } from "./Admin";
import { AdminCard } from "./AdminCard";

export const FlaggedPosts: FC<{
  flaggedPosts: FlaggedPostType[];
  filter: string;
  refetchFlaggedPosts: () => void;
}> = ({ flaggedPosts, filter, refetchFlaggedPosts }) => {
  const bundledFlagPosts: BundledFlagPostType[] = flaggedPosts.reduce(
    (acc: BundledFlagPostType[], cur: FlaggedPostType) => {
      if (acc.length === 0) {
        acc.push({
          post: cur.post,
          reasons: [{ id: cur.id, message: cur.reason, date: cur.created }],
          id: acc.length + 1,
        });
      } else {
        const flaggedPostIndex = acc.findIndex(
          (bundledPost) => bundledPost.post.id === cur.post.id
        );

        if (flaggedPostIndex !== -1) {
          acc[flaggedPostIndex] = {
            ...acc[flaggedPostIndex],
            reasons: [
              ...acc[flaggedPostIndex].reasons,
              { id: cur.id, message: cur.reason, date: cur.created },
            ],
          };
        } else {
          acc.push({
            post: cur.post,
            reasons: [{ id: cur.id, message: cur.reason, date: cur.created }],
            id: acc.length + 1,
          });
        }
      }

      return acc;
    },
    []
  );

  return (
    <div>
      {bundledFlagPosts
        .filter((post) =>
          post.post.title
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase())
        )
        .map((bundledFlagPost) => (
          <AdminCard
            post={bundledFlagPost.post}
            flaggedData={bundledFlagPost.reasons}
            refetchFlaggedPosts={refetchFlaggedPosts}
            key={bundledFlagPost.id}
          />
        ))}
    </div>
  );
};
