import React, { FC } from "react";
import theme from "../../utils/theme";

export const MagnifyingGlass: FC<{
  size?: number;
  color?: string;
  style?: any;
}> = ({ size, color, style }) => (
  <svg
    width={size || 60}
    height={size || 60}
    viewBox="0 0 60 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      ...style,
    }}
  >
    <g transform="matrix(1.304,0,0,1.304,-9.12001,-9.12001)">
      <path
        d="M27.5,47.5C38.546,47.5 47.5,38.546 47.5,27.5C47.5,16.454 38.546,7.5 27.5,7.5C16.454,7.5 7.5,16.454 7.5,27.5C7.5,38.546 16.454,47.5 27.5,47.5Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: color || theme.colors.base.background.secondary,
          strokeWidth: 1,
        }}
      />
    </g>
    <g transform="matrix(1.304,0,0,1.304,-9.12001,-9.12001)">
      <path
        d="M52.5,52.5L41.625,41.625"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: color || theme.colors.base.background.secondary,
          strokeWidth: 1,
        }}
      />
    </g>
  </svg>
);
