import {Link, RouteComponentProps} from "@reach/router";
import {css} from "emotion";
import React, {FC, useRef, useState} from "react";
import {ArrowCorner} from "../../components/icons/Arrow";
import {MagnifyingGlass} from "../../components/icons/MagnifyingGlass";
import {useChangeHeaderColor} from "../../utils/hooks";
import theme from "../../utils/theme";
import {useDataApi} from "../../utils/useDataApi";
import {Content} from "./content/Content";
import {Search} from "./Search/Search";

export const Home: FC<RouteComponentProps> = () => {
  const [searchIsActive, setSearchIsActive] = useState<boolean>(false);
  const [initialPostsResult, refetchInitialPosts] = useDataApi<{ posts: PostData[] }>(
    "/api/post?limit=150&offset=0"
  );
  const [allPostsResult, refetchAllPosts] = useDataApi<{ posts: PostData[] }>(
      "/api/post"
  );
  const [latestPostsResult, refetchLatestPosts] = useDataApi<{
    posts: PostData[];
  }>("/api/latest-posts?limit=5");

  const contentRef = useRef<HTMLDivElement>(null);
  useChangeHeaderColor("#e1f7f7");

  const handleScrollToContent = () => {
    if (contentRef.current) {
      window.scrollTo({
        top: contentRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const postsResult = allPostsResult.isLoading ? initialPostsResult : allPostsResult;

  return (
    <div>
      <div
        className={css`
          background-color: #e1f7f7;
        `}
      >
        <div
          className={css`
            width: 90vw;
            position: relative;
            height: ${searchIsActive ? 100 : 180}px;
            max-width: 1330px;
            margin: 0 auto;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 800px) {
              width: 100vw;
              max-width: 100%;
              padding: 0 10vw;
            }
          `}
        >
          <div
            className={css`
              position: absolute;
              right: 0;
              top: ${searchIsActive ? 110 : 140}px;
            `}
          >
            <SearchUploadButton
              mode="UPLOAD"
              to="/upload"
              size={searchIsActive ? "SMALL" : "BIG"}
            />
          </div>
        </div>
        <Search
          postsResult={postsResult}
          scrollToContentClick={handleScrollToContent}
          setSearchIsActive={setSearchIsActive}
        />
      </div>
      {!searchIsActive && (
        <div
          className={css`
            width: 100vw;
            max-width: 100%;
            background-color: ${theme.colors2.background.content};
          `}
        >
          <div
            ref={contentRef}
            className={css`
              width: 90vw;
              max-width: 1330px;
              margin: 0 auto;
              padding-bottom: 50px;
            `}
          >
            <Content
              latestPostsResult={latestPostsResult}
              postsResult={postsResult}
              refetchPosts={() => {
                refetchInitialPosts();
                refetchLatestPosts();
                refetchAllPosts();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export type Comment = {
  id: number;
  author: PostUser;
  text: string;
  children: Comment[];
  deleted: boolean;
  deletedByAdmin: boolean;
  likes: number;
  isLiked: boolean;
  createdTime: string;
  postId: number;
}

export type CommentFlag = {
  id: number;
  reporter: PostUser;
  reason: string;
  comment: Comment;
}

export type PostData = {
  bookmarked: boolean;
  bookmarks: number;
  created: string | null;
  description: string;
  downloads: number;
  files: PostFile[];
  id: number;
  liked: boolean;
  likes: number;
  links: string[];
  tags: string[];
  title: string;
  user: PostUser;
  comments: Comment[]
};

export type PostFile = {
  filename: string;
  id: number;
  mimetype: string;
  sha512hash: string;
};

export type PostUser = {
  id: number;
  name: string | null;
  special: string | null;
};

export const SearchUploadButton: FC<{
  mode: "SEARCH" | "UPLOAD";
  size: "BIG" | "SMALL";
  to: string;
}> = ({ mode, to, size }) => (
  <Link
    className={css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 120px;
      font-size: 15px;
      text-align: center;
    `}
    to={to}
  >
    <span
      className={css`
        width: ${size === "BIG" ? 90 : 60}px;
        height: ${size === "BIG" ? 90 : 60}px;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${mode === "SEARCH"
          ? theme.colors.search.secondary
          : theme.colors.upload.secondary};
        margin-bottom: 10px;

        &:hover {
          svg {
            transform: scale(1.1);
          }
        }
      `}
    >
      {mode === "SEARCH" ? (
        <MagnifyingGlass color={theme.colors.search.tertiary} size={30} />
      ) : (
        <ArrowCorner
          direction="UP"
          color={theme.colors.base.background.secondary}
          size={30}
        />
      )}
    </span>
    {mode === "SEARCH" ? "Søk etter opplegg" : "Last opp opplegg"}
  </Link>
);
