import { css, cx } from "emotion";
import React, { FC, useEffect } from "react";
import {
  allTags,
  grade,
  topic,
  Tag,
  tagWrapperStyle,
} from "../../components/Tags";
import { StepDescription } from "./components/StepDescription";
import { TagSearch } from "./TagSearch";
import { UploadedFile } from "./Upload";

export const Tagging: FC<{
  files: UploadedFile[];
  links: string[];
  stepIsValid: boolean;
  tags: string[];
  addTag: (tag: string) => void;
  handleDeleteFile: (index: number, type: "UPLOADED" | "CHOSEN") => void;
  handleRemoveLink: (index: number) => void;
  removeTag: (tag: string) => void;
  validateStep: (isValid: boolean) => void;
}> = ({
  addTag,
  files,
  handleDeleteFile,
  links,
  removeTag,
  stepIsValid,
  tags,
  validateStep,
  handleRemoveLink,
}) => {
  useEffect(() => {
    const isValid = tags.length > 0 && tags.some((tag) => (grade.includes(tag) || topic.includes(tag)));

    if (!stepIsValid && isValid) {
      validateStep(true);
    } else if (stepIsValid && !isValid) {
      validateStep(false);
    }
  }, [validateStep, stepIsValid, tags]);

  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 90vw;
        max-width: 1000px;

        h4 {
          margin-bottom: 24px;
        }
      `}
    >
      <StepDescription
        title="Hva slags type innhold er dette?"
        files={files}
        deleteFile={handleDeleteFile}
        links={links}
        removeLink={handleRemoveLink}
      />
      <TagSearch selectedTags={tags} addTag={addTag} removeTag={removeTag} />
      {tags.length > 0 && (
        <>
          <h4>Valgte emneknagger</h4>
          <div
            className={cx(
              tagWrapperStyle,
              css`
                position: relative;
              `
            )}
          >
            {tags.map((tag) => (
              <Tag
                key={tag}
                tag={tag}
                tags={tags}
                isSelected
                coreTagButton
                addTag={addTag}
                removeTag={removeTag}
              />
            ))}
          </div>
        </>
      )}
      <h4>Populære emneknagger</h4>
      <div className={css(tagWrapperStyle)}>
        {allTags
          .filter((tag) => !tags.includes(tag))
          .map((tag) => (
            <Tag
              tag={tag}
              isSelected={false}
              addTag={addTag}
              removeTag={removeTag}
              key={tag}
            />
          ))}
      </div>
    </div>
  );
};
