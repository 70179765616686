import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import { css, cx, keyframes } from "emotion";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Alert } from "../../components/Alert";
import { Arrow } from "../../components/icons/Arrow";
import { Hand } from "../../components/icons/Hand";
import { GoogleLink } from "../../components/modals/signUpInModal/LogInOrSignUp";
import { useUserContext } from "../../lib/UserContext";
import theme from "../../utils/theme";
import { SearchUploadButton } from "../home/Home";
import { Description } from "./components/Description";
import { FileAndLinkUpload } from "./FileAndLinkUpload";
import { Share } from "./Share";
import { Summary } from "./Summary";
import { Tagging } from "./Tagging";
import { useChangeHeaderColor } from "../../utils/hooks";

export const Upload: FC<RouteComponentProps> = () => {
  const [step, setStep] = useState<number>(0);
  const [formStarted, setFormStarted] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [chosenFiles, setChosenFiles] = useState<File[]>([]);
  const [links, setLinks] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [validSteps, setValidSteps] = useState<number[]>([]);
  const [postStatus, setPostStatus] = useState<PostStatusType>({
    loading: false,
    postId: undefined,
    error: undefined,
  });

  const { isLoggedIn } = useUserContext();
  useChangeHeaderColor("#fef3d7");

  const validateStep = useCallback(
    (step: number, valid: boolean) => {
      const stepIsAlreadyValidIndex = validSteps.findIndex(
        (validStep) => validStep === step
      );
      if (valid) {
        if (stepIsAlreadyValidIndex === -1) {
          setValidSteps((steps) => [...steps, step]);
        }
      } else {
        let validStepsCopy = [...validSteps];
        validStepsCopy.splice(stepIsAlreadyValidIndex, 1);
        setValidSteps(validStepsCopy);
      }
    },
    [validSteps, setValidSteps]
  );
  useEffect(() => {
    if (validSteps.includes(0) && files.length === 0 && links.length === 0) {
      validateStep(0, false);
    }
  }, [files, links, validSteps, validateStep]);

  const handleUpdateFiles = (f: UploadedFile, serverId?: string): string => {
    const id = f.id || `_tmp-${Math.floor(Math.random() * 10000)}`;

    setFiles((files) => {
      const existingFileIndex = files.findIndex((file) => file.id === f.id);
      if (existingFileIndex === -1) {
        return [
          ...files,
          {
            ...f,
            id: serverId || id,
          },
        ];
      } else {
        let filesCopy = [...files];
        filesCopy[existingFileIndex] = {
          ...f,
          id: serverId || id,
        };
        return filesCopy;
      }
    });

    return id;
  };

  const handleFileUpload = async () => {
    validateStep(0, true);
    if (!formStarted) {
      setFormStarted(true);
    }

    if (chosenFiles.length > 0) {
      chosenFiles.forEach(async (file) => {
        let data = new FormData();

        const tmpId = handleUpdateFiles({
          name: file.name,
          loading: true,
        });
        data.append("file", file);
        try {
          let res = await axios.post("/api/upload", data);

          if (res?.data?.file_id) {
            handleUpdateFiles(
              {
                id: tmpId,
                name: file.name,
                loading: false,
              },
              res.data.file_id
            );
          }
        } catch (e) {
          handleUpdateFiles({
            id: tmpId,
            name: file.name,
            loading: false,
            error: e.toString(),
          });
        }
      });

      setChosenFiles([]);
    }
  };

  const handleDeleteFile = (index: number, type: "UPLOADED" | "CHOSEN") => {
    if (type === "CHOSEN") {
      let chosenFilesCopy = [...chosenFiles];
      chosenFilesCopy.splice(index, 1);
      setChosenFiles(chosenFilesCopy);
    } else if (type === "UPLOADED") {
      const file = files[index];
      let uploadedFilesCopy = [...files];
      uploadedFilesCopy.splice(index, 1);
      setFiles(uploadedFilesCopy);
      try {
        axios.delete(`/api/upload/${file.id}`);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleRemoveLink = (index: number) => {
    let linksCopy = [...links];
    linksCopy.splice(index, 1);
    setLinks(linksCopy);
  };

  const handleAddLink = (link: string) => {
    validateStep(0, true);
    if (!formStarted) {
      setFormStarted(true);
    }

    const linkExists = Boolean(links.find((l) => l === link));
    if (linkExists) {
      alert("Du har allerede lagt til denne linken");
    } else {
      setLinks((currentLinks) => [...currentLinks, link]);
    }
  };

  const handleAddChosenFiles = (files: File[]) => {
    setChosenFiles((currentChosenFiles) => [...currentChosenFiles, ...files]);
  };

  const handleStepChange = (direction: "NEXT" | "PREVIOUS") => {
    let nextStep = direction === "NEXT" ? step + 1 : step - 1;
    if (nextStep < 0) {
      nextStep = 0;
    } else if (nextStep > 4) {
      nextStep = 4;
    }

    setStep(nextStep);
    if (!formStarted) {
      setFormStarted(true);
    }
  };

  const handlePublishPost = useCallback(async () => {
    setPostStatus({
      loading: true,
    });
    try {
      const postResult = await axios.post<{ post_id: number }>("/api/post", {
        title,
        tags,
        description,
        files: files.map((file) => file.id),
        links: links,
      });

      setPostStatus({
        loading: false,
        postId: postResult.data.post_id,
      });
      validateStep(3, true);
      setStep(4);
      setFormStarted(false);
    } catch (error) {
      setPostStatus({
        loading: false,
        error: error.toString(),
      });
    }
  }, [title, links, tags, description, files, validateStep]);

  const handleCancelClick = useCallback(() => {
    setChosenFiles([]);
    setLinks([]);
    setDescription("");
    setFiles([]);
    setFormStarted(false);
    setPostStatus({ loading: false, postId: undefined, error: undefined });
    setStep(0);
    setTags([]);
    setTitle("");
    setValidSteps([]);
  }, []);

  const handleAddTag = (tag: string) => {
    if (!tags.find((t) => t.toLocaleLowerCase() === tag.toLocaleLowerCase())) {
      setTags((tags) => [...tags, tag]);
    }
  };

  const handleRemoveTag = (tag: string) => {
    const tagIndex = tags.findIndex((t) => t === tag);
    if (tagIndex !== -1) {
      setTags((currentTags) => {
        let tagsCopy = [...currentTags];
        tagsCopy.splice(tagIndex, 1);
        return tagsCopy;
      });
    }
  };

  const uploadIsActive = formStarted || chosenFiles.length > 0;

  return (
    <div
      className={css`
        background-color: #fef3d7;
      `}
    >
      <div
        className={css`
          width: 90vw;
          position: relative;
          height: ${uploadIsActive ? 100 : 180}px;
          max-width: 1330px;
          margin: 0 auto;
          display: flex;
          justify-content: flex-end;

          @media screen and (max-width: 800px) {
            width: 100vw;
            max-width: 100%;
            padding: 0 10vw;
            background-color: ${theme.colors.upload.primary};
          }
        `}
      >
        <div
          className={css`
            position: absolute;
            right: 0;
            top: ${uploadIsActive ? 110 : 140}px;
          `}
        >
          <SearchUploadButton
            mode="SEARCH"
            to="/"
            size={uploadIsActive ? "SMALL" : "BIG"}
          />
        </div>
      </div>
      <div
        className={css`
          min-height: calc(100vh - 180px);
          background-color: ${theme.colors.upload.primary};
          display: flex;
          align-items: center;
          flex-direction: column;
        `}
      >
        <div
          className={css`
            animation: ${keyframes`from { opacity: 0; } to { opacity: 1; }`}
              300ms ease-in-out;
          `}
        >
          {isLoggedIn ? (
            (() => {
              switch (step) {
                case 0:
                  return (
                    <FileAndLinkUpload
                      chosenFiles={chosenFiles}
                      handleAddLink={handleAddLink}
                      handleDeleteFile={handleDeleteFile}
                      handleFileUpload={handleFileUpload}
                      handleRemoveLink={handleRemoveLink}
                      handleAddChosenFiles={handleAddChosenFiles}
                      links={links}
                      stepIsValid={validSteps.includes(0)}
                      uploadedFiles={files}
                      uploadIsActive={uploadIsActive}
                      validateStep={(isValid: boolean) =>
                        validateStep(0, isValid)
                      }
                    />
                  );
                case 1:
                  return (
                    <Tagging
                      addTag={handleAddTag}
                      files={files}
                      handleDeleteFile={handleDeleteFile}
                      handleRemoveLink={handleRemoveLink}
                      links={links}
                      removeTag={handleRemoveTag}
                      stepIsValid={validSteps.includes(1)}
                      tags={tags}
                      validateStep={(isValid: boolean) =>
                        validateStep(1, isValid)
                      }
                    />
                  );
                case 2:
                  return (
                    <Description
                      description={description}
                      files={files}
                      handleDeleteFile={handleDeleteFile}
                      handleRemoveLink={handleRemoveLink}
                      links={links}
                      removeTag={handleRemoveTag}
                      setDescription={setDescription}
                      setTitle={setTitle}
                      stepIsValid={validSteps.includes(2)}
                      tags={tags}
                      title={title}
                      validateStep={(isValid: boolean) =>
                        validateStep(2, isValid)
                      }
                    />
                  );
                case 3:
                  return (
                    <Summary
                      description={description}
                      files={files}
                      handleDeleteFile={handleDeleteFile}
                      handleRemoveLink={handleRemoveLink}
                      handleUpload={handlePublishPost}
                      links={links}
                      postStatus={postStatus}
                      tags={tags}
                      title={title}
                      validSteps={validSteps}
                    />
                  );
                case 4:
                  if (!postStatus.postId) {
                    return (
                      <Alert level="ERROR">Something wrong happened</Alert>
                    );
                  }
                  return (
                    <Share
                      postId={postStatus.postId}
                      resetForm={handleCancelClick}
                    />
                  );
                default:
                  return <div>Ops, her gikk det noe galt</div>;
              }
            })()
          ) : (
            <div
              className={css`
                margin-top: 60px;
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
            >
              <h1
                className={css`
                  margin-top: 60px;
                  margin-bottom: 20px;
                `}
              >
                Hei! For å laste opp noe må du være logget inn
              </h1>
              <Hand color="#ffe566" />
              <div style={{ height: 60 }} />
              <GoogleLink link="/api/login" title="Logg inn med Google" />
              <p
                className={css`
                  margin-top: 40px;
                  color: ${theme.colors.base.colors.orange.primary};
                `}
              >
                Har du ingen bruker ennå?
              </p>
              <a
                href="/api/login"
                className={css`
                  margin-top: 8px;
                `}
              >
                Opprett bruker
              </a>
            </div>
          )}
        </div>
        {formStarted && (
          <UploadNavigation
            postStatus={postStatus}
            step={step}
            validSteps={validSteps}
            handleStepChange={handleStepChange}
            handleCancelClick={handleCancelClick}
          />
        )}
      </div>
    </div>
  );
};

export type UploadedFile = {
  name: string;
  id?: string;
  loading: boolean;
  error?: string;
};

export type PostStatusType = {
  loading: boolean;
  postId?: number;
  error?: string;
};

const UploadNavigation: FC<{
  handleStepChange: (direction: "NEXT" | "PREVIOUS") => void;
  handleCancelClick: () => void;
  step: number;
  validSteps: number[];
  postStatus: PostStatusType;
}> = ({
  handleStepChange,
  handleCancelClick,
  step,
  postStatus,
  validSteps,
}) => {
  const stepIsValid = validSteps.includes(step);
  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 0;
        width: 90vw;
        max-width: 1094px;

        @media screen and (max-width: 800px) {
          padding: 30px 0;
        }
      `}
    >
      {step !== 4 && (
        <div
          className={css`
            display: grid;
            grid-template-columns: auto 1fr auto 1fr auto;

            button {
              height: 60px;
              width: 100px;
              margin-bottom: 30px;

              &:first-child {
                justify-content: flex-start;
              }

              &:last-child {
                justify-content: flex-end;
              }

              &:hover {
                background-color: transparent;
                color: ${theme.colors2.cta.tertiary};
              }
            }
          `}
        >
          {step === 0 && <span style={{ width: 130 }} />}
          {step !== 0 && (
            <button
              disabled={Boolean(step > 3 || postStatus.postId)}
              onClick={() => handleStepChange("PREVIOUS")}
            >
              <Arrow direction="LEFT" size="16px" />
              <span
                className={css`
                  margin-left: 14px;
                `}
              >
                Tilbake
              </span>
            </button>
          )}
          <span />
          <button
            disabled={Boolean(step > 3 || postStatus.postId)}
            onClick={handleCancelClick}
          >
            Avbryt
          </button>
          <span />
          {step !== 3 ? (
            <button
              onClick={() => handleStepChange("NEXT")}
              disabled={!stepIsValid}
            >
              <span
                className={cx(
                  step < 3 &&
                    css`
                      margin-right: 14px;
                    `
                )}
              >
                Neste
              </span>
              <Arrow size="16px" style={!stepIsValid ? { opacity: 0.4 } : {}} />
            </button>
          ) : (
            <span style={{ width: 100 }} />
          )}
        </div>
      )}
      <Progress progress={(step + 1) * 25} />
    </div>
  );
};

const Progress: FC<{ progress: number }> = ({ progress }) => (
  <div
    className={css`
      height: 4px;
      border-radius: 2px;
      width: 100%;
      position: relative;
      background-color: ${theme.colors.base.background.primary};
    `}
  >
    <div
      className={css`
        position: absolute;
        left: 0;
        height: 4px;
        border-radius: 2px;
        width: ${progress}%;
        background-color: ${theme.colors.base.colors.orange.primary};
        transition: width 1s ease-in-out;
      `}
    />
  </div>
);
