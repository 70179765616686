import { Link } from "@reach/router";
import { css } from "emotion";
import React, { FC } from "react";
import theme from "../../../utils/theme";
import { Modal } from "../../modal/Modal";
import { GoogleLink } from "./LogInOrSignUp";

export const SignUpModal: FC<{
  message?: string;
  closeModal: () => void;
  changeToLogInModal: () => void;
}> = ({ message, closeModal, changeToLogInModal }) => {
  return (
    <Modal
      closeModal={closeModal}
      modalSize={{ width: "790px", height: "780px" }}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 100%;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <h1
            className={css`
              margin-top: 70px;
              margin-bottom: 30px;
            `}
          >
            {message || "Du er inne om et par strakser..."}
          </h1>
          <svg
            width="173"
            height="88"
            viewBox="0 0 173 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M157.046 49.2099L122.118 75.3045L124.871 79.0001L159.799 52.9055L157.046 49.2099Z"
              fill="#FFE566"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M156.11 28.7529L127.341 61.5406L130.795 64.5887L159.563 31.801L156.11 28.7529Z"
              fill="#FFE566"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M152.557 18.603L123.789 51.3907L127.242 54.4387L156.011 21.651L152.557 18.603Z"
              fill="#FFE566"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140.649 18.9595L111.88 51.7471L115.333 54.7952L144.102 22.0075L140.649 18.9595Z"
              fill="#FFE566"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.761 69.7234L101.215 51.3237C101.215 51.3236 101.214 51.3238 101.215 51.3237C102.087 40.2533 99.9765 34.2222 96.568 29.3635L95.2447 27.4772L98.9985 24.8372L100.322 26.7235C104.456 32.6166 106.734 39.765 105.793 51.7024L104.34 70.103L99.761 69.7234Z"
              fill="#FFE566"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.8564 48.8656L50.3404 75.5445L47.5257 79.1933L13.0418 52.5144L15.8564 48.8656Z"
              fill="#FFEFA4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.1374 28.4273L45.3499 61.6947L41.8457 64.6842L13.6332 31.4168L17.1374 28.4273Z"
              fill="#FFEFA4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.8602 18.3386L49.0726 51.606L45.5685 54.5954L17.356 21.3281L20.8602 18.3386Z"
              fill="#FFEFA4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M32.7611 18.8955L60.9736 52.1629L57.4694 55.1524L29.257 21.885L32.7611 18.8955Z"
              fill="#FFEFA4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M72.7884 70.3407L71.6449 51.9191C71.6449 51.9189 71.6449 51.9192 71.6449 51.9191C70.9588 40.8356 73.1706 34.8408 76.6605 30.0402L78.0153 28.1765L74.3065 25.4736L72.9516 27.3374C68.7188 33.1601 66.3206 40.2691 67.0604 52.2207L68.204 70.6431L72.7884 70.3407Z"
              fill="#FFEFA4"
            />
          </svg>

          <div
            className={css`
              margin-top: 80px;
            `}
          >
            <GoogleLink link="/api/login" title=" Registrer deg med Google" />
          </div>
          <p
            className={css`
              margin-top: 40px;
              color: ${theme.colors.base.colors.orange.primary};
            `}
          >
            Har du allerede en bruker?
          </p>
          <button
            className={css`
              margin-top: 8px;
            `}
            onClick={changeToLogInModal}
          >
            Logg inn
          </button>
        </div>
        <p
          className={css`
            margin-top: 100px;
          `}
        >
          Ved å logge inn, eller opprette en bruker samtykker du til våre{" "}
          <Link
            className={css`
              color: #2057d6;
            `}
            to="/"
          >
            Vilkår
          </Link>{" "}
          og vårt{" "}
          <Link
            className={css`
              color: #2057d6;
            `}
            to="/"
          >
            Personvern
          </Link>
        </p>
      </div>
    </Modal>
  );
};
