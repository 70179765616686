import React, { FC } from "react";

export const Hand: FC<{ color?: string }> = ({ color }) => (
  <svg
    width="67"
    height="64"
    viewBox="0 0 67 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.54154 0.427844C8.92677 -0.0701515 10.4571 0.637823 10.9726 2.01762L10.9952 2.07816C13.6508 9.18626 14.0723 17.0335 9.9944 28.7596L3.63139 47.0461C0.797258 46.0162 -0.683648 42.8979 0.307975 40.0481L4.88204 26.9028C8.6257 16.1376 8.06533 9.60833 5.93206 3.89838C5.40802 2.49571 6.13336 0.934092 7.54154 0.427844ZM20.8653 60.0253L57.8071 42.9662C59.2147 42.3162 60.8796 42.9744 61.4633 44.4117C62.0102 45.7581 61.4028 47.2968 60.0843 47.9057L27.8549 62.7887C25.164 64.0313 21.9813 62.773 20.8653 60.0253Z"
      fill={color || "#00935F"}
    />
    <path
      d="M33.1325 30.725C33.6579 31.1906 34.1781 31.6811 34.6932 32.1966C35.5142 33.0183 36.2716 33.8504 36.9653 34.693L65.8551 14.0982C67.0836 13.2225 67.3682 11.5149 66.4903 10.2874C65.6153 9.06402 63.916 8.78029 62.6916 9.65315L33.1325 30.725Z"
      fill={color || "#00935F"}
    />
    <path
      d="M28.3415 27.2536L51.9538 8.61842C53.1398 7.68243 53.3398 5.95997 52.3999 4.77673C51.4652 3.60014 49.7559 3.40136 48.5766 4.3321L22.5441 24.8773C24.0591 25.2586 25.5824 25.8316 27.1138 26.5963C27.5258 26.8021 27.935 27.0211 28.3415 27.2536Z"
      fill={color || "#00935F"}
    />
    <path
      d="M42.0013 44.2473L65.482 29.3263C66.7757 28.5042 67.138 26.7765 66.2836 25.5033C65.4597 24.2755 63.8082 23.9287 62.5607 24.7214L39.9549 39.0865C40.0912 39.3374 40.2223 39.5891 40.3482 39.8417C41.0668 41.3054 41.6178 42.774 42.0013 44.2473Z"
      fill={color || "#00935F"}
    />
  </svg>
);
