import { css, cx } from "emotion";
import React, { FC, useRef, useState, useEffect } from "react";
import { useOutsideClick } from "../utils/hooks";
import theme from "../utils/theme";
import { Cross } from "./icons/Cross";

export const Tag: FC<{
  tag: string;
  isSelected: boolean;
  coreTagButton?: boolean;
  tags?: string[];
  removeTag: (tag: string) => void;
  addTag?: (tag: string) => void;
}> = ({ tag, isSelected, coreTagButton, tags, addTag, removeTag }) => {
  const handleTagClick = () => {
    if (isSelected) {
      removeTag(tag);
    } else {
      addTag && addTag(tag);
    }
  };

  if (coreTagButton) {
    return (
      <UploadTagButton
        isSelected={isSelected}
        tag={tag}
        tags={tags || []}
        onClick={handleTagClick}
        addTag={addTag}
        removeTag={removeTag}
      />
    );
  }
  return (
    <TagButton isSelected={isSelected} tag={tag} onClick={handleTagClick} />
  );
};

export const TagsAsBobbles: FC<{
  tags: string[];
  maxTags: number;
  className?: string;
}> = ({ tags, maxTags, className }) => {
  const numberOfSlicesTags = tags.length - maxTags;
  const tagsToDisplay = tags
    .filter((tag) => !grade.includes(tag))
    .slice(0, maxTags);

  return (
    <div
      className={cx(
        css`
          padding: 5px 0;
          white-space: nowrap;
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
        `,
        className
      )}
    >
      {tagsToDisplay.map((tag) => (
        <BobbleTag key={tag} tag={tag} />
      ))}
      {numberOfSlicesTags > 0 && (
        <BobbleTag
          tag={`+${numberOfSlicesTags} emneknagg${
            numberOfSlicesTags > 1 ? "er" : ""
          }`}
        />
      )}
    </div>
  );
};

export const BobbleTag: FC<{ tag: string }> = ({ tag }) => (
  <span
    className={css`
      background-color: ${getTagColor(tag, true)};
      border-radius: 20px;
      padding: 2px 8px;
      margin-right: 5px;
      margin-bottom: 5px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  >
    {tag}
  </span>
);

export const TagButton: FC<{
  isSelected: boolean;
  tag: string;
  onClick: () => void;
  className?: string;
  btnRef?: React.RefObject<HTMLButtonElement>;
}> = ({ isSelected, onClick, tag, className, btnRef }) => (
  <button
    ref={btnRef}
    className={cx(
      css`
        height: 40px;
        padding: 0 16px;
        border-radius: 20px;
        color: ${isSelected
          ? theme.colors2.text.secondary
          : theme.colors2.text.primary};
        background-color: ${isSelected
          ? theme.colors2.tags.selected
          : getTagColor(tag)};
        margin: 0 8px 8px 0;

        &:hover {
          color: ${theme.colors2.text.secondary};
          background-color: ${theme.colors2.tags.selected};
        }
      `,
      className
    )}
    onClick={onClick}
  >
    {tag}
    {isSelected && (
      <Cross
        color={theme.colors.base.background.primary}
        className={css`
          margin-left: 8px;
        `}
      />
    )}
  </button>
);

export const UploadTagButton: FC<{
  isSelected: boolean;
  tag: string;
  tags: string[];
  onClick: () => void;
  removeTag: (tag: string) => void;
  addTag?: (tag: string) => void;
}> = ({ isSelected, tag, tags, onClick, removeTag, addTag }) => {
  const [showCoreTopicSelector, setShowCoreTopicSelector] = useState<boolean>(
    isSelected && topic.includes(tag)
  );
  const [
    buttonRefState,
    setButtonRefState,
  ] = useState<HTMLButtonElement | null>(null);
  const coreTopicSelectorRef = useRef<HTMLDivElement>(null);
  const topicButtonRef = useRef<HTMLButtonElement>(null);
  useOutsideClick(coreTopicSelectorRef, () => setShowCoreTopicSelector(false));

  useEffect(() => {
    // need to do this to trigger a rerender
    if (topicButtonRef.current) {
      setButtonRefState(topicButtonRef.current);
    }
  }, [setButtonRefState]);

  const handleCoreTopicTagClick = (coreTag: string) => {
    if (tags.includes(coreTag)) {
      removeTag(coreTag);
    } else {
      addTag && addTag(coreTag);
    }
  };

  const handleTagButtonClick = () => {
    if (topic.includes(tag)) {
      coreTopic[tag.toLocaleLowerCase()].forEach((ct) => removeTag(ct));
    }
    removeTag(tag);
  };

  return (
    <span
      className={css`
        display: flex;
        border-radius: 320px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        margin-bottom: 8px;

        button:first-child {
          margin-bottom: 0;
        }

        button:last-of-type {
          margin-bottom: 0;
          margin-right: 0;
        }

        .addCoreTagButton {
          &:first-of-type {
            margin-right: 0;
          }
        }
      `}
      ref={topicButtonRef}
    >
      <TagButton
        isSelected={isSelected}
        onClick={handleTagButtonClick}
        tag={tag}
        className="addCoreTagButton"
      />
      {topic.includes(tag) && !showCoreTopicSelector && (
        <button
          onClick={() => setShowCoreTopicSelector(true)}
          className={css`
            height: 40px;
            width: 40px;
            border-radius: 20px;
            margin-left: 4px;
            background-color: ${theme.colors.base.background.primary};
          `}
        >
          <Cross
            color={theme.colors2.cta.tertiary}
            className={css`
              transform: rotate(45deg);
            `}
          />
        </button>
      )}
      {showCoreTopicSelector &&
        coreTopic[tag.toLocaleLowerCase()] &&
        coreTopic[tag.toLocaleLowerCase()].length > 0 && (
          <div
            ref={coreTopicSelectorRef}
            className={css`
              position: absolute;
              left: 0;
              right: 0;
              top: ${(buttonRefState?.offsetTop || 0) + 55}px;
              z-index: 50;
              background: ${theme.colors.base.background.primary};
              box-shadow: ${theme.shadows.medium};
              border-radius: 2px;
              padding: 16px;

              &::after {
                content: "";
                position: absolute;
                left: ${buttonRefState
                  ? buttonRefState.offsetLeft +
                    buttonRefState.clientWidth / 2 -
                    30
                  : 0}px;
                top: -10px;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;

                border-bottom: 10px solid
                  ${theme.colors.base.background.primary};
              }
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
              `}
            >
              <p
                className={css`
                  margin-bottom: 28px;
                  font-style: italic;
                `}
              >
                Vil du legge til kjerneelementer innen faget?
              </p>
              <button onClick={() => setShowCoreTopicSelector(false)}>
                <Cross />
              </button>
            </div>
            <div
              className={css`
                display: flex;
                flex-wrap: wrap;
              `}
            >
              {(coreTopic[tag.toLocaleLowerCase()] || []).map(
                (coreTag, index) => (
                  <TagButton
                    key={`${coreTopic}-${index}`}
                    onClick={() => handleCoreTopicTagClick(coreTag)}
                    isSelected={tags.includes(coreTag)}
                    tag={coreTag}
                  />
                )
              )}
            </div>
          </div>
        )}
    </span>
  );
};

export const tagWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const getTagColor = (tag: string, asBobble?: boolean): string => {
  if (tagColors[tag]) {
    return tagColors[tag];
  } else if (topic.includes(tag)) {
    return theme.colors2.tags.topic;
  } else if (
    Object.values(coreTopic)
      .map((t) => t)
      .flat()
      .includes(tag)
  ) {
    return theme.colors2.tags.coreTopic;
  }

  if (asBobble) {
    return "#fafafa";
  }
  return theme.colors2.tags.primary;
};

const tagColors: { [key: string]: string } = {
  "1. trinn": theme.colors2.tags.grade1to4,
  "2. trinn": theme.colors2.tags.grade1to4,
  "3. trinn": theme.colors2.tags.grade1to4,
  "4. trinn": theme.colors2.tags.grade1to4,
  "5. trinn": theme.colors2.tags.grade5to7,
  "6. trinn": theme.colors2.tags.grade5to7,
  "7. trinn": theme.colors2.tags.grade5to7,
  "8. trinn": theme.colors2.tags.grade8to10,
  "9. trinn": theme.colors2.tags.grade8to10,
  "10. trinn": theme.colors2.tags.grade8to10,
  VG1: theme.colors2.tags.gradeVG1toVG3,
  VG2: theme.colors2.tags.gradeVG1toVG3,
  VG3: theme.colors2.tags.gradeVG1toVG3,
};

export const grade = [
  "1. trinn",
  "2. trinn",
  "3. trinn",
  "4. trinn",
  "5. trinn",
  "6. trinn",
  "7. trinn",
  "8. trinn",
  "9. trinn",
  "10. trinn",
  "VG1",
  "VG2",
  "VG3",
  "Voksenopplæring",
];

export const topic = [
  "Arbeidslivsfag",
  "Engelsk",
  "Fremmedspråk",
  "KRLE",
  "Kroppsøving",
  "Kunst og håndverk",
  "Mat og helse",
  "Matematikk",
  "Musikk",
  "Naturfag",
  "Norsk 2.språk",
  "Norsk",
  "Samfunnsfag",
  "Samisk",
  "Utdanningsvalg",
];

const coreTopic: { [topic: string]: string[] } = {
  arbeidslivsfag: [
    "Arbeidsfellesskap og helse, miljø og sikkerhet i arbeidsoppdrag",
    "Praktiske og yrkesretta arbeidsoppdrag",
  ],
  engelsk: [
    "Kommunikasjon",
    "Språklæring",
    "Møte med engelskspråklige tekster",
  ],
  fremmedspråk: [
    "Språk og teknologi",
    "Språklæring og flerspråklighet",
    "Interkulturell kompetanse",
    "Kommunikasjon",
  ],
  krle: [
    "Kjennskap til religioner og livssyn",
    "Utforsking av religioner og livssyn med ulike metoder",
    "Utforsking av eksistensielle spørsmål og svar",
    "Kunne ta andres perspektiv",
    "Etisk refleksjon",
  ],
  kroppsøving: [
    "Bevegelse og kroppslig læring",
    "Deltakelse og samspill i bevegelsesaktiviteter",
    "Uteaktiviteter og naturferdsel",
  ],
  "kunst og håndverk": [
    "Håndverksferdigheter",
    "Kunst- og designprosesser",
    "Visuell kommunikasjon",
    "Kulturforståelse",
  ],
  "mat og helse": [
    "Helsefremmende kosthold",
    "Mat og måltider som identitets- og kulturbærer",
    "Bærekraftige matvaner og forbruk",
  ],
  matematikk: [
    "Utforsking og problemløsing",
    "Representasjon og kommunikasjon",
    "Resonnering og argumentasjon",
    "Matematiske kunnskapsområder",
    "Modellering og anvendelser",
    "Abstraksjon og generalisering",
  ],
  musikk: ["Kulturforståelse", "Utøve musikk", "Oppleve musikk", "Lage musikk"],
  naturfag: [
    "Naturvitenskaplige praksiser og tenkemåter",
    "Jorda og livet på jorda",
    "Energi og materie",
    "Teknologi",
    "Kropp og helse",
  ],
  norsk: [
    "Kritisk tilnærming til tekst",
    "Muntlig kommunikasjon",
    "Tekst i kontekst",
    "Skriftlig tekstskaping",
    "Språklig mangfold",
    "Språket som system og mulighet",
  ],
  samfunnsfag: [
    "Undring og utforskning",
    "Perspektivmangfold og samfunnskritisk tenkning",
    "Identitet og livsmestring",
    "Medborgerskap og bærekraftig utvikling",
  ],
  samisk: [],
  utdanningsvalg: ["Karrierekompetanser"],
};

export const allTags = [...grade, ...topic];
