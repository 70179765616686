import { css } from "emotion";
import React, { FC } from "react";
import { Card } from "../../components/card/Card";
import { StepDescription } from "./components/StepDescription";
import { ArrowCorner } from "../../components/icons/Arrow";
import { Alert } from "../../components/Alert";
import { PostStatusType, UploadedFile } from "./Upload";
import theme from "../../utils/theme";

export const Summary: FC<{
  description: string;
  files: UploadedFile[];
  links: string[];
  postStatus: PostStatusType;
  tags: string[];
  title: string;
  validSteps: number[];
  handleDeleteFile: (index: number, type: "UPLOADED" | "CHOSEN") => void;
  handleRemoveLink: (index: number) => void;
  handleUpload: () => void;
}> = ({
  description,
  files,
  handleDeleteFile,
  handleRemoveLink,
  handleUpload,
  links,
  postStatus,
  tags,
  title,
  validSteps,
}) => {
  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        padding: 20px;
        flex-direction: column;
        align-items: center;
      `}
    >
      <StepDescription
        title="Vil du publisere bidraget ditt?"
        files={files}
        deleteFile={handleDeleteFile}
        cannotDeleteFiles={Boolean(postStatus.loading || postStatus.postId)}
        links={links}
        removeLink={handleRemoveLink}
      />
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <Card
          mode="VERTICAL"
          disableOnClick
          post={{
            bookmarked: false,
            bookmarks: 0,
            created: null,
            description: description,
            downloads: 0,
            files: [],
            links: [],
            id: -1,
            liked: false,
            likes: 0,
            tags: tags,
            title: title,
            user: { id: -1, name: null, special: null },
            comments: []
          }}
        />
        <button
          className={css`
            margin-top: 24px;
            width: 250px;
            height: 50px;
            border-radius: 4px;
            background-color: ${theme.colors.base.background.secondary};
            color: ${theme.colors.base.text.secondary};
            font-size: 18px;
          `}
          onClick={() => handleUpload()}
          disabled={
            Boolean(postStatus.loading || postStatus.postId) ||
            ![0, 1, 2].every((step) => validSteps.includes(step))
          }
        >
          Klikk for å laste opp
          <ArrowCorner
            direction="UP"
            size={18}
            style={{ marginLeft: 8 }}
            color={theme.colors.base.text.secondary}
          />
        </button>
      </div>
      {postStatus.error && (
        <Alert marginTop={16} level="ERROR">
          {postStatus.error}
        </Alert>
      )}
    </div>
  );
};
