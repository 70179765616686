import {css, cx} from "emotion";
import React, {ChangeEvent, FC, useState} from "react";
import {useInterval} from "../../../utils/hooks";
import theme from "../../../utils/theme";

export const SearchInput: FC<{
  id: string;
  onChange: (value: string) => void;
}> = ({ id, onChange }) => {
  const [value, setValue] = useState("");
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };
  return (
      <div
          id={id}
          className={css`
      font-size: 24px;
      position: relative;
      flex: 1;
    `}
      >
        <input
          onChange={handleChange}
        />
        <MagicPlaceholder hide={Boolean(value)} />
      </div>
  );
}

const MagicPlaceholder: FC<{ hide: boolean }> = ({ hide }) => {
  const [wordIndex, setWordIndex] = useState(0);
  const [slice, setSlice] = useState(0);
  const [direction, setDirection] = useState<"FORWARD" | "BACKWARD">("FORWARD");

  const currentWord = placeholderKeywords[wordIndex];

  useInterval(
    () => {
      if (direction === "FORWARD") {
        if (slice === currentWord.length) {
          setDirection("BACKWARD");
        } else {
          setSlice(slice + 1);
        }
      } else {
        if (slice === 0) {
          setWordIndex((wordIndex + 1) % placeholderKeywords.length);
          setDirection("FORWARD");
        } else {
          setSlice(slice - 1);
        }
      }
    },
    direction === "FORWARD" ? 120 : 80
  );

  return (
    <span
      className={cx(
        css`
          position: absolute;
          top: 16px;
          left: 16px;
          pointer-events: none;
          color: ${theme.colors.base.text.primary};
          opacity: 0.8;
        `,
        hide &&
          css`
            display: none;
          `
      )}
    >
      Søk etter{" "}
      <span
        className={css`
          color: ${theme.colors.search.secondary};
          opacity: 0.8;
        `}
      >
        {currentWord.slice(0, slice)}
      </span>
    </span>
  );
};

const placeholderKeywords = [
  "demokrati    ",
  "eksperiment    ",
  "uteskole    ",
  "retorikk    ",
  "tverrfaglig    ",
  "teknologi    ",
  "medborgerskap    ",
];
