import { css, cx } from "emotion";
import React, { FC, useState } from "react";
import { Card } from "../../../components/card/Card";
import { Cross } from "../../../components/icons/Cross";
import filterImg from "../../../components/icons/filter.svg";
import gridImg from "../../../components/icons/grid.svg";
import listImg from "../../../components/icons/list.svg";
import { grade, topic } from "../../../components/Tags";
import theme from "../../../utils/theme";
import { TagFilterRow } from "../content/Content";
import { PostData } from "../Home";
import { useSearch } from "../../../utils/useSearch";

const searchKeys = [
  "description",
  "tags",
  "title",
  "files.filename",
  "user.special",
];

export const SearchResults: FC<{
  value: string;
  posts: PostData[];
}> = ({ posts, value }) => {
  const results = useSearch<PostData>(value, posts, searchKeys);

  const [viewMode, setViewMode] = useState<"LIST" | "GRID">("GRID");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [gradeFilter, setGradeFilter] = useState<string[]>([]);
  const [topicFilter, setTopicFilter] = useState<string[]>([]);
  const [popularFilter, setPopularFilter] = useState<string[]>([]);

  const handleUpdateGradeFilter = (tag: string) => {
    const tagExistsIndex = gradeFilter.findIndex((t) => t === tag);
    if (tagExistsIndex === -1) {
      setGradeFilter((tags) => [...tags, tag]);
    } else {
      let gradeFilterCopy = [...gradeFilter];
      gradeFilterCopy.splice(tagExistsIndex, 1);
      setGradeFilter(gradeFilterCopy);
    }
  };

  const handleUpdateTopicFilter = (tag: string) => {
    const tagExistsIndex = topicFilter.findIndex((t) => t === tag);
    if (tagExistsIndex === -1) {
      setTopicFilter((tags) => [...tags, tag]);
    } else {
      let topicFilterCopy = [...topicFilter];
      topicFilterCopy.splice(tagExistsIndex, 1);
      setTopicFilter(topicFilterCopy);
    }
  };
  const handleUpdatePopularFilter = (tag: string) => {
    const tagExistsIndex = popularFilter.findIndex((t) => t === tag);
    if (tagExistsIndex === -1) {
      setPopularFilter((tags) => [...tags, tag]);
    } else {
      let popularFilterCopy = [...popularFilter];
      popularFilterCopy.splice(tagExistsIndex, 1);
      setPopularFilter(popularFilterCopy);
    }
  };

  const filteredResults = results
    .filter((post) =>
      gradeFilter.length > 0
        ? post.tags.some((tag) => gradeFilter.includes(tag))
        : true
    )
    .filter((post) =>
      topicFilter.length > 0
        ? post.tags.some((tag) => topicFilter.includes(tag))
        : true
    )
    .filter((post) =>
      popularFilter.length > 0
        ? post.tags.some((tag) => popularFilter.includes(tag))
        : true
    );

  const possibleTags = extractTags(filteredResults.map((data) => data.tags));

  return (
    <div
      className={css`
        margin-top: 80px;
        margin-bottom: 30px;
        width: 100%;
      `}
    >
      {showFilter && (
        <div
          className={css`
            margin-top: 60px;
            margin-bottom: 60px;
            background-color: ${theme.colors2.background.primary};
            width: 100%;
          `}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-top: 40px;
              padding-bottom: 20px;
              width: 1330px;
              margin: 0px auto;
              position: relative;

              @media screen and (max-width: 1400px) {
                width: 90vw;
              }
            `}
          >
            <button
              onClick={() => setShowFilter(false)}
              className={css`
                padding: 8px 16px;
                height: 40px;
                background-color: ${theme.colors2.background.primary};
                position: absolute;
                top: -40px;
                left: 250px;

                @media screen and (max-width: 750px) {
                  left: 0px;
                }
              `}
            >
              Filtrér etter
              <Cross
                className={css`
                  margin-left: 80px;
                `}
              />
            </button>
            <TagFilterRow
              title="Klassetrinn"
              tags={grade}
              selectedTags={[...popularFilter, ...gradeFilter]}
              onTagClick={handleUpdateGradeFilter}
            />
            <TagFilterRow
              title="Fag"
              tags={topic}
              selectedTags={[...popularFilter, ...topicFilter]}
              onTagClick={handleUpdateTopicFilter}
            />
            <TagFilterRow
              title="Mest brukte"
              tags={possibleTags.slice(0, 10)}
              selectedTags={[...popularFilter, ...topicFilter, ...gradeFilter]}
              onTagClick={handleUpdatePopularFilter}
            />
          </div>
        </div>
      )}

      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 1330px;
          margin: 0px auto;

          @media screen and (max-width: 1400px) {
            width: 90vw;
          }
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: center;
            `}
          >
            <h2
              className={css`
                width: 250px;

                @media screen and (max-width: 750px) {
                  display: none;
                }
              `}
            >
              Mest relevante treff:
            </h2>
            {!showFilter && (
              <button
                onClick={() => setShowFilter(true)}
                className={css`
                  padding: 8px 16px;
                  background-color: ${theme.colors2.background.secondary};
                `}
              >
                Filtrér etter
                <img
                  src={filterImg}
                  alt=""
                  className={css`
                    margin-left: 80px;
                  `}
                />
              </button>
            )}
          </div>
          <div
            className={css`
              display: flex;
              align-items: center;
            `}
          >
            <p
              className={css`
                @media screen and (max-width: 450px) {
                  display: none;
                }
              `}
            >
              Vis heller som
            </p>
            <button
              onClick={() => setViewMode(viewMode === "GRID" ? "LIST" : "GRID")}
              title={viewMode === "GRID" ? "LIST" : "GRID"}
              className={css`
                background-color: ${theme.colors.base.background.primary};
                height: 40px;
                width: 40px;
                border-radius: 20px;
                margin-left: 10px;
              `}
            >
              {viewMode === "GRID" ? (
                <img src={listImg} alt="list" />
              ) : (
                <img src={gridImg} alt="grid" />
              )}
            </button>
          </div>
        </div>
        <div
          className={cx(
            css`
              margin-top: 48px;
            `,
            viewMode === "GRID"
              ? css`
                  display: grid;
                  grid-gap: 24px;
                  grid-template-columns: repeat(auto-fit, 250px);
                  grid-template-rows: repeat(auto-fit, 420px);
                  align-items: center;
                  justify-items: center;
                `
              : css`
                  display: flex;
                  flex-direction: column;
                `
          )}
        >
          {filteredResults.map((post) => (
            <Card
              mode={viewMode === "GRID" ? "VERTICAL" : "HORIZONTAL"}
              key={`${post.id}-${post.title}`}
              post={post}
              refetchPosts={() => {}}
              searchContext={value}
            />
          ))}
        </div>
        {(filteredResults.length === 0 || results.length === 0) && (
          <div>Ingen resultater</div>
        )}
      </div>
    </div>
  );
};

function extractTags(tagList: string[][]) {
  let flatList: string[] = tagList.flat();
  const sortedList = flatList
    .filter((tag) => !grade.includes(tag))
    .sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  const occurrences: Record<string, number> = {};
  for (const element of sortedList) {
    if (!occurrences[element]) {
      occurrences[element] = 0;
    }
    occurrences[element]++;
  }
  return Object.entries(occurrences)
    .sort(
      ([_aElement, aOccurrences], [_bElement, bOccurrences]) =>
        bOccurrences - aOccurrences
    )
    .map(([element]) => element);
}
