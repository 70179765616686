import React, { FC } from "react";
import { css } from "emotion";
import { Hand } from "../components/icons/Hand";
import { GoogleLink } from "../components/modals/signUpInModal/LogInOrSignUp";
import theme from "../utils/theme";
import { Link, RouteComponentProps } from "@reach/router";

export const LogIn: FC<RouteComponentProps> = () => {
  return (
    <div
      className={css`
        height: calc(100vh - 100px);
      `}
    >
      <div
        className={css`
          padding: 100px 20px 20px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <h1
            className={css`
              margin-top: 70px;
              margin-bottom: 30px;
            `}
          >
            Hei! Fint å se deg igjen
          </h1>
          <Hand />
          <div
            className={css`
              margin-top: 80px;
            `}
          >
            <GoogleLink link="/api/login" title="Logg inn med Google" />
          </div>
          <p
            className={css`
              margin-top: 40px;
              color: ${theme.colors.base.colors.orange.primary};
            `}
          >
            Har du ingen bruker ennå?
          </p>
          <Link
            to="/sign-up"
            className={css`
              margin-top: 8px;
            `}
          >
            Opprett bruker
          </Link>
        </div>
        <p
          className={css`
            margin-top: 100px;
          `}
        >
          Ved å logge inn, eller opprette en bruker samtykker du til våre{" "}
          <Link
            className={css`
              color: #2057d6;
            `}
            to="/terms-of-use"
          >
            Vilkår og vårt personvern
          </Link>{" "}
        </p>
      </div>
    </div>
  );
};

export const SignUp: FC<RouteComponentProps> = () => {
  return (
    <div
      className={css`
        height: calc(100vh - 100px);
      `}
    >
      <div
        className={css`
          padding: 100px 20px 20px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <h1
            className={css`
              margin-top: 70px;
              margin-bottom: 30px;
            `}
          >
            "Du er inne om et par strakser..."
          </h1>
          <svg
            width="180"
            height="100"
            viewBox="0 0 180 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.384 29.4413C102.635 28.6808 104.267 29.0597 105.046 30.294L105.083 30.3532C109.104 36.7234 111.093 44.2591 109.472 56.4634L106.94 75.4964C103.967 75.0643 101.896 72.3319 102.291 69.3617L104.11 55.6851L104.11 55.6846C105.599 44.4805 103.739 38.2528 100.509 33.1357C99.7161 31.8791 100.111 30.2157 101.384 29.4413ZM126.366 84.6457L158.981 60.6753C160.224 59.7615 161.98 60.0656 162.838 61.3434C163.643 62.5422 163.358 64.1614 162.192 65.0187L133.747 85.9244C131.367 87.6731 128.008 87.091 126.366 84.6457Z"
              fill="#FFE566"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M132.444 53.7355C133.05 54.0823 133.656 54.4543 134.262 54.8516C135.229 55.485 136.135 56.1413 136.981 56.8204L161.026 31.0343C162.05 29.9356 161.986 28.2178 160.882 27.2015C159.782 26.1893 158.067 26.2556 157.047 27.3499L132.444 53.7355ZM127.071 51.3249L146.36 28.4995C147.331 27.3508 147.181 25.6358 146.025 24.675C144.877 23.72 143.169 23.8709 142.204 25.0126L120.937 50.1793C122.491 50.2459 124.093 50.497 125.741 50.9326C126.184 51.0498 126.628 51.1806 127.071 51.3249ZM143.816 65.089L163.723 45.8966C164.822 44.8371 164.83 43.084 163.74 42.0184C162.689 40.9918 161.008 40.9872 159.949 42.0081L140.782 60.4876C140.965 60.7039 141.144 60.922 141.317 61.142C142.313 62.4194 143.146 63.7351 143.816 65.089Z"
              fill="#FFE566"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M78.6155 29.4413C77.3651 28.6808 75.7327 29.0597 74.9537 30.294L74.9163 30.3532C70.8958 36.7234 68.9066 44.2591 70.5277 56.4634L73.0593 75.4964C76.0328 75.0643 78.1036 72.3319 77.7086 69.3617L75.8894 55.6851L75.8894 55.6846C74.4012 44.4805 76.2609 38.2528 79.4906 33.1357C80.2837 31.8791 79.8885 30.2157 78.6155 29.4413ZM53.6337 84.6457L21.0186 60.6753C19.7753 59.7615 18.0198 60.0656 17.1618 61.3434C16.3568 62.5422 16.6415 64.1614 17.8079 65.0187L46.253 85.9244C48.6323 87.6731 51.9917 87.091 53.6337 84.6457Z"
              fill="#FFEFA4"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M47.5557 53.7355C46.9495 54.0823 46.3433 54.4543 45.7371 54.8516C44.7708 55.485 43.8644 56.1413 43.018 56.8204L18.9739 31.0343C17.9494 29.9356 18.0139 28.2178 19.1177 27.2015C20.2171 26.1893 21.9323 26.2556 22.9526 27.3499L47.5557 53.7355ZM52.9285 51.3249L33.6394 28.4995C32.6687 27.3508 32.8188 25.6358 33.9741 24.675C35.1224 23.72 36.8305 23.8709 37.7953 25.0126L59.063 50.1793C57.5081 50.2459 55.9067 50.497 54.2587 50.9326C53.8153 51.0498 53.3719 51.1806 52.9285 51.3249ZM36.1831 65.089L16.2763 45.8966C15.1772 44.8371 15.1699 43.084 16.26 42.0184C17.3103 40.9918 18.9912 40.9872 20.0501 42.0081L39.2176 60.4876C39.0342 60.7039 38.8557 60.922 38.6821 61.142C37.6865 62.4194 36.8536 63.7351 36.1831 65.089Z"
              fill="#FFEFA4"
            />
          </svg>

          <div
            className={css`
              margin-top: 80px;
            `}
          >
            <GoogleLink link="/api/login" title=" Registrer deg med Google" />
          </div>
          <p
            className={css`
              margin-top: 40px;
              color: ${theme.colors.base.colors.orange.primary};
            `}
          >
            Har du allerede en bruker?
          </p>
          <Link
            to="/log-in"
            className={css`
              margin-top: 8px;
            `}
          >
            Logg inn
          </Link>
        </div>
        <p
          className={css`
            margin-top: 100px;
          `}
        >
          Ved å logge inn, eller opprette en bruker samtykker du til våre{" "}
          <Link
            className={css`
              color: #2057d6;
            `}
            to="/"
          >
            Vilkår
          </Link>{" "}
          og vårt{" "}
          <Link
            className={css`
              color: #2057d6;
            `}
            to="/"
          >
            Personvern
          </Link>
        </p>
      </div>
    </div>
  );
};
