import React, { FC } from "react";
import theme from "../../utils/theme";

export const Mail: FC<{
  size?: number;
  color?: string;
  className?: string;
  alt?: string;
}> = ({ size, color, className, alt }) => (
  <svg
    width={size || 16}
    height={size || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {alt && <title>{alt}</title>}
    <g>
      <path
        d="M2.66732 2.66699H13.334C14.0673 2.66699 14.6673 3.26699 14.6673 4.00033V12.0003C14.6673 12.7337 14.0673 13.3337 13.334 13.3337H2.66732C1.93398 13.3337 1.33398 12.7337 1.33398 12.0003V4.00033C1.33398 3.26699 1.93398 2.66699 2.66732 2.66699Z"
        stroke={color || theme.colors2.cta.tertiary}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6673 4L8.00065 8.66667L1.33398 4"
        stroke={color || theme.colors2.cta.tertiary}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
