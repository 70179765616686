import {css} from "emotion";
import {Modal} from "../modal/Modal";
import React, {FC, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import theme from "../../utils/theme";
import {Alert} from "../Alert";

export const FeedbackModal: FC<RouteComponentProps & {
  message: string;
  onConfirm: (feedbackText: string) => Promise<void>;
  onClose: () => void;
}> = ({message, onConfirm, onClose}) => {
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [flagPostError, setFlagPostError] = useState<string>("");
  const [flagPostSuccess, setFlagPostSuccess] = useState<boolean>(false);
  
  const _onConfirm = async () => {
    try {
      await onConfirm(feedbackText);
      setFlagPostSuccess(true);
    } catch (error) {
      setFlagPostError(error.toString());
    } finally {
      setFeedbackText("");
      setFlagPostError("");
    }
  };
  
  if (flagPostError) {
    return (
      <Alert
        level="ERROR"
        className={css`
        margin-top: 20px;
      `}
      >
        {flagPostError}
      </Alert>
    )
  }
  
  if (flagPostSuccess) {
    return (
      <Modal
        closeModal={onClose}
        autoDismiss={2000}
        modalSize={{ width: "400px", height: "300px" }}
      >
        <div
          className={css`
            margin-top: 50px;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
          `}
        >
          <h1>Tilbakemeldingen er registrert.</h1>
        </div>
      </Modal>
    );
  }
  
  return (
    <Modal
      closeModal={onClose}
      modalSize={{ width: "790px", height: "500px" }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
        `}
      >
        <h1>{message}</h1>
        <label htmlFor="flag-reason" className="sr-only">
          Tilbakemelding
        </label>
        <textarea
          id="flag-reason"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Skriv en tilbakemelding her, så tar vi tak i det."
          className={css`
            margin-top: 50px;
            width: 520px;
            height: 150px;
            background-color: ${theme.colors.base.background.tertiary};

            @media screen and (max-width: 650px) {
              width: 100%;
            }
          `}
        />
        {flagPostError && (
          <Alert
            level="ERROR"
            className={css`
              margin-top: 20px;
            `}
          >
            {flagPostError}
          </Alert>
        )}
        <div
          className={css`
            display: flex;
            flex: 1;
            align-items: flex-end;

            @media screen and (max-width: 650px) {
              flex-direction: column;
              align-items: center;
              justify-content: flex-end;
            }
          `}
        >
          <button
            className={css`
              width: 250px;
              height: 60px;
              background-color: ${theme.colors.base.background.secondary};
              color: ${theme.colors.base.text.secondary};
              border-radius: 4px;
              &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
              }
            `}
            disabled={!feedbackText}
            onClick={_onConfirm}
          >
            Rapporter kommentar
          </button>
          <button
            className={css`
              width: 250px;
              height: 60px;
              border-radius: 4px;
            `}
            onClick={() => {
              setFeedbackText("");
              onClose();
            }}
          >
            Avbryt
          </button>
        </div>
      </div>
    </Modal>
  );
}
