import React, { FC } from "react";
import { css, keyframes, cx } from "emotion";
import theme from "../../utils/theme";

export const Cross: FC<{
  spin?: boolean;
  color?: string;
  className?: string;
}> = ({ spin, color, className }) => (
  <span
    className={cx(
      css`
        position: relative;
        width: 16px;
        height: 16px;
      `,
      className,
      spin &&
        css`
          animation-name: ${spinKeyframe};
          animation-duration: 1500ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        `
    )}
  >
    <span
      className={css`
        position: absolute;
        transform: rotate(45deg);
        background-color: ${color || theme.colors.base.background.secondary};
        border-radius: 2px;
        width: 2px;
        height: 17px;
        top: 0;
        left: 7px;
      `}
    />
    <span
      className={css`
        position: absolute;
        transform: rotate(-45deg);
        background-color: ${color || theme.colors.base.background.secondary};
        border-radius: 2px;
        width: 2px;
        height: 17px;
        top: 0;
        left: 7px;
      `}
    />
  </span>
);

const spinKeyframe = keyframes`
from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;
