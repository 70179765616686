import Fuse from "fuse.js";
import { useEffect, useState } from "react";

const FUSE_OPTIONS = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

export function useSearch<T>(query: string, data: T[], keys?: string[]) {
  const [results, setResults] = useState<T[]>([]);

  useEffect(() => {
    if (query.length > 0 && data.length > 0) {
      const fuseResults = new Fuse(data, {
        ...FUSE_OPTIONS,
        keys: keys || [],
      }).search(query);

      setResults(fuseResults.map((result) => result.item));
    }
    if (query.length === 0) {
      setResults([]);
    }
  }, [query, data, keys]);

  return results;
}
