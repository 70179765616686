import { css, cx, keyframes } from "emotion";
import FocusTrap from "focus-trap-react";
import React, { FC, useEffect } from "react";
import closeImg from "./close.svg";
import maximizeImg from "./maximize.svg";
import theme from "../../utils/theme";
import { useInterval } from "../../utils/hooks";

export const Modal: FC<{
  closeModal: () => void;
  maximize?: () => void;
  modalSize?: { width: string; height: string };
  hideDefaultButtons?: boolean;
  fullSizeContent?: boolean;
  autoDismiss?: number;
  backgroundColor?: string;
}> = ({
  closeModal,
  maximize,
  modalSize,
  children,
  fullSizeContent,
  hideDefaultButtons,
  autoDismiss,
  backgroundColor
}) => {
  useEffect(() => {
    const handleEscPress = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    window.addEventListener("keyup", handleEscPress);

    return () => {
      window.removeEventListener("keyup", handleEscPress);
    };
  }, [closeModal]);

  useInterval(() => {
    closeModal();
  }, autoDismiss || null);

  return (
    <FocusTrap active={!hideDefaultButtons}>
      <div
        tabIndex={-1}
        className={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
        onClick={(ev) => {
          if ((ev.target as HTMLDivElement).closest(".modal-inner") !== null) {
            // Don't close modal if the click is inside Inner
            return;
          }
          closeModal();
        }}
      >
        <div
          className={cx(
            "modal-inner",
            css`
              display: flex;
              flex-direction: column;
              background-color: ${backgroundColor ? backgroundColor : theme.colors.base.background.primary};
              border-radius: 2px;
              padding: 20px;
              position: relative;
              overflow-y: auto;
            `,
            modalSize
              ? css`
                  width: ${modalSize.width};
                  height: ${modalSize.height};

                  @media screen and (max-width: ${modalSize.width}) {
                    width: 90vw;
                    height: 90vh;
                  }
                `
              : css`
                  width: 90vw;
                  height: 90vh;
                `,
            fullSizeContent &&
              css`
                padding: 0;
              `
          )}
        >
          {!hideDefaultButtons && (
            <div
              className={cx(
                css`
                  display: flex;
                  justify-content: flex-end;
                `,
                fullSizeContent &&
                  css`
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    z-index: 200;
                  `
              )}
            >
              {maximize && (
                <button
                  onClick={maximize}
                  className={css`
                    margin-right: 10px;
                  `}
                >
                  <img src={maximizeImg} alt="Gå til" />
                </button>
              )}
              <button onClick={closeModal}>
                <img src={closeImg} alt="Lukk modal" />
              </button>
            </div>
          )}
          {children}
          {autoDismiss && (
            <div
              className={css`
                height: 4px;
                border-radius: 2px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: ${theme.colors.base.background.primary};
              `}
            >
              <div
                className={css`
                  position: absolute;
                  left: 0;
                  height: 4px;
                  border-radius: 0 2px 2px 0;
                  width: 0%;
                  background-color: ${theme.colors.base.colors.orange.primary};
                  animation: ${keyframes`from { width: 0%; } to { width: 100%; }`}
                    ${autoDismiss}ms forwards linear;
                `}
              />
            </div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
};
