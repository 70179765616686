import { Link } from "@reach/router";
import { css } from "emotion";
import React, { FC } from "react";
import theme from "../../../utils/theme";
import { Hand } from "../../icons/Hand";
import { Modal } from "../../modal/Modal";
import { GoogleLink } from "./LogInOrSignUp";

export const LogInModal: FC<{
  message?: string;
  closeModal: () => void;
  changeToSignUpModal: () => void;
}> = ({ message, closeModal, changeToSignUpModal }) => {
  return (
    <Modal
      closeModal={closeModal}
      modalSize={{ width: "790px", height: "780px" }}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 100%;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <h1
            className={css`
              margin-top: 70px;
              margin-bottom: 30px;
            `}
          >
            {message || "Hei! Fint å se deg igjen"}
          </h1>
          <Hand />
          <div
            className={css`
              margin-top: 80px;
            `}
          >
            <GoogleLink link="/api/login" title="Logg inn med Google" />
          </div>
          <p
            className={css`
              margin-top: 40px;
              color: ${theme.colors.base.colors.orange.primary};
            `}
          >
            Har du ingen bruker ennå?
          </p>
          <button
            className={css`
              margin-top: 8px;
            `}
            onClick={changeToSignUpModal}
          >
            Opprett bruker
          </button>
        </div>
        <p
          className={css`
            margin-top: 100px;
          `}
        >
          Ved å logge inn, eller opprette en bruker samtykker du til våre{" "}
          <Link
            className={css`
              color: #2057d6;
            `}
            to="/terms-of-use"
          >
            Vilkår og vårt personvern
          </Link>
        </p>
      </div>
    </Modal>
  );
};
