import { RouteComponentProps } from "@reach/router";
import { css } from "emotion";
import React from "react";
import { LinkButton } from "../components/Buttons";
import notFoundImg from "../components/icons/notFound.svg";

export const PageNotFound: React.FC<RouteComponentProps> = () => {
  return (
    <div
      className={css`
        background-color: #feefa4;
      `}
    >
      <div
        className={css`
          margin: 0 auto;
          padding-top: 100px;
          padding-bottom: 100px;
          max-width: 1330px;

          p {
            font-weight: 300;
            font-size: 144px;
            line-height: 180px;
          }

          @media screen and (max-width: 1330px) {
            padding: 10vw;

            p {
              font-size: 100px;
              line-height: 120px;
            }
          }
          @media screen and (max-width: 750px) {
            padding-top: 150px;

            p {
              font-size: 60px;
              line-height: 80px;
            }
          }
        `}
      >
        <p>Ojda.</p>
        <p>Siden du ser etter finnes ikke.</p>
        <div
          className={css`
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            img {
              margin-left: 100px;
            }

            h2 {
              font-weight: normal;
              font-size: 144px;
              line-height: 180px;
            }

            @media screen and (max-width: 1330px) {
              flex-direction: column;
              align-items: flex-start;

              button {
                margin-top: 50px;
              }

              h2 {
                font-size: 100px;
                line-height: 120px;
              }
            }

            @media screen and (max-width: 750px) {
              div {
                flex-direction: row;
                justify-content: center;
                width: 100%;
              }

              img {
                margin-left: 20px;
                width: 80px;
              }
              h2 {
                font-size: 60px;
                line-height: 80px;
              }
            }
          `}
        >
          <div
            className={css`
              display: flex;

              @media screen and (max-width: 750px) {
                flex-direction: column;
              }
            `}
          >
            <h2>404</h2>
            <img src={notFoundImg} alt="" />
          </div>
          <LinkButton to="/">Ta meg til forsiden</LinkButton>
        </div>
      </div>
    </div>
  );
};
