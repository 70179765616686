import { Link, RouteComponentProps } from "@reach/router";
import Axios from "axios";
import { css } from "emotion";
import React, { FC, useState } from "react";
import { Alert } from "../../components/Alert";
import { Card } from "../../components/card/Card";
import { CopyDataButton } from "../../components/CopyDataButton";
import { Apple } from "../../components/icons/Apple";
import { Arrow } from "../../components/icons/Arrow";
import { Bookmark } from "../../components/icons/Bookmark";
import pencilImg from "../../components/icons/pencil.svg";
import shareImg from "../../components/icons/share.svg";
import trashImg from "../../components/icons/trash.svg";
import { Modal } from "../../components/modal/Modal";
import { useChangeHeaderColor } from "../../utils/hooks";
import theme from "../../utils/theme";
import { useDataApi } from "../../utils/useDataApi";
import { PostData } from "../home/Home";

export const MyPage: FC<RouteComponentProps> = () => {
  useChangeHeaderColor("#f5fcfb");

  return (
    <div
      className={css`
        padding-top: 160px;
        background-color: #f5fcfb;
      `}
    >
      <div
        className={css`
          width: 100vw;
          max-width: 100%;
          min-height: 100vh;
          background-color: ${theme.colors2.background.myPage};
        `}
      >
        <div
          className={css`
            width: 90vw;
            max-width: 1330px;
            margin: 0 auto;
            padding: 40px 0;

            h2 {
              color: ${theme.colors.base.text.primary};
              opacity: 0.8;
              margin-bottom: 30px;
              font-weight: 400;
            }
          `}
        >
          <Link to="/">
            <Arrow direction="LEFT" size={14} /> Til forsiden
          </Link>
          <MyBookmarkedPosts />
          <MyLikedPosts />
          <MyPosts />
        </div>
      </div>
    </div>
  );
};

const MyPosts: FC = () => {
  const [myPosts, refetchMyPosts] = useDataApi<{ posts: PostData[] }>(
    "/api/me/posts"
  );

  return (
    <div
      className={css`
        margin-top: 70px;
      `}
    >
      <h2>Mine bidrag</h2>
      {myPosts.isLoading && <div>Laster inn mine poster</div>}
      {myPosts.error && (
        <Alert level="ERROR">
          Ops! En feil skjedde {myPosts.error.toString()}
        </Alert>
      )}
      {myPosts.data && (
        <div
          className={css`
            @media screen and (max-width: 650px) {
              max-width: 90vw;
              overflow-x: auto;
            }
          `}
        >
          <table
            className={css`
              width: 100%;
              hyphens: none;

              @media screen and (max-width: 750px) {
                width: 1000px;
                overflow-x: auto;
              }

              thead {
                background-color: ${theme.colors.base.background.primary};
                opacity: 0.8;
                tr {
                  th {
                    text-align: left;
                    padding: 16px;
                  }
                }
              }

              tbody {
                background-color: ${theme.colors.base.background.primary};

                tr {
                  td {
                    padding: 8px 16px;

                    &:last-child {
                      display: flex;
                      justify-content: flex-end;

                      img {
                        margin-left: 15px;
                        width: 20px;
                      }
                    }
                  }

                  &:hover {
                    background-color: ${theme.colors.base.background.tertiary};
                  }
                }
              }
            `}
          >
            <thead>
              <tr>
                <th>Tittel</th>
                <th>Antall filer</th>
                <th>Antall nedlastinger</th>
                <th>Liker</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {myPosts.data.posts.map((post) => (
                <MyPostRow
                  key={post.id}
                  post={post}
                  refetchPosts={refetchMyPosts}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const MyPostRow: FC<{ post: PostData; refetchPosts: () => void }> = ({
  post,
  refetchPosts,
}) => {
  return (
    <>
      <tr key={post.id}>
        <td>{post.title}</td>
        <td>{post.files.length}</td>
        <td>{post.downloads}</td>
        <td>{post.likes}</td>
        <td>
          <DeletePostButton post={post} refetchPosts={refetchPosts} />
          <Link to={`/my-page/${post.id}/edit`}>
            <img src={pencilImg} alt="rediger" />
            <span className="sr-only">Rediger</span>
          </Link>
          <CopyDataButton data={`${window.location.origin}/p/${post.id}`}>
            <img src={shareImg} alt="del" />
            <span className="sr-only">Del</span>
          </CopyDataButton>
        </td>
      </tr>
    </>
  );
};

const DeletePostButton: FC<{ post: PostData; refetchPosts: () => void }> = ({
  post,
  refetchPosts,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeletePostClick = async () => {
    try {
      await Axios.delete(`/api/post/${post.id}`);
      setShowDeleteModal(false);
      refetchPosts();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {showDeleteModal && (
        <Modal
          closeModal={() => setShowDeleteModal(false)}
          modalSize={{ width: "300px", height: "350px" }}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex: 1;
            `}
          >
            <div
              className={css`
                text-align: center;
                margin-top: 50px;
              `}
            >
              <p
                className={css`
                  color: ${theme.colors2.accent.primary};
                `}
              >
                {post.title}
              </p>
              <h2
                className={css`
                  margin-top: 20px;
                `}
              >
                Er du sikker på at du ønsker å slette?
              </h2>
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;

                button {
                  height: 60px;
                  width: 140px;
                  border-radius: 4px;
                }
              `}
            >
              <button
                className={css`
                  padding: 8px 16px;
                  background-color: ${theme.colors2.cta.primary};
                  color: ${theme.colors2.cta.secondary};
                `}
                onClick={handleDeletePostClick}
              >
                Ja, Slett
              </button>
              <button onClick={() => setShowDeleteModal(false)}>Avbryt</button>
            </div>
          </div>
        </Modal>
      )}
      <button onClick={() => setShowDeleteModal(true)}>
        <img src={trashImg} alt="slett" />
        <span className="sr-only">Slett</span>
      </button>
    </>
  );
};

const MyBookmarkedPosts: FC = () => {
  const [bookmarkedPosts] = useDataApi<{ bookmarks: PostData[] }>(
    "/api/me/bookmarks"
  );
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <div
      className={css`
        margin-top: 70px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <h2>Bokmerkede bidrag</h2>
        <button
          className={css`
            height: 20px;
          `}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? "Vis innhold" : "Skjul innhold"}
        </button>
      </div>

      {bookmarkedPosts.isLoading && <div>Laster inn mine poster</div>}
      {bookmarkedPosts.error && (
        <Alert level="ERROR">
          Ops! En feil skjedde {bookmarkedPosts.error.toString()}
        </Alert>
      )}
      {bookmarkedPosts.data && (
        <>
          {!collapsed && (
            <>
              {bookmarkedPosts.data.bookmarks.length === 0 && (
                <span>
                  Du har ikke bokmerket noen bidrag ennå. Trykk på <Bookmark />{" "}
                  for å bokmerke ett innlegg.
                </span>
              )}
              {bookmarkedPosts.data.bookmarks.map((post, i) => (
                <Card
                  mode="HORIZONTAL"
                  key={post.title}
                  post={{ ...post, bookmarked: true }}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

const MyLikedPosts: FC = () => {
  const [likedPosts] = useDataApi<{ likes: PostData[] }>("/api/me/likes");
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <div
      className={css`
        margin-top: 70px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <h2>Eplemerkede bidrag</h2>
        <button
          className={css`
            height: 20px;
          `}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? "Vis innhold" : "Skjul innhold"}
        </button>
      </div>
      {likedPosts.isLoading && <div>Laster inn mine poster</div>}
      {likedPosts.error && (
        <Alert level="ERROR">
          Ops! En feil skjedde {likedPosts.error.toString()}
        </Alert>
      )}
      {likedPosts.data && (
        <>
          {!collapsed && (
            <>
              {likedPosts.data.likes.length === 0 && (
                <span>
                  Du har ikke eplemerkedet noen bidrag ennå. Trykk på <Apple />{" "}
                  for å eplemerket ett innlegg.
                </span>
              )}
              {likedPosts.data.likes.map((post, i) => (
                <Card
                  mode="HORIZONTAL"
                  key={post.title}
                  post={{ ...post, bookmarked: true }}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};
