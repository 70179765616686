import React, {FC} from "react";
import {RouteComponentProps} from "@reach/router";
import type {Comment} from "../../home/Home";
import {PostComment} from "./PostComment";
import {NewComment} from "./NewComment";
import {css} from "emotion";
import {useUserContext} from "../../../lib/UserContext";

export const Comments: FC<
    RouteComponentProps & {
    postId: number,
    comments: Comment[],
    refetchPost: () => void;
}> = ({ comments, postId, refetchPost }) => {
  const {isLoggedIn, showLogInOrSignUp, loading} = useUserContext();
  return (
    <div className={css`
      margin-bottom: 60px;
    `}>
        <h3 className={css`
          margin-bottom: 60px;
        `}>
          Kommentarer
        </h3>
      {loading
        ? <div>Henter brukerdata...</div>
        : isLoggedIn
          ? <NewComment postId={postId} callback={refetchPost} />
          : <button
              onClick={() => showLogInOrSignUp("LOG_IN", "Hei! For å kommentere må du være logget inn.")}
              className={css`
                margin-bottom: 30px;
              `}
          >
            Logg inn for å kommentere
        </button>
      }
        {
            comments
              .sort((a, b) => (new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()))
              .map(comment => (
                <PostComment comment={comment} postId={postId} refetchPost={refetchPost} key={comment.id} />
            ))
        }
    </div>
  );
}