import { RefObject, useEffect, useRef } from "react";

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(() => {});

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// https://stackoverflow.com/questions/56804790/closing-popup-on-clicking-outside-component
export function useOutsideClick(
  ref: RefObject<HTMLDivElement>,
  callback: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export function useChangeHeaderColor(color: string) {
  useEffect(() => {
    const headerElement = document.getElementById("header");

    if (headerElement) {
      headerElement.style.backgroundColor = color;
    }

    return () => {
      if (headerElement) {
        headerElement.style.backgroundColor = "transparent";
      }
    };
  }, [color]);
}
