import React, { FC } from "react";
import { css, cx } from "emotion";
import theme from "../../../utils/theme";

export const DropToAdd: FC<{ className?: string }> = ({ className }) => (
  <div
    className={cx(
      css`
        position: absolute;
        top: -500px;
        left: 0;
        bottom: -500px;
        right: 0;
        background-color: rgba(100, 100, 100, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
      `,
      className
    )}
  >
    <div
      className={css`
        display: flex;
        align-items: center;
        flex-direction: column;
      `}
    >
      <div
        className={css`
          position: relative;
          margin-bottom: 20px;
          height: 100px;
          width: 190px;
        `}
      >
        <div
          className={css`
            width: 100px;
            height: 80px;
            background-color: ${theme.colors.base.colors.green.primary};
            border: 5px solid white;
            border-radius: 3px;
            transform: rotate(3deg);
            position: absolute;
            bottom: 50px;
            left: 45px;
          `}
        />

        <div
          className={css`
            width: 100px;
            height: 80px;
            background-color: ${theme.colors.base.colors.orange.primary};
            border: 5px solid white;
            border-radius: 3px;
            transform: rotate(10deg);
            position: absolute;
          `}
        />
        <div
          className={css`
            width: 100px;
            height: 80px;
            background-color: ${theme.colors.base.colors.yellow.primary};
            border: 5px solid white;
            border-radius: 3px;
            transform: rotate(-13deg);
            position: absolute;
            bottom: 0;
            right: 0;
          `}
        />
      </div>
      <h1>Slipp for å legge til</h1>
    </div>
  </div>
);
