import { css, cx } from "emotion";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ArrowCorner } from "../../components/icons/Arrow";
import theme from "../../utils/theme";
import { DropToAdd } from "./components/DropToAdd";
import { FileItem } from "./components/FileItem";
import { StepDescription } from "./components/StepDescription";
import { UploadedFile } from "./Upload";

export const FileAndLinkUpload: FC<{
  chosenFiles: File[];
  links: string[];
  stepIsValid: boolean;
  uploadedFiles: UploadedFile[];
  uploadIsActive: boolean;
  handleAddLink: (link: string) => void;
  handleDeleteFile: (index: number, type: "UPLOADED" | "CHOSEN") => void;
  handleFileUpload: () => void;
  handleRemoveLink: (index: number) => void;
  handleAddChosenFiles: (newFiles: File[]) => void;
  validateStep: (isValid: boolean) => void;
}> = ({
  chosenFiles,
  handleAddChosenFiles,
  handleAddLink,
  handleDeleteFile,
  handleFileUpload,
  handleRemoveLink,
  links,
  stepIsValid,
  uploadedFiles,
  uploadIsActive,
  validateStep,
}) => {
  useEffect(() => {
    if (uploadedFiles.length > 0 || links.length > 0) {
      validateStep(true);
    } else if (
      stepIsValid &&
      uploadedFiles.length === 0 &&
      links.length === 0
    ) {
      validateStep(false);
    }
  }, [uploadedFiles.length, links.length, stepIsValid, validateStep]);
  const [link, setLink] = useState<string>("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleAddChosenFiles(acceptedFiles);
    },
    [handleAddChosenFiles]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <>
      <div
        className={cx(
          css`
            height: 25vh;
            max-height: 250px;
            transition: height 50ms ease-in-out;

            @media screen and (max-width: 800px) {
              margin-top: 80px;
              height: 0;
            }
          `,
          uploadIsActive &&
            css`
              height: 80px;
            `
        )}
      />
      <div
        className={css`
          outline: none;
          display: flex;
          flex: 1;
          max-width: 100%;
          position: relative;
        `}
        {...getRootProps()}
      >
        {isDragActive && <DropToAdd />}
        <div
          className={css`
            width: 90vw;
            max-width: 1000px;
            margin: 0 auto;
          `}
        >
          {uploadIsActive && (
            <StepDescription
              files={uploadedFiles}
              links={links}
              deleteFile={handleDeleteFile}
              removeLink={handleRemoveLink}
            />
          )}
          <div
            className={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <ArrowCorner
              direction="UP"
              color={theme.colors.upload.secondary}
              size={45}
              style={{ marginBottom: 30 }}
            />
            <div>
              <div
                className={css`
                  display: flex;
                  align-items: flex-end;
                `}
              >
                <InnerDropZone
                  isDragActive={isDragActive}
                  onDrop={onDrop}
                  id="uploadFile"
                />
                {chosenFiles.length > 0 && (
                  <button
                    onClick={handleFileUpload}
                    disabled={chosenFiles.length < 1}
                    className={css`
                      align-items: center;
                      background-color: ${theme.colors.base.background
                        .secondary};
                      border-radius: 4px;
                      color: ${theme.colors.upload.primary};
                      height: 60px;
                      margin-left: 24px;
                      width: 140px;

                      &:disabled {
                        opacity: 0.4;
                        cursor: not-allowed;
                      }

                      svg {
                        margin-left: 8px;
                      }
                    `}
                  >
                    Last opp{" "}
                    <ArrowCorner
                      size={16}
                      color={theme.colors.upload.primary}
                    />
                  </button>
                )}
              </div>
              <label
                htmlFor="uploadFile"
                className={css`
                  padding-top: 10px;
                  display: block;
                  opacity: 0.8;
                `}
              >
                Last opp undervisningsmateriell. Husk å trykke på “Last opp” når
                du har lagt til filer.
              </label>
            </div>
            <div
              className={css`
                margin-bottom: 8px;
              `}
            >
              <ul
                className={css`
                  margin-bottom: 10px;
                  max-height: 300px;
                  overflow-y: auto;
                `}
              >
                {chosenFiles.map((file, index: number) => (
                  <FileItem
                    file={file}
                    index={index}
                    key={`${file.name}-${index}`}
                    deleteFile={handleDeleteFile}
                  />
                ))}
              </ul>
            </div>
            <div
              className={css`
                margin-top: 30px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  align-items: flex-end;
                `}
              >
                <div
                  className={css`
                    color: ${theme.colors2.cta.tertiary};
                    width: 100%;
                  `}
                >
                  <input
                    type="text"
                    id="uploadLink"
                    onChange={(event) => setLink(event.target.value)}
                    value={link}
                    placeholder="... eller så du kan legge til en lenke her"
                    className={css`
                      height: 70px;
                      font-size: 24px;
                      padding: 0 24px;
                      color: ${theme.colors2.cta.tertiary};
                    `}
                  />
                </div>
                {link && (
                  <button
                    onClick={() => {
                      handleAddLink(link);
                      setLink("");
                    }}
                    disabled={!link}
                    className={css`
                      align-items: center;
                      background-color: ${theme.colors.base.background
                        .secondary};
                      border-radius: 4px;
                      color: ${theme.colors.upload.primary};
                      height: 60px;
                      margin-left: 24px;
                      width: 140px;

                      &:disabled {
                        opacity: 0.4;
                        cursor: not-allowed;
                      }

                      svg {
                        margin-left: 8px;
                      }
                    `}
                  >
                    Legg til
                    <ArrowCorner
                      size={16}
                      color={theme.colors.upload.primary}
                    />
                  </button>
                )}
              </div>
              <label
                htmlFor="uploadLink"
                className={css`
                  padding-top: 10px;
                  display: block;
                  opacity: 0.8;
                `}
              >
                Legg til lenker. Husk å trykke på “Legg til” når du har skrevet
                inn url’en!
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const InnerDropZone: FC<{
  isDragActive: boolean;
  id: string;
  className?: string;
  onDrop: (acceptedFile: any) => void;
}> = ({ isDragActive, id, className, onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
  });
  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className={cx(
        css`
          width: 100%;
          padding: 0 24px;
          height: 70px;
          background-color: ${theme.colors.base.background.primary};
          display: flex;
          align-items: center;
          cursor: pointer;
        `,
        className
      )}
    >
      <input {...getInputProps()} id={id} />
      <p
        className={css`
          font-size: 24px;
          padding-top: 2px;
          color: #757575;
        `}
      >
        {isDragActive ? "Slipp for å legge til" : "Velg eller dra filer hit"}
      </p>
    </div>
  );
};
