import React, { FC } from "react";
import { css, cx } from "emotion";
import theme from "../../utils/theme";

export const Chevron: FC<{
  size?: number;
  color?: string;
  className?: string;
  direction?: "UP" | "DOWN" | "LEFT" | "RIGHT";
}> = ({ size, color, direction, className }) => (
  <svg
    width={(size && size * 1.75) || 14}
    height={size || 8}
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(
      css`
        transform: rotate(${direction && directionToDeg[direction]}deg);
      `,
      className
    )}
  >
    <path
      d="M1 1L7 7L13 1"
      stroke={theme.colors2.cta.primary}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const directionToDeg = {
  UP: 0,
  DOWN: 180,
  LEFT: 270,
  RIGHT: 90,
};
