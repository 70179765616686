import React, { FC } from "react";
import theme from "../../utils/theme";

export const Arrow: FC<{
  size?: number | string;
  color?: string;
  direction?: "UP" | "DOWN" | "LEFT" | "RIGHT";
  style?: any;
  className?: string;
}> = ({ size, color, direction, style, className }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      transform: `rotate(${direction && directionToDeg[direction]}deg)`,
      ...style,
    }}
    className={className}
  >
    <g transform="matrix(1.59647,0,0,1.59647,-7.15765,-7.15765)">
      <path
        d="M5,12L19,12"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: color || theme.colors.base.background.secondary,
          strokeWidth: 1,
        }}
      />
    </g>
    <g transform="matrix(1.59647,0,0,1.59647,-7.15765,-7.15765)">
      <path
        d="M12,5L19,12L12,19"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: color || theme.colors.base.background.secondary,
          strokeWidth: 1,
        }}
      />
    </g>
  </svg>
);

const directionToDeg = {
  UP: 270,
  DOWN: 90,
  LEFT: 180,
  RIGHT: 0,
};

export const ArrowCorner: FC<{
  size?: number | string;
  direction?: "UP" | "DOWN" | "LEFT" | "RIGHT";
  color?: string;
  style?: any;
  className?: string;
}> = ({ size, direction, color, style, className }) => (
  <svg
    width={size || 30}
    height={size || 30}
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      transform: `rotate(${direction && directionToDeg[direction] + 90}deg)`,
      ...style,
    }}
    className={className}
  >
    <g>
      <g transform="matrix(1.42803,0,0,1.42803,-6.42049,-6.41731)">
        <path
          d="M12.5,11.25L18.75,5L25,11.25"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: color || theme.colors.base.background.secondary,
            strokeWidth: 1,
          }}
        />
      </g>
      <g transform="matrix(1.42803,0,0,1.42835,-6.42049,-6.42525)">
        <path
          d="M5,25L13.75,25C15.076,25 16.348,24.473 17.286,23.536C18.223,22.598 18.75,21.326 18.75,20L18.75,5"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: color || theme.colors.base.background.secondary,
            strokeWidth: 1,
          }}
        />
      </g>
    </g>
  </svg>
);
