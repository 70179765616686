import React, { FC } from "react";
import { PostFile } from "../pages/home/Home";

export const MultiDownloadButton: FC<{
  files: PostFile[];
  className?: string;
}> = ({ files, className, children }) => {
  const download = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    files.forEach(async (file) => {
      const a = document.createElement("a");
      a.download = file.filename;
      a.href = `/api/file/${file.sha512hash}`;
      a.style.display = "none";
      document.body.append(a);
      a.click();

      // Chrome requires the timeout
      await new Promise((resolve) => setTimeout(resolve, 100));
      a.remove();
    });
  };

  return (
    <button onClick={download} className={className}>
      {children}
    </button>
  );
};
