import React, { FC, useContext, createContext, useMemo, useState } from "react";
import { useDataApi } from "../utils/useDataApi";
import { LogInOrSignUp } from "../components/modals/signUpInModal/LogInOrSignUp";

export const UserContext = createContext<UserContextType>({
  loading: true,
  isLoggedIn: false,
  user: undefined,
  showLogInOrSignUp: () => {},
});

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider: FC = ({ children }) => {
  const [user] = useDataApi<User>("/api/me");
  const [showModal, setShowModal] = useState<"LOG_IN" | "SIGN_UP" | undefined>(
    undefined
  );
  const [logInSignUpMessage, setLogInSignUpMessage] = useState<string>("");

  const handleShowLoginOrSignUpModal = (
    type: "LOG_IN" | "SIGN_UP" | undefined,
    modalMessage?: string
  ) => {
    setShowModal(type);
    setLogInSignUpMessage(modalMessage || "");
  };

  const providedValue = useMemo(() => {
    return {
      loading: user.isLoading,
      isLoggedIn: Boolean(user.data),
      user: user.data,
      showLogInOrSignUp: handleShowLoginOrSignUpModal,
    };
  }, [user.data, user.isLoading]);

  return (
    <UserContext.Provider value={providedValue}>
      {children}
      <LogInOrSignUp
        showModal={showModal}
        setShowModal={setShowModal}
        message={logInSignUpMessage}
      />
    </UserContext.Provider>
  );
};

type User = {
  id: number;
  name: string;
  admin?: boolean;
};

type UserContextState = {
  loading: boolean;
  isLoggedIn: boolean;
  user?: User;
};

type UserContextType = UserContextState & {
  showLogInOrSignUp: (
    type: "LOG_IN" | "SIGN_UP" | undefined,
    modalMessage?: string
  ) => void;
};
