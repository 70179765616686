import React, { FC } from "react";
import theme from "../../utils/theme";

export const Apple: FC<{
  filled?: boolean;
  size?: number;
  strokeColor?: string;
  fillColor?: string;
  className?: string;
}> = ({ filled, fillColor, size, strokeColor, className }) => (
  <svg
    width={(size && size * 1.05) || 22}
    height={size || 21}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11 7H11.2831L11.4287 6.75725C11.8552 6.04649 13.406 4.5 16 4.5C17.5613 4.5 18.795 5.2732 19.6556 6.47812C20.5235 7.69317 21 9.3375 21 11C21 13.2932 19.3715 15.7944 17.4709 17.5724C16.5301 18.4525 15.5606 19.1184 14.759 19.4723C14.3566 19.6499 14.0227 19.7372 13.7711 19.7496C13.5214 19.762 13.4075 19.7004 13.3536 19.6464C12.7387 19.0316 12.2565 18.6346 11.8947 18.3872C11.714 18.2637 11.5571 18.1733 11.4236 18.1116C11.308 18.0583 11.1549 18 11 18H10.5C10.3451 18 10.192 18.0583 10.0764 18.1116C9.94289 18.1733 9.78599 18.2637 9.60534 18.3872C9.24349 18.6346 8.76129 19.0316 8.14645 19.6464C8.09247 19.7004 7.97862 19.762 7.72885 19.7496C7.47729 19.7372 7.14345 19.6499 6.74098 19.4723C5.93942 19.1184 4.96993 18.4525 4.02908 17.5724C2.12853 15.7944 0.5 13.2932 0.5 11C0.5 9.3375 0.976477 7.69317 1.84437 6.47812C2.70502 5.2732 3.93867 4.5 5.5 4.5C8.09398 4.5 9.6448 6.04649 10.0713 6.75725L10.2169 7H10.5H11Z"
      fill={
        filled ? fillColor || theme.colors.base.colors.orange.secondary : "none"
      }
      stroke={
        filled ? "none" : strokeColor || theme.colors.base.background.secondary
      }
    />
    <path
      d="M10.5 7C11 5.83333 11.5 5.2 11.5 4C11.5 2.8 10.5 1.5 10 1"
      stroke={strokeColor || theme.colors.base.background.secondary}
      strokeLinecap="round"
    />
  </svg>
);
