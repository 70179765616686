import { css, cx } from "emotion";
import React, { FC } from "react";
import theme from "../../../utils/theme";
import { grade } from "../../Tags";

export const GradeTag: FC<{ tags: string[]; className?: string }> = ({
  tags,
  className,
}) => {
  const gradesGroup = getGradeFromTags(tags);

  return (
    <div
      className={cx(
        css`
          display: flex;
        `,
        className
      )}
    >
      {gradesGroup.map((grades) => (
        <span
          key={grades.grades}
          className={css`
            min-width: 20px;
            border-radius: 20px;
            padding: 3px 6px;
            margin-right: 3px;
            font-size: 12px;
            background-color: ${grades.color};
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {grades.grades}
        </span>
      ))}
    </div>
  );
};

function getGradeFromTags(tags: string[]): { grades: string; color: string }[] {
  const gradeTags = tags.filter((tag) => grade.includes(tag));
  let sortedGradeTags = Array(grade.length);

  gradeTags.forEach((tag) => {
    const gradeIndex = grade.findIndex((g) => g === tag);

    if (gradeIndex > -1) {
      sortedGradeTags[gradeIndex] = tag;
    }
  });

  sortedGradeTags = sortedGradeTags.filter(Boolean);
  const gradeNumbers = sortedGradeTags.map((tag) => tag.split(" trinn")[0]);

  const gradesGroupedTogether = gradeNumbers.reduce((acc, cur) => {
    if (cur) {
      if (acc.length === 0) {
        acc.push(cur);
      } else {
        const prevValue = gradeToValue[acc[acc.length - 1]];
        const curValue = gradeToValue[cur];

        if (
          curValue.score - prevValue.score === 1 &&
          curValue.bundle === prevValue.bundle
        ) {
          acc.push("-");
          acc.push(cur);
        } else {
          acc.push("+");
          acc.push(cur);
        }
      }
    }

    return acc;
  }, []);

  const gradesGroupedWithColor = gradesGroupedTogether
    .join()
    .split("+")
    .map((g: string) => {
      const tg = g.split("-");
      const firstGrade = tg[0] ? tg[0].replace(/,/g, "") : null;
      const gradeBundle: ThemeColorTypes | undefined =
        firstGrade && gradeToValue[firstGrade]?.bundle;

      if (firstGrade && gradeBundle) {
        if (tg.length > 1) {
          return {
            grades: `${tg[0]}-${tg[tg.length - 1]}`.replace(/,/g, ""),
            color: theme.colors.tags[gradeBundle],
          };
        } else {
          return {
            grades: tg[0].replace(/,/g, ""),
            color: theme.colors.tags[gradeBundle],
          };
        }
      } else {
        return {
          grades: "",
          color: "",
        };
      }
    });

  return gradesGroupedWithColor;
}

type ThemeColorTypes =
  | "grade1to4"
  | "grade5to7"
  | "grade8to10"
  | "gradeVG1toVG3";

const gradeToValue: any = {
  "1.": {
    score: 1,
    bundle: "grade1to4",
  },
  "2.": {
    score: 2,
    bundle: "grade1to4",
  },
  "3.": {
    score: 3,
    bundle: "grade1to4",
  },
  "4.": {
    score: 4,
    bundle: "grade1to4",
  },
  "5.": {
    score: 5,
    bundle: "grade5to7",
  },
  "6.": {
    score: 6,
    bundle: "grade5to7",
  },
  "7.": {
    score: 7,
    bundle: "grade5to7",
  },
  "8.": {
    score: 8,
    bundle: "grade8to10",
  },
  "9.": {
    score: 9,
    bundle: "grade8to10",
  },
  "10.": {
    score: 10,
    bundle: "grade8to10",
  },
  VG1: {
    score: 11,
    bundle: "gradeVG1toVG3",
  },
  VG2: {
    score: 12,
    bundle: "gradeVG1toVG3",
  },
  VG3: {
    score: 13,
    bundle: "gradeVG1toVG3",
  },
};
