import React, { FC } from "react";
import { PostData } from "../home/Home";
import { AdminCard } from "./AdminCard";

export const AllPosts: FC<{
  filter: string;
  posts: PostData[];
  refetchPosts: () => void;
}> = ({ posts, filter, refetchPosts }) => {
  return (
    <div>
      {posts
        .filter((post) =>
          post.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        )
        .map((post) => (
          <AdminCard post={post} key={post.id} refetchPosts={refetchPosts} />
        ))}
    </div>
  );
};
