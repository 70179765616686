import axios from "axios";
import React, { FC, useCallback, useState } from "react";
import { PostData } from "../../pages/home/Home";
import { PostAsModal } from "../../pages/post/Post";
import { HorizontalCard } from "./HorizontalCard";
import { VerticalCard } from "./VerticalCard";
import { useUserContext } from "../../lib/UserContext";

export const Card: FC<{
  mode: "VERTICAL" | "HORIZONTAL";
  post: PostData;
  disableOnClick?: boolean;
  refetchPosts?: () => void;
  searchContext?: string;
}> = ({ mode, post, disableOnClick, refetchPosts, searchContext }) => {
  const [showPostModal, setShowPostModal] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(post.liked);
  const [bookmarked, setBookmarked] = useState<boolean>(post.bookmarked);
  const { isLoggedIn, showLogInOrSignUp } = useUserContext();

  const handleLikeClick = useCallback(async () => {
    if (isLoggedIn) {
      const initialLiked = liked;
      setLiked(!initialLiked);

      if (initialLiked) {
        try {
          await axios.delete(`/api/like/${post.id}`);
        } catch (error) {
          setLiked(initialLiked);
          console.error(error);
        }
      } else {
        try {
          await axios.post(`/api/like/${post.id}`);
        } catch (error) {
          setLiked(initialLiked);
          console.error(error);
        }
      }
      refetchPosts && refetchPosts();
    } else {
      showLogInOrSignUp("LOG_IN", "Hei! For å gi epler må du være logget inn.");
    }
  }, [isLoggedIn, liked, post, refetchPosts, showLogInOrSignUp]);

  const handleBookmarkedClick = useCallback(async () => {
    if (isLoggedIn) {
      const initialBookmarked = bookmarked;
      setBookmarked(!initialBookmarked);

      if (initialBookmarked) {
        try {
          await axios.delete(`/api/bookmark/${post.id}`);
        } catch (error) {
          setBookmarked(initialBookmarked);
          console.error(error);
        }
      } else {
        try {
          await axios.post(`/api/bookmark/${post.id}`);
        } catch (error) {
          setBookmarked(initialBookmarked);
          console.error(error);
        }
      }
      refetchPosts && refetchPosts();
    } else {
      showLogInOrSignUp(
        "LOG_IN",
        "Hei! For å bokmerke noe må du være logget inn."
      );
    }
  }, [isLoggedIn, bookmarked, post, refetchPosts, showLogInOrSignUp]);

  if (mode === "HORIZONTAL") {
    return (
      <>
        {showPostModal && (
          <PostAsModal
            post={{
              ...post,
              liked,
              bookmarked,
            }}
            refetchPost={refetchPosts || (() => {})}
            handleCloseModal={() => setShowPostModal(false)}
            searchContext={searchContext}
          />
        )}
        <HorizontalCard
          post={{
            ...post,
            liked,
            bookmarked,
          }}
          isLoggedIn={isLoggedIn}
          handleLikeClick={handleLikeClick}
          handleBookmarkedClick={handleBookmarkedClick}
          disableOnClick={disableOnClick}
          handleOpenModalClick={
            disableOnClick ? () => {} : () => setShowPostModal(true)
          }
        />
      </>
    );
  }

  return (
    <>
      {showPostModal && (
        <PostAsModal
          post={{
            ...post,
            liked,
            bookmarked,
          }}
          refetchPost={refetchPosts || (() => {})}
          handleCloseModal={() => setShowPostModal(false)}
          searchContext={searchContext}
        />
      )}
      <VerticalCard
        post={{
          ...post,
          liked,
          bookmarked,
        }}
        isLoggedIn={isLoggedIn}
        handleLikeClick={handleLikeClick}
        handleBookmarkedClick={handleBookmarkedClick}
        disableOnClick={disableOnClick}
        handleOpenModalClick={
          disableOnClick ? () => {} : () => setShowPostModal(true)
        }
      />
    </>
  );
};
