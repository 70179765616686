import { css, cx } from "emotion";
import React, { FC } from "react";
import { PostData } from "../../pages/home/Home";
import theme from "../../utils/theme";
import { TextLink } from "../Buttons";
import { CopyDataButton } from "../CopyDataButton";
import { Apple } from "../icons/Apple";
import { ArrowCorner } from "../icons/Arrow";
import bookImg from "../icons/book.svg";
import { Bookmark } from "../icons/Bookmark";
import share from "../icons/share.svg";
import { MultiDownloadButton } from "../MultiDownloadButton";
import { PostMenu } from "../PostMenu";
import { TagsAsBobbles } from "../Tags";
import { GradeTag } from "./components/GradeTag";

export const HorizontalCard: FC<{
  post: PostData;
  isLoggedIn: boolean;
  disableOnClick?: boolean;
  handleOpenModalClick: () => void;
  handleBookmarkedClick: () => void;
  handleLikeClick: () => void;
}> = ({
  post,
  isLoggedIn,

  disableOnClick,

  handleOpenModalClick,
  handleLikeClick,
  handleBookmarkedClick,
}) => (
  <div
    className={cx(
      css`
        width: 100%;
        height: auto;
        background-color: ${theme.colors.base.background.primary};
        padding: 20px;
        margin-bottom: 8px;
        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-template-areas: "grade title tagsAndName likesDownloadsAndMenu actions download";
        grid-template-columns: minmax(auto, 100px) 3fr 3fr 1fr 1fr 1fr;
        border-radius: 2px;
        cursor: initial;

        @media screen and (max-width: 1100px) {
          grid-template-areas:
            "grade likesDownloadsAndMenu"
            "title title"
            "tagsAndName tagsAndName"
            "actions download";
          grid-template-rows: repeat(4, auto);
          grid-template-columns: 1fr 1fr;
          grid-gap: 8px;
        }
      `,
      !disableOnClick &&
        css`
          cursor: pointer;
          &:hover {
            box-shadow: ${theme.shadows.small};
          }
        `,
      post.user.special &&
        css`
          border: 1.5px solid #14376e;
        `
    )}
    onClick={handleOpenModalClick}
  >
    <GradeTag
      tags={post.tags}
      className={css`
        grid-area: grade;
        font-weight: 400;
        margin-right: 10px;
        flex-wrap: wrap;

        span {
          margin-bottom: 3px;
        }
      `}
    />
    <h2
      className={css`
        grid-area: title;
        font-size: 18px;

        @media screen and (max-width: 1100px) {
          grid-column: 1 / -1;
        }
      `}
    >
      {post.title}
    </h2>
    <div
      className={css`
        grid-area: tagsAndName;
        @media screen and (max-width: 1100px) {
          grid-column: 1 / -1;
        }
      `}
    >
      <TagsAsBobbles tags={post.tags} maxTags={6} />
      {post.user.special ? (
        <TextLink
          to="/"
          className={css`
            display: flex;
            align-items: center;
            color: ${theme.colors2.cta.tertiary};
            font-size: 14px;

            img {
              margin-right: 8px;
              width: 16px;
            }
          `}
        >
          <img src={bookImg} alt="" />
          {post.user.special}
        </TextLink>
      ) : (
        <div
          className={css`
            color: ${theme.colors.base.text.tertiary};
            font-size: 12px;
          `}
        >
          Lastet opp av {post.user.name}
        </div>
      )}
    </div>
    <div
      className={css`
        font-weight: 100;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-area: likesDownloadsAndMenu;

        @media screen and (max-width: 1100px) {
          grid-template-columns: repeat(3, auto);
          justify-self: end;
          grid-gap: 0;
        }
      `}
    >
      <div
        className={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
        `}
      >
        <Apple filled size={20} />
        <span
          className={css`
            margin-left: 8px;
            margin-right: 8px;
            padding-top: 2px;
          `}
        >
          {post.likes || 0}
        </span>
      </div>
      <div
        className={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
        `}
      >
        <ArrowCorner
          direction="DOWN"
          color={theme.colors.base.colors.pink.primary}
          size={20}
        />
        <span
          className={css`
            margin-left: 8px;
            padding-top: 2px;
          `}
        >
          {post.downloads}
        </span>
      </div>
      <PostMenu
        className={css`
          margin-left: 8px;
          @media screen and (min-width: 1100px) {
            display: none;
          }
        `}
        flagButton
        postId={post.id}
      >
        <MultiDownloadButton
          className={css`
            display: flex;
            align-items: center;

            svg {
              margin-left: 14px;
            }
          `}
          files={post.files}
        >
          Last ned <ArrowCorner direction="DOWN" size={20} />
        </MultiDownloadButton>
      </PostMenu>
    </div>
    <div
      className={css`
        display: flex;
        justify-content: center;
        grid-area: actions;

        @media screen and (max-width: 1100px) {
          justify-content: flex-start;
        }
      `}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleLikeClick();
        }}
        className={cx(
          css`
            &:hover {
              path {
                opacity: 0.5;
              }
              path:first-of-type {
                fill: ${theme.colors.base.background.secondary};
                stroke: none;
              }
            }
          `,
          !isLoggedIn &&
            css`
              path: {
                opacity: 0.5;
              }
            `
        )}
      >
        <Apple
          filled={post.liked}
          fillColor={theme.colors.base.background.secondary}
        />
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleBookmarkedClick();
        }}
        className={cx(
          css`
            &:hover {
              svg {
                fill: ${theme.colors.base.background.secondary};
                opacity: 0.5;
              }
            }
          `,
          !isLoggedIn &&
            css`
              svg {
                opacity: 0.5;
              }
            `
        )}
      >
        <Bookmark solid={post.bookmarked} />
      </button>
      <CopyDataButton data={`${window.location.origin}/p/${post.id}`}>
        <img src={share} alt="del" />
      </CopyDataButton>
    </div>
    <div
      className={css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        grid-area: download;
      `}
    >
      <PostMenu
        className={css`
          margin-left: 14px;
          @media screen and (max-width: 1100px) {
            display: none;
          }
        `}
        flagButton
        postId={post.id}
      >
        <MultiDownloadButton
          className={css`
            display: flex;
            align-items: center;

            svg {
              margin-left: 14px;
            }
          `}
          files={post.files}
        >
          Last ned <ArrowCorner direction="DOWN" size={20} />
        </MultiDownloadButton>
      </PostMenu>
    </div>
  </div>
);
