import {css} from "emotion";
import {Button} from "../Buttons";
import {Modal} from "../modal/Modal";
import React, {FC} from "react";
import {RouteComponentProps} from "@reach/router";

export const AlertModal: FC<RouteComponentProps & {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({message, onConfirm, onCancel}) => (
  <Modal
    closeModal={onCancel}
    modalSize={{
      width: "555px",
      height: "263px",
    }}
    backgroundColor='#F5F5F5'
  >
    <div className={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 100%;
      width: 100%;
    `}>
      <div className={css`
        font-size: 24px;
      `}>
        {message}
      </div>
      <div className={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        > * {
          margin: 5px
        }
      `}>
        <Button onClick={onConfirm}>Ja, slett</Button>
        <Button
          secondary={true}
          onClick={onCancel}
        >
          Avbryt
        </Button>
      </div>
    </div>
  </Modal>
);
