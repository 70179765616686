import Axios from "axios";
import { css, cx } from "emotion";
import React, { FC, useRef, useState } from "react";
import { useOutsideClick } from "../utils/hooks";
import theme from "../utils/theme";
import { Alert } from "./Alert";
import flagImg from "./icons/flag.svg";
import kebabMenuImg from "./icons/kebabMenu.svg";
import { Modal } from "./modal/Modal";

export const PostMenu: FC<{
  flagButton?: boolean;
  postId?: number;
  className?: string;
  side?: "TOP" | "BOTTOM";
}> = ({ children, className, flagButton, postId, side }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [flagText, setFlagText] = useState<string>("");
  const [flagPostError, setFlagPostError] = useState<string>("");
  const [flagPostSuccess, setFlagPostSuccess] = useState<boolean>(false);
  const [showFlagModal, setShowFlagModal] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick(menuRef, () => setShowMenu(false));

  const handleFlagPostClick = () => {
    try {
      Axios.post("/api/flag", { post: postId, reason: flagText });
      setFlagPostSuccess(true);
      setShowFlagModal(false);
      setFlagText("");
      setFlagPostError("");
    } catch (error) {
      setFlagPostError(error.toString());
    }
  };

  return (
    <div
      className={cx(
        css`
          position: relative;
          width: 20px;
        `,
        className
      )}
    >
      {flagPostSuccess && (
        <Modal
          closeModal={() => setFlagPostSuccess(false)}
          autoDismiss={2000}
          modalSize={{ width: "400px", height: "300px" }}
        >
          <div
            className={css`
              margin-top: 50px;
              display: flex;
              justify-content: center;
              width: 100%;
              height: 100%;
            `}
          >
            <h1>Bidraget er nå rapportert.</h1>
          </div>
        </Modal>
      )}
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu((currentState) => !currentState);
        }}
      >
        <img src={kebabMenuImg} alt="meny" />
      </button>
      {showMenu && (
        <div
          ref={menuRef}
          onClick={(e) => e.stopPropagation()}
          className={cx(
            css`
              position: absolute;
              padding: 20px 0;
              border-radius: 2px;
              box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
              background-color: ${theme.colors.base.background.primary};
              hyphens: none;
              z-index: 1;

              img {
                margin-left: 16px;
              }

              button,
              a {
                padding: 10px 20px;
                border-radius: 2px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                &:hover {
                  background-color: ${theme.colors.base.background.tertiary};
                }
              }
            `,
            side === "TOP"
              ? css`
                  right: -20px;
                  bottom: 30px;

                  &::after {
                    content: "";
                    position: absolute;
                    right: 20px;
                    bottom: -10px;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid
                      ${theme.colors.base.background.primary};
                  }
                `
              : css`
                  right: -20px;
                  top: 30px;

                  &::after {
                    content: "";
                    position: absolute;
                    right: 20px;
                    top: -10px;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid
                      ${theme.colors.base.background.primary};
                  }
                `
          )}
        >
          {flagButton && (
            <button
              onClick={() => {
                setShowFlagModal(true);
                setShowMenu(false);
              }}
            >
              Rapporter <img src={flagImg} alt="" />
            </button>
          )}
          {children}
        </div>
      )}

      {showFlagModal && (
        <Modal
          closeModal={() => setShowFlagModal(false)}
          modalSize={{ width: "790px", height: "500px" }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
            `}
          >
            <h1>Hvorfor ønsker du å rapportere dette bidraget?</h1>
            <label htmlFor="flag-reason" className="sr-only">
              Tilbakemelding
            </label>
            <textarea
              id="flag-reason"
              value={flagText}
              onChange={(e) => setFlagText(e.target.value)}
              placeholder="Skriv en tilbakemelding på hva som er feil her, så tar vi tak i det."
              className={css`
                margin-top: 50px;
                width: 520px;
                height: 150px;
                background-color: ${theme.colors.base.background.tertiary};

                @media screen and (max-width: 650px) {
                  width: 100%;
                }
              `}
            />
            {flagPostError && (
              <Alert
                level="ERROR"
                className={css`
                  margin-top: 20px;
                `}
              >
                {flagPostError}
              </Alert>
            )}
            <div
              className={css`
                display: flex;
                flex: 1;
                align-items: flex-end;

                @media screen and (max-width: 650px) {
                  flex-direction: column;
                  align-items: center;
                  justify-content: flex-end;
                }
              `}
            >
              <button
                className={css`
                  width: 250px;
                  height: 60px;
                  background-color: ${theme.colors.base.background.secondary};
                  color: ${theme.colors.base.text.secondary};
                  border-radius: 4px;
                  &:disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                  }
                `}
                disabled={!flagText}
                onClick={handleFlagPostClick}
              >
                Rapporter bidrag
              </button>
              <button
                className={css`
                  width: 250px;
                  height: 60px;
                  border-radius: 4px;
                `}
                onClick={() => {
                  setFlagText("");
                  setShowFlagModal(false);
                }}
              >
                Avbryt
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
