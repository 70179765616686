import React, { FC, useEffect } from "react";
import { css } from "emotion";
import { RouteComponentProps } from "@reach/router";

export const TermsOfUse: FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div
      className={css`
        max-width: 1330px;
        margin: 0 auto;
        margin-top: 180px;
        display: flex;

        @media screen and (max-width: 1500px) {
          width: 90vw;
        }

        @media screen and (max-width: 1100px) {
          flex-direction: column;
        }
      `}
    >
      <div
        className={css`
          margin-right: 50px;

          * {
            hyphens: none;
            white-space: nowrap;
          }

          @media screen and (max-width: 1100px) {
            * {
              white-space: pre-wrap;
            }
          }

          ul {
            @media screen and (min-width: 1100px) {
              position: sticky;
              top: 20px;
            }
            margin-bottom: 30px;
            li {
              font-weight: 500;
              padding-top: 10px;
              padding-bottom: 10px;
              font-size: 18px;

              &:hover {
                text-decoration: underline;
              }
            }

            ul {
              li {
                font-weight: 400;
                margin-left: 20px;
              }
            }
          }
        `}
      >
        <ul>
          <li>
            <a href="#retningslinjer-for-bruk-av-tjenesten">
              Retningslinjer for bruk av tjenesten
            </a>
          </li>
          <li>
            <a href="#personvern">Personvern</a>
          </li>
          <li>
            <a href="#hvilke-personopplysninger-samler-vi-inn">
              Hvilke personopplysninger samler vi inn?
            </a>
          </li>
          <ul>
            <li>
              <a href="#persondata">Persondata</a>
            </li>
            <li>
              <a href="#undervisningsopplegg">Undervisningsopplegg</a>
            </li>
            <li>
              <a href="#informasjonskapsler">Informasjonskapsler</a>
            </li>
            <li>
              <a href="#webanalyse">Webanalyse</a>
            </li>
          </ul>
          <li>
            <a href="#hvordan-vi-beskytter-dine-personopplysninger">
              Hvordan vi beskytter dine personopplysninger
            </a>
          </li>
          <li>
            <a href="#hvem-blir-dine-opplysninger-delt-med">
              Hvem blir dine opplysninger delt med?
            </a>
          </li>
          <li>
            <a href="#hva-er-dine-rettigheter-knyttet-til-informasjonen-vi-har-lagret">
              Hva er dine rettigheter knyttet til informasjonen vi har lagret?
            </a>
          </li>
          <li>
            <a href="#hvor-lenge-oppbevarer-vi-opplysningene">
              Hvor lenge oppbevarer vi opplysningene?
            </a>
          </li>
          <li>
            <a href="#vaare-rutiner-for-datalekkasjer">
              Våre rutiner for datalekkasjer
            </a>
          </li>
        </ul>
      </div>
      <div
        className={css`
          margin-bottom: 60px;

          h1 {
            margin-bottom: 30px;

            &:not(:first-of-type) {
              margin-top: 60px;
            }
          }

          h2 {
            margin-bottom: 30px;
            margin-top: 60px;
          }

          p:not(:first-of-type) {
            margin-top: 20px;
          }
        `}
      >
        <h1 id="retningslinjer-for-bruk-av-tjenesten">
          Retningslinjer for bruk av tjenesten
        </h1>
        <p>
          Velkommen til Addito! Tusen takk for at du vil bidra til å gjøre
          hverdagen enklere for tusenvis av lærere. Her møtes du av delingsglede
          og heiarop!
        </p>
        <p>
          For at vi skal ha et åpent og trygt fellesskap må alle registrere seg
          med fullt navn i profilen.
        </p>
        <p>
          Alt materiale skal være gratis og tilgjengelig for alle. Ingen lenker
          skal vise til materiale som ligger bak betalingsmur.
        </p>
        <p>
          Undervisningsopplegget du laster opp eller lenker til må være
          egenprodusert. Du som er forfatter av materialet anses som eier og kan
          når som helst slette eller endre innholdet.
        </p>
        <p>
          Ved å laste opp materiale tillater du andre å bruke dette i sin
          undervisning.
        </p>
        <p>
          Det skal være lett å finne det man søker etter. Derfor er det viktig
          at du merker dine opplegg med trinn og fag. Markerer du i tillegg med
          flere emneknagger gjør du det enda lettere for andre å finne
          opplegget. Administratorer i Addito kan i noen tilfeller endre på
          emneknagger som er brukt dersom det gjør det lettere å finne
          undervisningsopplegget ditt.
        </p>
        <p>
          Vi ønsker at flest mulig skal få glede av undervisningsoppleggene.
          Derfor kommer vi til å lagelister etter trinn og tema. Vi kommer også
          til å legge stor vekt på tverrfalige opplegg. Vedå laste opp opplegget
          på Addito aksepterer du at vi også kan vise frem det du har laget.
        </p>
        <p>
          Bilder av bruk av opplegget i praksis må ikke identifisere barn og
          elever.
        </p>
        <p>
          Undervisningsopplegg og øvrig materiale som lastes opp på plattformen
          skal ikke være av diskriminerende eller krenkende art. Det skal heller
          ikke lastes opp materiale som identifiserer individuelle
          privatpersoner og/eller elever, uten at berørte personer/foresatte har
          gitt sitt samtykke til dette. Materiale som faller inn under disse
          kategoriene vil bli slettet av administratorene på siden.
        </p>
        <h1 id="personvern">Personvern</h1>
        <p>
          Vi har i utviklingen og drift av siden et spesielt stort fokus på å
          sikre at dine personopplysninger blir behandlet i tråd med gjeldende
          lovverk i Norge. Vi har under listet opp hvilken informasjon vi samler
          inn som en del av tjenesten - og ikke minst hvordan vi benytter denne
          informasjonen. Et bærende prinsipp er at du selv eier dine egne data
          og undervisningsopplegg, og at du til enhver tid kan be om at dine
          data og din brukerprofil blir slettet. Informasjon om deg vil heller
          aldri bli videreformidlet til tredjeparter uten din eksplisitte
          samtykke.
        </p>

        <h1 id="hvilke-personopplysninger-samler-vi-inn">
          Hvilke personopplysninger samler vi inn?
        </h1>
        <h2 id="persondata">Persondata</h2>
        <p>
          Når du oppretter en brukerprofil eller logger deg på med din
          Google-konto første gang, vil vi hente noe informasjon om deg som
          bruker. Denne informasjonen benytter vi for å sikre at
          undervisningsopplegg du laster opp selv, eller opplegg du gir epler
          eller bokmerker kan registreres til deg som bruker. Dette gjør at du
          enkelt kan gjenfinne denne informasjonen når du skal benytte tjenesten
          neste gang. Informasjonen som blir opprettet på tjenesten når du
          første gang registrerer eller logger deg på siden, er 1{")"} din
          gmail-adresse, 2{")"} ditt navn og 3{")"} din Google ID {"("}en
          numerisk verdi{")"}.
        </p>

        <h2 id="undervisningsopplegg">Undervisningsopplegg</h2>
        <p>
          Når du laster opp et undervisningsopplegg på siden, vil de aktuelle
          filene bli lagret hos oss - i tillegg til din beskrivelse av
          opplegget, tittelen til opplegget og de emneknagger du velger under
          opplastingen. Vi lagrer disse filene hos oss, slik at de enkelt kan
          lastes ned av andre som ønsker å benytte de i sin læring.
        </p>

        <h2 id="informasjonskapsler">Informasjonskapsler</h2>
        <p>
          Tjenesten bruker informasjonskapsler for å heve brukeropplevelsen på
          siden, og gjør blant annet at informasjonsbokser ikke dukker opp flere
          ganger, personlige preferanser blir lagret samt at du kan forbli
          innlogget på siden dersom du ønsker dette. Den aktuelle
          informasjonskapselen lagres på enheten du benytter for å besøke
          tjenesten vår, og når du besøker siden for første gang.
        </p>
        <p>
          Dersom du ikke ønsker at det blir lagret en slik informasjonskapsel
          når du besøker tjenesten, kan du velge å besøke tjenesten i privat
          modus. Den aktuelle informasjonskapselen vil da ikke bli lagret, men
          det vil samtidig føre til at du ikke vil være innlogget på siden, og
          at opplasting ikke vil fungere samt at du ikke vil kunne bokmerke
          eller gi epler til andre sine undervisningsopplegg.
        </p>

        <h2 id="webanalyse">Webanalyse</h2>
        <p>
          Vi benytter webanalyseverktøyet Matomo for å hente inn informasjon om
          bruksmøntre for vår tjeneste, en anerkjent løsning med høyt fokus på
          personvern. Den aktuelle løsningen driftes som en privat skyløsning,
          og sikrer at tredjeparter og/eller kommersielle ikke får tilgang til
          de aktuelle bruksmønstrene. Informasjonen som hentes inn gjennom
          webanalyseverktøyet inkluderer blant annet hvor mange som har besøkt
          siden, hvor besøkende har kommet frem samt hvilke sider som blir mest
          besøkt. Den aktuelle informasjonen innhentes som underlagsmateriale
          for å gjøre tjenesten vår bedre, og sikrer at vi raskt kan agere og
          gjøre forbedringer hvis eksempelvis veldig mange velger å forlate
          siden på spesifikke sider.
        </p>

        <h1 id="hvordan-vi-beskytter-dine-personopplysninger">
          Hvordan vi beskytter dine personopplysninger
        </h1>
        <p>
          Din personlige identifiserbare informasjon behandles i henhold til
          beste praksis for informasjonssikkerhet og beskyttelse av informasjon.
          Dersom du ønsker mer informasjon om denne praksisen eller våre rutiner
          og prosesser knyttet til databehandling, er du velkomment til å ta
          kontakt med oss.
        </p>

        <h1 id="hvem-blir-dine-opplysninger-delt-med">
          Hvem blir dine opplysninger delt med?
        </h1>
        <p>
          Informasjonen vi innhenter vil aldri bli delt med tredjeparter uten
          ditt samtykke.
        </p>
        <p>
          Som et ledd i opprettelse av din brukerprofil, vil vi hente inn din
          mailadresse fra Google. Denne vil imidlertid kun være synlig for
          administratorer på siden. Denne vil da også kun bli benyttet for å
          kontakte deg dersom det er aspekter ved de undervisningsopplegg du har
          lastet opp vi ønsker å diskutere med deg. Eksempler på slike aspekter
          kan være at vi ønsker å bekrefte med deg at vi kan gjøre justeringer
          på undervisningsopplegget - eksempelvis legge til emneknagger - dersom
          vi tenker at dette vil bedre kvaliteten på undervisningsopplegget.
        </p>

        <h1 id="hva-er-dine-rettigheter-knyttet-til-informasjonen-vi-har-lagret">
          Hva er dine rettigheter knyttet til informasjonen vi har lagret?
        </h1>
        <p>
          Du kan på hvilket som helst tidspunkt be om å få tilsendt alle
          personopplysningene vi har om deg. Dette omfatter all data du har gitt
          oss. Du kan også be om at vi sletter alle personopplysninger vi har om
          deg.
        </p>
        <p>
          Du kan også be om innsyn, endring eller sletting av din brukerprofil.
          Vi vil ved en slik henvendelse sikre at all informasjon vi har lagret
          på deg vil bli slettet fra våre databaser.
        </p>

        <h1 id="hvor-lenge-oppbevarer-vi-opplysningene">
          Hvor lenge oppbevarer vi opplysningene?
        </h1>
        <p>
          Informasjon om deg som bruker, dine aktiviteter på siden {"("}
          inkludert bokmerker{")"} samt dine undervisningsopplegg vil bli lagret
          på siden så lenge du har en aktiv brukerprofil hos oss, eller at du
          selv 1{")"} selv sletter de aktuelle opplysninger eller 2{")"} sender
          en henvendelse til
          <a href="mailto:kontakt@addito.no">kontakt@addito.no</a> og ber om at
          vi sletter denne informasjonen på dine vegne.
        </p>

        <h1 id="vaare-rutiner-for-datalekkasjer">
          Våre rutiner for datalekkasjer
        </h1>
        <p>
          Oppstår datalekkasjer vil dette bli håndtert så raskt som mulig av vår
          organisasjon og eventuelle samarbeidspartnere. Relevante myndigheter
          vil bli kontaktet i henhold til gjeldende GDPR-retningslinjer.
        </p>
      </div>
    </div>
  );
};
