export const colors2 = {
  cta: {
    primary: "#33140c",
    secondary: "#ffffff",
    tertiary: "#2057d6",
  },
  base: {
    primary: "#33140c",
    secondary: "#ffffff",
    tertiary: "#fafafa",
  },
  accent: {
    primary: "#ff653a",
    secondary: "#fe9051",
    search: "#14376e",
    upload: "#ffbb00",
    edit: "#145e6e",
  },
  background: {
    primary: "#fafafa",
    secondary: "#ffffff",
    search: "#d7f5f6",
    upload: "#fff0c8",
    myPage: "#f1faf9",
    admin: "#fafafa",
    content: "#ececec",
  },
  tags: {
    grade1to4: "#fde491",
    grade5to7: "#f9aa91",
    grade8to10: "#f89234",
    gradeVG1toVG3: "#9BB0E2",
    topic: "#afdfc0",
    coreTopic: "#d7efdf",
    primary: "#ffffff",
    selected: "#33140c",
  },
  text: {
    primary: "#33140c",
    secondary: "#ffffff",
    tertiary: "#808080",
  },
};

const colors = {
  base: {
    background: {
      primary: "#ffffff",
      secondary: "#33140c",
      tertiary: "#fafafa",
      quaternary: "#f3f3f3",
    },
    text: {
      primary: "#33140c",
      secondary: "#ffffff",
      tertiary: "#808080",
    },
    colors: {
      orange: {
        primary: "#f6653a",
        secondary: "#fe9051",
      },
      yellow: {
        primary: "#fde566",
        secondary: "#feefa4",
      },
      green: {
        primary: "#2e9379",
        secondary: "#afdfc0",
      },
      pink: {
        primary: "#f9aa91",
      },
    },
    alert: {
      success: {
        text: "#1e4620",
        background: "#edf7ed",
      },
      warning: {
        text: "#663c00",
        background: "#fef4e5",
      },
      error: {
        text: "#611a15",
        background: "#fdecea",
      },
      info: {
        text: "#0d3c61",
        background: "#e8f4fe",
      },
    },
  },
  tags: {
    grade1to4: "#fde491",
    grade5to7: "#f9aa91",
    grade8to10: "#f89234",
    gradeVG1toVG3: "#9BB0E2",
    green: "#afdfc0",
  },
  search: {
    primary: "#d7f5f6",
    secondary: "#14376e",
    tertiary: "#c9f2f5",
    quaternary: "#e8f7f8",
  },
  upload: {
    primary: "#fef0c8",
    secondary: "#fabb02",
  },
  myPage: {
    primary: "#f1faf9",
    secondary: "#195e6e",
  },
};

const shadows = {
  small: "0px 0px 15px rgba(0, 0, 0, 0.15)",
  medium: "0px 20px 30px rgba(0, 0, 0, 0.15)",
  large: "0px -30px 60px rgba(0, 0, 0, 0.05)",
};

export default {
  colors,
  colors2,
  shadows,
};
