import { Redirect, RouteComponentProps, Router } from "@reach/router";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { UserContextProvider, useUserContext } from "./lib/UserContext";
import { Admin } from "./pages/admin/Admin";
import { Home } from "./pages/home/Home";
import { AboutUs } from "./pages/information/AboutUs";
import { TermsOfUse } from "./pages/information/TermsOfUse";
import { EditPost } from "./pages/myPage/EditPost";
import { MyPage } from "./pages/myPage/MyPage";
import { PageNotFound } from "./pages/PageNotFound";
import { Post } from "./pages/post/Post";
import { LogIn, SignUp } from "./pages/SignUpIn";
import { Upload } from "./pages/upload/Upload";

import "./reset.css";
import "./fonts.css";
import "./index.css";

export const App: FC = () => {
  return (
    <UserContextProvider>
      <Helmet>
        <title>Addito.no – En gratis delingsplattform for lærere</title>
      </Helmet>
      <Header />
      <Router>
        <Home path="/" />
        <Upload path="upload" />
        <Post path="p/:id" />
        <PrivateRoute as={MyPage} path="my-page" />
        <PrivateRoute as={EditPost} path="my-page/:id/edit" />
        <AdminRoute as={EditPost} path="admin/:id/edit" />
        <AdminRoute as={Admin} path="admin" />
        <TermsOfUse path="terms-of-use" />
        <AboutUs path="about-us" />
        <LogIn path="log-in" />
        <SignUp path="sign-up" />
        <PageNotFound default />
      </Router>
      <Footer />
    </UserContextProvider>
  );
};

const PrivateRoute = (props: RouteComponentProps & { as: Function }) => {
  const { isLoggedIn } = useUserContext();
  const { as: Comp, ...otherProps } = props;

  return isLoggedIn ? (
    <Comp {...otherProps} />
  ) : (
    <Redirect to="/log-in" noThrow={true} />
  );
};

const AdminRoute = (props: RouteComponentProps & { as: Function }) => {
  const { isLoggedIn, user } = useUserContext();
  const { as: Comp, ...otherProps } = props;

  return isLoggedIn && user?.admin ? (
    <Comp {...otherProps} />
  ) : (
    <PageNotFound />
  );
};
