import { Link } from "@reach/router";
import { css, cx } from "emotion";
import React, { FC } from "react";
import theme from "../utils/theme";
import {Apple} from "./icons/Apple";

export const Button: FC<{ className?: string; onClick: () => void; secondary?: boolean }> = ({
  className,
  children,
  onClick,
  secondary
}) => (
  <button
    className={cx(
      css`
        background-color: ${secondary ? 'transparent' : theme.colors2.cta.primary};
        color: ${secondary ? theme.colors2.cta.primary : theme.colors2.cta.secondary};
        max-height: 60px;
        border-radius: 4px;
        padding: 20px 60px;
        border: none;
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        outline: none;

        &:hover {
          background-color: ${secondary ? 'white' : '#5c433d'};
        }

        &:active {
          transform: scale(0.95);
        }

        &:disabled {
          cursor: not-allowed;
        }
      `,
      className
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

export const LinkButton: FC<{
  className?: string;
  to: string;
}> = ({ className, children, to }) => (
  <Link
    to={to}
    className={cx(
      css`
        background-color: ${theme.colors2.cta.primary};
        color: ${theme.colors2.text.secondary};
        max-height: 60px;
        border-radius: 4px;
        padding: 20px 60px;
        border: none;
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        outline: none;
        text-decoration: none;

        &:hover {
          background-color: #5c433d;
        }

        &:active {
          transform: scale(0.95);
        }

        &:disabled {
          cursor: not-allowed;
        }
      `,
      className
    )}
  >
    {children}
  </Link>
);

export const TextLink: FC<{
  className?: string;
  to: string;
}> = ({ className, children, to }) => (
  <Link
    to={to}
    className={cx(
      css`
        color: ${theme.colors2.text.primary};
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        outline: none;
        text-decoration: none;

        &:hover {
          color: ${theme.colors2.cta.tertiary};
        }

        &:active {
          color: ${theme.colors2.cta.tertiary};
        }
      `,
      className
    )}
  >
    {children}
  </Link>
);

export const LikeButton: FC<{
  isLoggedIn: boolean;
  onClick: () => void;
  isLiked: boolean;
  likes: number;
  strokeColor?: string;
  fillColor?: string;
  size?: number;
}> = ({isLoggedIn, onClick, isLiked, likes, strokeColor, fillColor, size}) => (
  <button
    onClick={onClick}
    className={cx(
      css`
        ${size ?  `font-size: ${size}px;` : ''}
        &:hover {
          path {
            opacity: 0.5;
          }
          path:first-of-type {
            fill: ${fillColor ? fillColor : theme.colors.base.colors.pink.primary};
            stroke: none;
          }
        }
      `,
      !isLoggedIn &&
      css`
        path {
          opacity: 0.5;
        }
      `
    )}
  >
    <Apple
      filled={isLiked}
      strokeColor={strokeColor ? strokeColor : theme.colors2.cta.tertiary}
      className={css`
        margin-right: 8px;
      `}
      size={size}
    />
    {likes}
  </button>
)
