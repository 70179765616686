import {RouteComponentProps} from "@reach/router";
import {css} from "emotion";
import React, {FC, useState} from "react";
import {Alert} from "../../components/Alert";
import {MagnifyingGlass} from "../../components/icons/MagnifyingGlass";
import theme from "../../utils/theme";
import {useDataApi} from "../../utils/useDataApi";
import {CommentFlag, PostData} from "../home/Home";
import {AllPosts} from "./AllPosts";
import {FlaggedPosts} from "./FlaggedPosts";
import {Users, UserType} from "./Users";
import {CommentFlagCard} from "./CommentFlagCard";

export const Admin: FC<RouteComponentProps> = () => {
  const [flaggedPostsRequest, refetchFlaggedPosts] = useDataApi<{
    flags: FlaggedPostType[];
  }>("/api/flag");
  const [postsRequest, refetchPosts] = useDataApi<{ posts: PostData[] }>(
    "/api/post"
  );
  const [usersRequest, refetchUsers] = useDataApi<{ users: UserType[] }>(
    "/api/user"
  );
  const [commentFlagsRequest, refetchCommentFlags] = useDataApi<{ commentFlags: CommentFlag[] }>(
    "/api/post/comment-flags"
  );

  const [mode, setMode] = useState<"ALL" | "FLAGS" | "USERS" | "COMMENT_FLAGS">("ALL");
  const [filter, setFilter] = useState<string>("");

  return (
    <div>
      <div
        className={css`
          height: 180px;
          background-color: ${theme.colors2.base.primary};
          display: flex;
        `}
      >
        <div
          className={css`
            max-width: 1330px;
            margin: 0 auto;
            color: ${theme.colors2.text.secondary};
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;

            @media screen and (max-width: 1550px) {
              padding-left: calc(2.5vw + 60px);
            }
          `}
        >
          <h2>Admin</h2>
        </div>
      </div>
      <div
        className={css`
          background-color: ${theme.colors2.background.admin};
          padding-bottom: 50px;
        `}
      >
        <div
          className={css`
            width: 90vw;
            max-width: 1330px;
            margin: 0 auto;
          `}
        >
          <div
            className={css`
              padding-top: 70px;
              padding-bottom: 30px;
              display: flex;
              justify-content: space-between;

              @media screen and (max-width: 700px) {
                flex-direction: column;
              }
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: center;
                align-items: center;
                width: 250px;
                padding: 8px;
                background: ${theme.colors2.cta.secondary};
                border-radius: 2px;
              `}
            >
              <MagnifyingGlass size={16} color={theme.colors2.cta.tertiary} />
              <input
                type="text"
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
                placeholder={`Filtrer etter ${
                  mode === "USERS" ? "navn" : "tittel"
                }`}
                className={css`
                  margin-left: 8px;
                  width: 100%;
                `}
              />
            </div>
            <div
              className={css`
                display: flex;
                align-items: flex-end;
                font-size: 18px;
                button {
                  font-weight: 500;

                  &:not(:last-of-type) {
                    margin-right: 20px;
                  }
                }
                .active {
                  font-weight: 300;
                  height: 18px;
                  border-bottom: 6px;
                  border-top: 0;
                  border-left: 0;
                  border-right: 0;
                  border-color: #afdfc0;
                  border-style: solid;
                }

                @media screen and (max-width: 700px) {
                  margin-top: 20px;
                }
              `}
            >
              <button
                onClick={() => setMode("ALL")}
                className={(mode === "ALL" && "active") || ""}
              >
                Alle bidrag
              </button>
              <button
                onClick={() => setMode("FLAGS")}
                className={(mode === "FLAGS" && "active") || ""}
              >
                Flaggede bidrag
              </button>
              <button
                onClick={() => setMode("COMMENT_FLAGS")}
                className={(mode === "COMMENT_FLAGS" && "active") || ""}
              >
                Flaggede kommentarer
              </button>
              <button
                onClick={() => setMode("USERS")}
                className={(mode === "USERS" && "active") || ""}
              >
                Bidragsytere
              </button>
            </div>
          </div>
          <div>
            {mode === "ALL" && (
              <div>
                {postsRequest.isLoading && "Laster..."}
                {postsRequest.error && (
                  <Alert level="ERROR">{postsRequest.error.toString()}</Alert>
                )}
                {postsRequest.data && (
                  <AllPosts
                    filter={filter}
                    posts={postsRequest.data.posts}
                    refetchPosts={refetchPosts}
                  />
                )}
              </div>
            )}
            {mode === "FLAGS" && (
              <div>
                {flaggedPostsRequest.isLoading && "Laster..."}
                {flaggedPostsRequest.error && (
                  <Alert level="ERROR">
                    {flaggedPostsRequest.error.toString()}
                  </Alert>
                )}
                {flaggedPostsRequest.data && (
                  <FlaggedPosts
                    filter={filter}
                    flaggedPosts={flaggedPostsRequest.data.flags}
                    refetchFlaggedPosts={refetchFlaggedPosts}
                  />
                )}
              </div>
            )}
            {mode === "USERS" && (
              <div>
                {usersRequest.isLoading && "Laster..."}
                {usersRequest.error && (
                  <Alert level="ERROR">{usersRequest.error.toString()}</Alert>
                )}
                {usersRequest.data && (
                  <Users
                    filter={filter}
                    users={usersRequest.data.users}
                    refetchUsers={refetchUsers}
                  />
                )}
              </div>
            )}
            {mode === "COMMENT_FLAGS" && (
              <div>
                {commentFlagsRequest.isLoading && "Laster..."}
                {commentFlagsRequest.error && (
                  <Alert level="ERROR">{usersRequest.error.toString()}</Alert>
                )}
                {commentFlagsRequest.data && (
                  commentFlagsRequest.data.commentFlags
                    .filter((commentFlag) =>
                      commentFlag.comment.text.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
                    )
                    .map(commentFlag => (
                      <CommentFlagCard commentFlag={commentFlag} refetchCommentFlags={refetchCommentFlags} filter={filter} />
                    ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export type BundledFlagPostType = {
  id: number;
  post: PostData;
  reasons: ReasonType[];
};

export type ReasonType = {
  id: number;
  message: string;
  date: string;
};

export type FlaggedPostType = {
  created: string;
  id: number;
  post: PostData;
  reason: string;
};
