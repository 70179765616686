import { RouteComponentProps, useNavigate, useLocation } from "@reach/router";
import axios from "axios";
import { css, cx } from "emotion";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { CopyDataButton } from "../../components/CopyDataButton";
import { Apple } from "../../components/icons/Apple";
import { ArrowCorner } from "../../components/icons/Arrow";
import { Bookmark } from "../../components/icons/Bookmark";
import bookImg from "../../components/icons/book.svg";
import fileImg from "../../components/icons/file.svg";
import externalLinkImg from "../../components/icons/externalLink.svg";
import { Modal } from "../../components/modal/Modal";
import { MultiDownloadButton } from "../../components/MultiDownloadButton";
import { PostMenu } from "../../components/PostMenu";
import { TagsAsBobbles } from "../../components/Tags";
import { useUserContext } from "../../lib/UserContext";
import theme from "../../utils/theme";
import { useDataApi } from "../../utils/useDataApi";
import { PostData } from "../home/Home";
import minimizeImg from "../../components/icons/minimize.svg";
import { LinkIcon } from "../../components/icons/LinkIcon";
import { format } from "date-fns";
import { useChangeHeaderColor } from "../../utils/hooks";
import { GradeTag } from "../../components/card/components/GradeTag";
import {Comments} from "./comments/Comments";

export const Post: FC<
  RouteComponentProps & {
    id?: string | number;
  }
> = ({ id }) => {
  const [postResponse, refetchPost] = useDataApi<PostData>(`/api/post/${id}`);
  const location = useLocation();
  const state = location.state as { fromModal?: boolean };

  useChangeHeaderColor("#ffffff");

  useEffect(() => {
    // need this because the navigation remembers the scroll position
    window.scrollTo(0, 0);
  }, []);

  if (postResponse.isLoading) {
    return <div>Loading...</div>;
  }
  if (postResponse.error) {
    return <div>{postResponse.error.toString()}</div>;
  }
  if (postResponse.data) {
    return (
      <PostContent
        post={postResponse.data}
        refetchPost={refetchPost}
        fromModal={Boolean(state?.fromModal)}
      />
    );
  }
  return (
    <div
      className={css`
        height: 1vh;
      `}
    >
      Something wrong happened
    </div>
  );
};

export const PostAsModal: FC<{
  post: PostData;
  refetchPost: () => void;
  handleCloseModal: () => void;
  searchContext?: string;
}> = ({ post, handleCloseModal, refetchPost, searchContext }) => {
  const [returnTo] = useState<string>(window.location.pathname);
  const navigate = useNavigate();

  useEffect(() => {
    window.history.replaceState("", "", `/p/${post?.id}`);
  }, [post]);

  return (
    <Modal
      closeModal={() => {
        if (handleCloseModal) {
          window.history.replaceState("", "", returnTo);
          handleCloseModal();
        }
      }}
      maximize={() => {
        window.history.pushState({searchContext: searchContext}, "", returnTo);
        navigate(`/p/${post.id}`, { state: { fromModal: true } });
      }}
      fullSizeContent
      modalSize={{
        width: "1330px",
        height: "90vh",
      }}
    >
      <PostContent post={post} refetchPost={refetchPost} asModal />
    </Modal>
  );
};

const PostContent: FC<{
  post: PostData;
  asModal?: boolean;
  fromModal?: boolean;
  refetchPost: () => void;
}> = ({ post, asModal, fromModal, refetchPost }) => {
  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        flex-direction: column;
      `}
    >
      <Helmet>
        <title>Addito - {post.title}</title>
      </Helmet>
      {fromModal && (
        <div
          className={css`
            padding-right: 90px;

            @media screen and (max-width: 900px) {
              padding-right: 40px;
            }
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: flex-end;
              max-width: 1330px;
              margin: auto;
            `}
          >
            <button onClick={() => window.history.back()}>
              <img src={minimizeImg} alt="Minimer" />
            </button>
          </div>
        </div>
      )}

      <div
        className={css`
          flex: 1;
        `}
      >
        <div
          className={css`
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            max-width: 1330px;
            margin: auto;

            @media screen and (max-width: 900px) {
              flex-direction: column;
            }
          `}
        >
          <Content post={post} refetchPost={refetchPost} asModal={asModal} />
        </div>
      </div>
    </div>
  );
};

const Content: FC<{
  post: PostData;
  asModal?: boolean;
  refetchPost: () => void;
}> = ({ post, asModal, refetchPost }) => {
  const [liked, setLiked] = useState<boolean>(post.liked);
  const [bookmarked, setBookmarked] = useState<boolean>(post.bookmarked);
  const { isLoggedIn, showLogInOrSignUp } = useUserContext();

  const handleLikeClick = useCallback(async () => {
    if (isLoggedIn) {
      const initialLiked = liked;
      setLiked(!initialLiked);

      if (initialLiked) {
        try {
          await axios.delete(`/api/like/${post.id}`);
        } catch (error) {
          setLiked(initialLiked);
          console.error(error);
        }
      } else {
        try {
          await axios.post(`/api/like/${post.id}`);
        } catch (error) {
          setLiked(initialLiked);
          console.error(error);
        }
      }
      refetchPost();
    } else {
      showLogInOrSignUp("LOG_IN", "Hei! For å gi epler må du være logget inn.");
    }
  }, [isLoggedIn, liked, post.id, refetchPost, showLogInOrSignUp]);

  const handleBookmarkedClick = useCallback(async () => {
    if (isLoggedIn) {
      const initialBookmarked = bookmarked;
      setBookmarked(!initialBookmarked);

      if (initialBookmarked) {
        try {
          await axios.delete(`/api/bookmark/${post.id}`);
        } catch (error) {
          setBookmarked(initialBookmarked);
          console.error(error);
        }
      } else {
        try {
          await axios.post(`/api/bookmark/${post.id}`);
        } catch (error) {
          setBookmarked(initialBookmarked);
          console.error(error);
        }
      }
      refetchPost();
    } else {
      showLogInOrSignUp(
        "LOG_IN",
        "Hei! For å bokmerke noe må du være logget inn."
      );
    }
  }, [isLoggedIn, bookmarked, post.id, refetchPost, showLogInOrSignUp]);

  return (
    <div className={css`
      width: 100%;
      display: flex;
      padding-top: ${asModal ? 0 : 160}px;
      @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
      }
    `}>
      <div
        className={cx(
          "post-content",
          css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1 1 50%;
          position: relative;
          @media screen and (max-width: 1200px) {
            flex: 1 1 100%;
          }
        `
        )}
      >
        <div
          className={css`
          min-height: calc(100vh - 400px);
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 100px 90px 0 90px;
          @media screen and (max-width: 600px) {
            padding: 100px 40px 0 40px;
          }
        `}
        >
          <div
            className={css`
            flex: 1;
            flex-shrink: 0;
          `}
          >
            <div
              className={css`
              padding-bottom: 10px;
            `}
            >
              <GradeTag tags={post.tags} />
              <p
                className={css`
                font-size: 16px;
                margin-top: 5px;
                color: ${theme.colors2.cta.tertiary};
                display: flex;
                align-items: center;

                img {
                  margin-right: 8px;
                  width: 18px;
                }
              `}
              >
                {post.user.special ? (
                  <>
                    <img src={bookImg} alt="" /> {post.user.special}
                  </>
                ) : (
                  post.user.name
                )}
              </p>
              {post.created && (
                <p
                  className={css`
                  font-size: 12px;
                  color: ${theme.colors2.cta.primary};
                  opacity: 0.7;
                `}
                >
                  {format(new Date(post.created), "dd.MM.yyyy HH:MM")}
                </p>
              )}
              <h1
                className={css`
                margin-top: 20px;
              `}
              >
                {post.title}
              </h1>
              <TagsAsBobbles
                tags={post.tags}
                maxTags={post.tags.length}
                className={css`
                font-size: 14px;
                margin-top: 10px;
              `}
              />
              <div
                className={css`
                margin-top: 30px;
              `}
              >
                {/*
                Need to manually add a <br /> to render a newline.
                A empty <p> gets the height 0px.
              */}
                {post.description
                  .split("\n")
                  .map((text) => (text ? <p>{text}</p> : <br />))}
              </div>
            </div>
          </div>
          <div
            className={css`
            flex: 1;
            flex-shrink: 0;
          `}
          >
            <div>
              <div
                className={css`
                font-size: 14px;
              `}
              >
                <div
                  className={css`
                  font-weight: 300;
                  font-size: 14px;
                `}
                >
                  <ArrowCorner
                    direction="DOWN"
                    color={theme.colors2.accent.secondary}
                    size={11}
                    className={css`
                    margin-right: 8px;
                  `}
                  />
                  {post.downloads}
                </div>
                <h3
                  className={css`
                  margin-top: 41px;
                  font-size: 14px;
                  opacity: 0.7;
                `}
                >
                  Ressurser
                </h3>
                <ul
                  className={css`
                  li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;

                    &:first-of-type {
                      padding-top: 20px;
                    }

                    &:last-of-type {
                      padding-bottom: 20px;
                    }

                    span {
                      display: flex;
                      align-items: center;
                    }
                  }
                `}
                >
                  {post.files.length === 0 && post.links.length === 0 ? (
                    "Ingen ressurser"
                  ) : (
                    <>
                      {post.links.map((link, index) => {
                        return (
                          <li key={`${link}`}>
                            <a
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={css`
                              width: 100%;
                              display: flex;
                              justify-content: space-between;
                              padding: 8px;

                              &:hover {
                                background-color: #fafafa;
                              }
                            `}
                            >
                            <span>
                              <span
                                className={css`
                                  width: 40px;
                                `}
                              >
                                <LinkIcon color={theme.colors2.cta.primary} />
                              </span>
                              {link}
                            </span>
                              <img src={externalLinkImg} alt="" />
                            </a>
                          </li>
                        );
                      })}
                      {post.files.map((file, index) => (
                        <li key={`${file.sha512hash}-${index}`}>
                          <a
                            href={`/api/file/${file.sha512hash}`}
                            download={file.filename}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={css`
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            padding: 8px;

                            &:hover {
                              background-color: #fafafa;
                            }
                          `}
                          >
                          <span>
                            <span
                              className={css`
                                width: 40px;
                              `}
                            >
                              <img src={fileImg} alt="" />
                            </span>
                            {file.filename}
                          </span>
                            <ArrowCorner
                              direction="DOWN"
                              size={20}
                              color={theme.colors2.cta.tertiary}
                            />
                          </a>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <PostContentBottomMenu
          isLoggedIn={isLoggedIn}
          liked={liked}
          bookmarked={bookmarked}
          post={post}
          adModal={asModal}
          handleBookmarkedClick={handleBookmarkedClick}
          handleLikeClick={handleLikeClick}
        />
      </div>
      <div className={cx(
        css`
        flex: 1 1 50%;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        padding: 100px 90px 0 90px;
        `,
        css`
          @media screen and (max-width: 900px) {
            padding: 60px 40px 20px 40px;
          }
        `
      )}>
        <Comments postId={post.id} comments={post.comments} refetchPost={refetchPost}/>
      </div>
    </div>
  );
};

const PostContentBottomMenu: FC<{
  isLoggedIn: boolean;
  liked: boolean;
  bookmarked: boolean;
  post: PostData;
  adModal?: boolean;
  handleBookmarkedClick: () => void;
  handleLikeClick: () => void;
}> = ({
  isLoggedIn,
  liked,
  bookmarked,
  post,
  adModal,
  handleBookmarkedClick,
  handleLikeClick,
}) => (
  <div
    className={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 120px;
      padding: 0 90px;
      @media screen and (min-width: 1200px) {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
      }
      @media screen and (max-width: 600px) {
        padding: 0 40px;
      }
    `}
  >
    <div
      className={css`
        display: flex;
        justify-content: space-between;
        background-color: ${theme.colors.base.background.primary};

        button:not(:last-of-type) {
          margin-right: 30px;
        }
      `}
    >
      <button
        onClick={handleLikeClick}
        className={cx(
          css`
            &:hover {
              path {
                opacity: 0.5;
              }
              path:first-of-type {
                fill: ${theme.colors.base.colors.pink.primary};
                stroke: none;
              }
            }
          `,
          !isLoggedIn &&
            css`
              path {
                opacity: 0.5;
              }
            `
        )}
      >
        <Apple
          filled={liked}
          strokeColor={theme.colors2.cta.tertiary}
          className={css`
            margin-right: 8px;
          `}
        />
        {post.likes}
      </button>
      <button
        onClick={handleBookmarkedClick}
        className={cx(
          css`
            &:hover {
              svg {
                fill: ${theme.colors.base.background.secondary};
                opacity: 0.5;
              }
            }
          `,
          !isLoggedIn &&
            css`
              svg {
                opacity: 0.5;
              }
            `
        )}
      >
        <Bookmark solid={bookmarked} color={theme.colors2.cta.tertiary} />
      </button>
    </div>
    <div
      className={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <CopyDataButton
        data={`${window.location.origin}/p/${post.id}`}
        className={css`
          @media screen and (max-width: 900px) {
            margin-right: 24px;
          }
          @media screen and (min-width: 900px) {
            margin-right: 30px;
          }
        `}
      >
        <span
          className={css`
            @media screen and (max-width: 900px) {
              display: none;
            }
            margin-right: 8px;
          `}
        >
          Del
        </span>
        <LinkIcon color={theme.colors2.cta.tertiary} />
      </CopyDataButton>
      <PostMenu
        flagButton
        postId={post.id}
        side="TOP"
        className={css`
          @media screen and (max-width: 900px) {
            margin-right: 24px;
          }
          @media screen and (min-width: 900px) {
            margin-right: 30px;
          }
        `}
      />
      <MultiDownloadButton files={post.files}>
        {!adModal && "Last ned"}
        <ArrowCorner
          direction="DOWN"
          size={18}
          style={{ marginLeft: 12 }}
          color={theme.colors2.cta.tertiary}
        />
      </MultiDownloadButton>
    </div>
  </div>
);
