import React, { FC, useState } from "react";
import { css, cx } from "emotion";
import theme from "../../utils/theme";
import fileImg from "../../components/icons/file.svg";
import trashImg from "../../components/icons/trash.svg";
import { Mail } from "../../components/icons/Mail";
import { Chevron } from "../../components/icons/Chevron";
import Axios from "axios";

export const Users: FC<{
  users: UserType[];
  filter: string;
  refetchUsers: () => void;
}> = ({ users, filter, refetchUsers }) => {
  const [sort, setSort] = useState<{
    column: string;
    direction: "ASCENDING" | "DESCENDING";
  }>({
    column: "",
    direction: "ASCENDING",
  });

  const handleDeleteUser = async (user: UserType) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Er du sikker på at du vil slette ${user.name}?`)) {
      try {
        await Axios.delete(`/api/user/${user.id}`);
      } catch (error) {
        alert(error);
      }
      refetchUsers();
    }
  };
  return (
    <div
      className={css`
        @media screen and (max-width: 730px) {
          max-width: 90vw;
          overflow: auto;
        }

        table {
          width: 100%;
          @media screen and (max-width: 730px) {
            min-width: 800px;
            overflow: auto;
          }
          thead {
            tr {
              background-color: ${theme.colors2.background.secondary};
              border-bottom: 8px solid ${theme.colors2.background.primary};

              th {
                text-align: left;
                font-weight: 300;
                padding: 15px 20px 10px 20px;
                font-size: 15px;
                vertical-align: middle;

                img {
                  height: 15px;
                  margin-right: 8px;
                }
              }
            }
          }

          tbody {
            tr {
              background-color: ${theme.colors2.background.secondary};
              border-bottom: 4px solid ${theme.colors2.background.primary};

              td {
                padding: 10px 20px;

                &:last-of-type {
                  text-align: right;
                }
              }
            }
          }

          button {
            display: inline-block;
          }
        }
      `}
    >
      <table>
        <thead>
          <tr>
            <th>
              Navn{" "}
              <button
                onClick={() =>
                  setSort({
                    column: "name",
                    direction:
                      sort.direction === "ASCENDING"
                        ? "DESCENDING"
                        : "ASCENDING",
                  })
                }
              >
                <Chevron
                  direction={
                    sort.column === "name" && sort.direction === "ASCENDING"
                      ? "DOWN"
                      : "UP"
                  }
                  className={cx(
                    css`
                      margin-left: 10px;
                      opacity: 0.4;
                    `,
                    sort.column === "name" &&
                      css`
                        opacity: 1;
                      `
                  )}
                />
              </button>
            </th>
            <th>
              <img src={fileImg} alt="" />
              Antall bidrag
              <button
                onClick={() =>
                  setSort({
                    column: "posts",
                    direction:
                      sort.direction === "ASCENDING"
                        ? "DESCENDING"
                        : "ASCENDING",
                  })
                }
              >
                <Chevron
                  direction={
                    sort.column === "posts" && sort.direction === "ASCENDING"
                      ? "DOWN"
                      : "UP"
                  }
                  className={cx(
                    css`
                      margin-left: 10px;
                      opacity: 0.4;
                    `,
                    sort.column === "posts" &&
                      css`
                        opacity: 1;
                      `
                  )}
                />
              </button>
            </th>
            <th>
              <Mail
                size={15}
                color={theme.colors2.cta.primary}
                className={css`
                  margin-right: 8px;
                  opacity: 0.6;
                `}
              />
              E-postadresse
              <button
                onClick={() =>
                  setSort({
                    column: "email",
                    direction:
                      sort.direction === "ASCENDING"
                        ? "DESCENDING"
                        : "ASCENDING",
                  })
                }
              >
                <Chevron
                  direction={
                    sort.column === "email" && sort.direction === "ASCENDING"
                      ? "DOWN"
                      : "UP"
                  }
                  className={cx(
                    css`
                      margin-left: 10px;
                      opacity: 0.4;
                    `,
                    sort.column === "email" &&
                      css`
                        opacity: 1;
                      `
                  )}
                />
              </button>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {users
            .filter((user) =>
              (user.name || "")
                .toLocaleLowerCase()
                .includes(filter.toLocaleLowerCase())
            )
            .sort((a, b) => {
              if (sort.column === "name") {
                if (sort.direction === "ASCENDING") {
                  return (a.name || "")
                    .toLowerCase()
                    .localeCompare((b.name || "").toLowerCase());
                } else {
                  return (b.name || "")
                    .toLowerCase()
                    .localeCompare((a.name || "").toLowerCase());
                }
              }

              return a.id - b.id;
            })
            .map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.posts || "-"}</td>
                <td>{user.email || "-"}</td>
                <td>
                  <button onClick={() => handleDeleteUser(user)}>
                    <img src={trashImg} alt="Slett bruker" />
                  </button>
                  {user.email && (
                    <a
                      href={`mailto:${user.email}`}
                      className={css`
                        margin-left: 22px;
                      `}
                    >
                      <Mail alt="Send epost til bruker" />
                    </a>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export type UserType = {
  id: number;
  name: string;
  email?: string;
  posts?: string;
};
