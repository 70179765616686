import { css } from "emotion";
import React, { FC, useEffect } from "react";
import { Tag } from "../../../components/Tags";
import theme from "../../../utils/theme";
import { UploadedFile } from "../Upload";
import { StepDescription } from "./StepDescription";

export const Description: FC<{
  description: string;
  files: UploadedFile[];
  links: string[];
  stepIsValid: boolean;
  tags: string[];
  title: string;
  handleDeleteFile: (index: number, type: "UPLOADED" | "CHOSEN") => void;
  handleRemoveLink: (index: number) => void;
  removeTag: (tag: string) => void;
  setDescription: (text: string) => void;
  setTitle: (text: string) => void;
  validateStep: (isValid: boolean) => void;
}> = ({
  description,
  files,
  handleDeleteFile,
  links,
  removeTag,
  setDescription,
  setTitle,
  stepIsValid,
  tags,
  title,
  validateStep,
  handleRemoveLink,
}) => {
  useEffect(() => {
    if (!stepIsValid && title.length > 3) {
      validateStep(true);
    } else if (stepIsValid && title.length < 3) {
      validateStep(false);
    }
  }, [title, stepIsValid, validateStep]);

  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        padding: 20px;
        flex-direction: column;
      `}
    >
      <StepDescription
        title="En kort beskrivelse er veiledende for andre lærere"
        files={files}
        deleteFile={handleDeleteFile}
        links={links}
        removeLink={handleRemoveLink}
      />

      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {tags.map((tag, index) => (
          <Tag
            tag={tag}
            removeTag={removeTag}
            isSelected={true}
            key={`${tag}-${index}`}
          />
        ))}
      </div>
      <div style={{ height: 24 }} />
      <label htmlFor="tittel">Tittel</label>
      <input
        id="tittel"
        type="text"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        placeholder="Hva handler bidraget om?"
        className={css`
          margin-top: 8px;
          padding: 15px;
        `}
      />
      <div style={{ height: 24 }} />
      <label htmlFor="comment">Kommentar</label>

      <textarea
        id="comment"
        value={description}
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        placeholder="Her kan du gi en beskrivelse av hva slags innhold det er du deler, hvordan det er tenkt brukt osv..."
        rows={10}
        className={css`
          margin-top: 8px;
          width: 100%;
          border: none;
          background-color: ${theme.colors.base.background.primary};
          font-size: inherit;
          padding: 16px;
          font-family: inherit;
        `}
      />
    </div>
  );
};
